package csaware.comm

import dk.rheasoft.csaware.api.*

open class SystemDependenciesBackend(exceptionHandler: (errorCode: Int, errorText: String) -> Unit) : CSAwareBackend(exceptionHandler) {


    //    fun getSystemDependencies(receiver: (SystemDependency) -> Unit) {
    fun getSystemDependencies(receiver: (List<SystemDependencyResource>) -> Unit) {
        getTxt("/sysdep/all") {
            receiver(SystemDependencyResource.fromListJson(it))
        }
    }

    fun getSystemDependency(id: String, receiver: (SystemDependencyResource?) -> Unit) {
        getTxt("/sysdep/read/$id") {
            receiver(SystemDependencyResource.fromJson(it))
        }
    }

    fun storeSystemDependency(resource: SystemDependencyResource, receiver: (SystemDependencyResource?) -> Unit) {
        sendJsonTxt("/sysdep/update", "POST", resource.toJsonString()) {
            receiver(SystemDependencyResource.fromJson(it))
        }
    }

    fun storeSystemDependencyWithSourceInOther(resource: SystemDependencyResource, sourceInOtherResourceIds: Set<String>, receiver: (SystemDependencyResource?) -> Unit) {
        val resourceWithOther = SystemDependencyResourceWithSourceInOthers(resource, sourceInOtherResourceIds)
        sendJsonTxt("/sysdep/updateWithSourceInOther", "POST", resourceWithOther.toJsonString()) {
            receiver(SystemDependencyResource.fromJson(it))
        }
    }

    fun deleteSystemDependency(resource: SystemDependencyResource, receiver: (SystemDependencyResource?) -> Unit) {
        sendJsonTxt("/sysdep/delete/${resource.id}", "DELETE", resource.toJsonString()) {
            receiver(resource)
        }
    }

    fun importSystemDependencies(jsonString: String, replaceCurrent: Boolean, receiver: () -> Unit) {
        sendJsonTxt("/sysdep/import?replace=$replaceCurrent", "POST", jsonString) {
            receiver()
        }
    }

    fun getSystemDependencyConfig(receiver: (SystemDependencyConfig) -> Unit) {
        getTxt("/sysdep/config") {
            receiver(SystemDependencyConfig.fromJson(it))
        }
    }

    fun storeSystemDependencyConfig(config: SystemDependencyConfig, receiver: (SystemDependencyConfig) -> Unit) {
        sendJsonTxt("/sysdep/config", "POST", config.toJsonString()) {
            receiver(SystemDependencyConfig.fromJson(it))
        }
    }

    fun importSystemDependencyConfig(
        configJson: String,
        receiver: (String) -> Unit
    ) {
        sendJsonTxt("/sysdep/config/import", "POST", configJson) {
            val x = it
            receiver(x)
        }
    }

}

