package csaware.systemdepend.nodedetails

import csaware.main.CsawareServices
import csaware.messages.csawareMessageStrategy
import csaware.messages.csawareMessages
import csaware.systemdepend.config.SystemResourceConfigChangeDlg
import csaware.utilities.markdown.MarkDownInput
import dk.rheasoft.csaware.api.*
import kafffe.bootstrap.*
import kafffe.bootstrap.form.*
import kafffe.core.*
import kafffe.core.modifiers.CssClassModifier
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kafffe.core.modifiers.StyleModifier

class SystemResourceChangeDlg(
    systemResource: SystemDependencyResource,
    stateTxt: String,
    val connectedFrom: List<String>
) :
// Use copy of data in order to be able to sync changes and do "cancel" by forgetting
    FormDialog<SystemDependencyResource>(
        Model.of("${systemResource.name}: $stateTxt"),
        Model.of(systemResource.copy())
    ) {

    init {
        labelStrategy = csawareMessageStrategy("system_depend_")
    }

    private val config: SystemDependencyConfig
        get() = CsawareServices.systemDependencyService.config.data

    private val addedFieldIds = mutableSetOf<String>()

    private val resourceIds =
        CsawareServices.systemDependencyService.model.data.sortedBy(SystemDependencyResource::name).map { it.id }

    // Map functional submodel list to mutable list of ids
    private val connectsToModel: Model<List<String>> = model.func(
        { p -> p.data.source.toList() },
        { p, v -> p.data.source = v.toMutableList() }
    )

    private val connectsTo =
        object : MultipleEditSelect<String>("connectedTo", connectsToModel, Model.of(resourceIds)) {
            override fun display(choice: String): String =
                CsawareServices.systemDependencyService.byId(choice)?.name ?: ""
        }

    /**
     * Model of connected from ids that can edited, but are to be represented by connects to on the other SystemDependencyResources
     */
    val connectsFromModel: Model<List<String>> = Model.of(connectedFrom)
    private val connectsFrom =
        object : MultipleEditSelect<String>("connectedFrom", connectsFromModel, Model.of(resourceIds)) {
            override fun display(choice: String): String =
                CsawareServices.systemDependencyService.byId(choice)?.name ?: ""
        }

    private val infoflowModel: Model<List<String>> =
        model.func({ p -> p.data.x_infoflow.toList() }, { p, v -> p.data.x_infoflow = v.toMutableList() })
    private val infoflow = MultipleEditSelectString(
        "infoFlow",
        infoflowModel,
        Model.of(CsawareServices.systemDependencyService.config.data.getInfoflowValues())
    )

    init {
        size = ModalSize.large
        modal.modifiersBody.add(StyleModifier {
            overflowY = "auto"
            maxHeight = "75vh"
        })
        modal.modifiersModal.add(StyleModifier {
            maxWidth = "90vw"
            width = "1600px"
        })
        modal.modifiersContent.add(CssClassModifier("bg-light"))
        // We need some hgap because we do not apply whitespace "\n" between buttons.
        row {
            col(ColWidth(ResponsiveSize.md, 6)) {
                val fieldContainer =
                    row {
                        cssClassModifier("vgap-3")
                        readonly(SystemDependencyResource::id)
                        input(SystemDependencyResource::name)
                        decorateAndAdd(labelStrategy.label("connectedFrom"), connectsFrom)
                        decorateAndAdd(labelStrategy.label("connectedTo"), connectsTo)
                        decorateAndAdd(labelStrategy.label("infoFlow"), infoflow)
                        for (field in config.fields.filter { includeEditorForField(it) }) {
                            addFieldEditor(this, field)
                        }
                    }
                addFieldAdder(this, fieldContainer)
            }
            col(ColWidth(ResponsiveSize.md, 6)) {
                val inp = MarkDownInput(
                    model.property(SystemDependencyResource::description)
                )
                decorateAndAddComponent(labelStrategy.label(SystemDependencyResource::description.name), inp)
//                textArea(SystemDependencyResource::description).apply {
//                    lines = 18
//                }
            }
        }
        cssClassModifier("hgap-3")
        cssClassModifier("vgap-3")
        submit().apply {
            color = BasicColor.primary
        }
        cancel().color = BasicColor.secondary
    }

    private fun addFieldAdder(
        addTo: FormLayout<SystemDependencyResource>,
        fieldContainer: FormLayout<SystemDependencyResource>
    ) {
        addTo.group {
            cssClassModifier("border border-primary rounded p-2")
            val addFieldValueModel: Model<String> = Model.of("")
            val addFieldSelect =
                object : KafffeComponent() {
                    private fun valueSelected(fieldId: String) {
                        addedFieldIds.add(fieldId)
                        config.fields.filter { it.id == fieldId }.map { field ->
                            addFieldEditor(fieldContainer, field)
                        }
                        addFieldValueModel.data = ""
                        rerender()
                        fieldContainer.rerender()
                    }

                    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
                        select {
                            addClass("form-select ms-2")
                            withStyle {
                                width = "max-content"
                                display = "inline-block"
                            }
                            withElement {
                                onchange = {
                                    valueSelected(this.value)
                                    value = ""
                                    it
                                }
                            }
                            option {
                                withElement {
                                    value = ""
                                }
                                text("")
                            }
                            config.fields.filter { !includeEditorForField(it) }.forEach { field ->
                                option {
                                    withElement {
                                        value = field.id
                                    }
                                    text(field.label)
                                }
                            }
                        }
                }
            addChild(Label(labelStrategy.label("field_add")))
            addChild(addFieldSelect)
            addChild(BootstrapButton(labelStrategy.label("fields_config")) {
                SystemResourceConfigChangeDlg(CsawareServices.systemDependencyService.config.data).apply {
                    onSubmitOk = {
                        CsawareServices.systemDependencyService.storeConfig(model.data) {
                            addFieldSelect.rerender()
                        }
                    }
                    attach()
                    selectTab(SystemResourceConfigChangeDlg.Tab.Fields)
                }
            }.apply {
                iconClasses = "fas fa-wrench me-2"
                iconBefore = true
                color = BasicColor.info
                cssClassModifier("ms-4")
            })
        }
    }

    private fun addFieldEditor(formLayout: FormLayout<SystemDependencyResource>, field: SystemDependencyField) {
        val hasValueSet = field.valueSet != null
        val single = field.cardinality.isSingle
        when {
            field.type == FieldType.DEPENDENCY -> {
                val valuesModel: Model<List<String>> = fieldValuesModel(field)

                val dependencyField =
                    object : MultipleEditSelect<String>(field.id, valuesModel, Model.of(resourceIds)) {
                        override fun display(choice: String): String =
                            CsawareServices.systemDependencyService.byId(choice)?.name ?: ""
                    }
                val dependencyWithLabel = inputDecorator(Model.of(field.label), dependencyField)
                formLayout.addChild(dependencyWithLabel)
            }

            single && !hasValueSet -> {
                val valueModel: Model<String> = fieldValueModel(field)
                if (field.type == FieldType.MARKDOWN) {
                    formLayout.textArea(field.id, Model.of(field.label), valueModel).apply {
                        // validation
                        if (field.cardinality == Cardinality.One) {
                            required = true
                        }
                    }
                } else {
                    formLayout.input(field.id, Model.of(field.label), valueModel).apply {
                        if (field.type == FieldType.SECRET) {
                            inputType = "password"
                        }
                    }.apply {
                        // validation
                        if (field.cardinality == Cardinality.One) {
                            required = true
                        }
                        addValidator {input ->
                            val valid = field.type.validate(input.htmlInputElement.value)
                            ValidationResult(valid, if (valid) "" else csawareMessages().system_depend_field_validation_error(field.type, input.htmlInputElement.value))
                        }
                    }
                }
            }

            single && hasValueSet -> {
                val valueModel: Model<String?> = fieldOptionalValueModel(field)
                val choices = CsawareServices.systemDependencyService.config.data.getValueSet(field.valueSet!!).toList()
                val valueEdit = SingleEditSelectString(field.id, valueModel, Model.of(choices))
                val valueList = inputDecorator(Model.of(field.label), valueEdit)
                formLayout.addChild(valueList)
            }

            !single && !hasValueSet -> {
                val valuesModel: Model<List<String>> = fieldValuesModel(field)
                val valueEdit = MultipleEdit(field.id, valuesModel)
                val valueList = inputDecorator(Model.of(field.label), valueEdit)
                valueEdit.addValidator {
                    val values = it.currentValues()
                    val invalidValues: List<String> = values.filter { value -> ! field.type.validate(value) }
                    when {
                        field.cardinality == Cardinality.OneToMany && values.isEmpty() -> {
                            ValidationResult(false, csawareMessages().validation_required)
                        }
                        invalidValues.isNotEmpty() -> {
                            ValidationResult(false, csawareMessages().system_depend_field_validation_error(field.type, invalidValues.joinToString(", ")))
                        }
                        else -> {
                            ValidationResult(true, "")
                        }
                    }
                }
                formLayout.addChild(valueList)
            }

            !single && hasValueSet -> {
                val valuesModel: Model<List<String>> = fieldValuesModel(field)
                val choices = CsawareServices.systemDependencyService.config.data.getValueSet(field.valueSet!!).toList()
                val valueEdit = MultipleEditSelectString(field.id, valuesModel, Model.of(choices))
                val valueList = inputDecorator(Model.of(field.label), valueEdit)
                formLayout.addChild(valueList)
            }
        }
    }

    private fun includeEditorForField(field: SystemDependencyField) =
        model.data.hasValue(field) || field.id in addedFieldIds

    private fun fieldValuesModel(field: SystemDependencyField) = model.func(
        getData = { p -> p.data.dataLists[field.id]?.toList() ?: listOf() },
        setData = { p, v -> p.data.dataLists[field.id] = v.toMutableList() }
    )

    private fun fieldValueModel(field: SystemDependencyField): FunctionalSubModel<String, SystemDependencyResource> {
        fun getData(p: Model<SystemDependencyResource>): String = p.data.data[field.id] ?: ""
        fun setData(p: Model<SystemDependencyResource>, value: String) {
            if (value.isBlank()) {
                p.data.data.remove(field.id)
            } else {
                p.data.data[field.id] = value
            }
        }
        return model.func(::getData, ::setData)
    }

    private fun fieldOptionalValueModel(field: SystemDependencyField): FunctionalSubModel<String?, SystemDependencyResource> {
        fun getData(p: Model<SystemDependencyResource>): String? = p.data.data[field.id]
        fun setData(p: Model<SystemDependencyResource>, value: String?) {
            if (value.isNullOrBlank()) {
                p.data.data.remove(field.id)
            } else {
                p.data.data[field.id] = value
            }
        }
        return model.func(::getData, ::setData)
    }

}


