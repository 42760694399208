package csaware.messages

import kafffe.messages.Messages_de

open class CsawareMessages_de : Messages_de(), CsawareMessages {
    override val translation_automatic: String = "Automatische Übersetzung"
    override val settings: String = "Einstellungen"

    override val environment_name = "Name"

    override val nav_overview = "Überblick"
    override val nav_threats = "Bedrohungen"
    override val nav_threatsClosed = "Geschlossene Bedrohungen"
    override val nav_config = "Konfiguration"
    override val threat_group = "Bedrohungsgruppe"

    override val threat_group_all = "Alle"
    override val threat_nobody = "Niemand"
    override val threat_state = "Zustand"
    override val threat_state_initator = "Wer"
    override val threat_state_history = "Verlauf"
    override val threat_state_comment = "Kommentar"
    override val threat_course_of_action = "Handlungsempfehlung"
    override val threat_where = "Wo"
    override val threat_assignee = "Zugeteilt an"

    override val threat_stixtype = "Bedrohungstyp"
    override val threat_stixtype_campaign = "Aktion"
    override val threat_stixtype_report = "Bericht"
    override val threat_stixtype_vulnerability = "Sicherheitslücke"
    override val threat_stixtype_malware = "Schadsoftware"
    override val threat_stixtype_attack_pattern = "Angriffsmuster"
    override val threat_stixtype_tool = "Werkzeug"
    override val threat_stixtype_unknown = "Unbekannt"

    override val threat_observed_data_objects = "Beobachtete Daten"
    override val threat_observed_data_id= "Id"
    override val threat_observed_data_type= "Art"
    override val threat_observed_data_data = "Daten"

    override val threat_subtype = "Unterkategorie"
    override val threat_firstObserved = "Zuerst beobachtet"
    override val threat_endActive = "Geschlossen um"
    override val threat_newState = "Zustand ändern"
    override val threat_selfHealingConfirm = "Selbstheilung bestätigen"

    override val threat_topTitle = "Höchste Bedrohungen"

    override val severity = "Ernsthaftigkeit"
    override val count = "Anzahl"

    override val threat_level_5_name = "Kritisch"
    override val threat_level_5_text = "Eine kritische Auswirkung dieses Events auf die Systeme oder Services wird erwartet."
    override val threat_level_4_name = "Ernst"
    override val threat_level_4_text = "Eine ernste Auswirkung dieses Events auf die Systeme oder Services wird erwartet."
    override val threat_level_3_name = "Substantiell"
    override val threat_level_3_text = "Eine substantielle Auswirkung dieses Events auf die Systeme oder Services wird erwartet."
    override val threat_level_2_name = "Moderat"
    override val threat_level_2_text = "Eine moderate Auswirkung dieses Events auf die Systeme oder Services wird erwartet."
    override val threat_level_1_name = "Niedrig"
    override val threat_level_1_text = "Eine niedrige Auswirkung dieses Events auf die Systeme oder Services wird erwartet."
    override val threat_level_0_name = ""
    override val threat_level_0_text = ""

    override val ThreatState_Active = "Aktiv"
    override val ThreatState_Resolved = "Gelöst"
    override val ThreatState_Ignored = "Ignoriert"
    override val ThreatState_HealingAwaitDecision = "Selbstheilung erwartet Entscheidung"
    override val ThreatState_HealingAccepted = "Selbstheilungsvorschlag akzeptieren"
    override val ThreatState_HealingDeclined = "Selbstheilungsvorschlag ablehnen"
    override val ThreatState_Healed = "Selbstheilung erfolgreich"
    override val ThreatState_HealingFailed = "Selbstheilung gescheitert"
    override val ThreatState_HealingInProgress = "Selbstheilung in Arbeit"
    override val ThreatState_Unknown = "Unbekannt"

    override val user_management = "Benutzerverwaltung"
    override val user_edit = "Benutzer bearbeiten"
    override val user_new = "Neuer Benutzer"
    override val user_subject = "Subjekt"
    override val user_email = "Email"
    override val user_firstName = "Vorname"
    override val user_lastName = "Nachname"
    override val user_fullname  = "Name"
    override val user_roles = "Rollen"
    override val user_action_import = "Importieren"
    override val user_action_export = "Exportieren"

    override val user_preferences_edit ="UI-Einstellungen"
    override val system_preferences_accountDataPresentationLanguage = "Standardsprache für die Datenpräsentation"
    override val system_preferences_accountUiLanguage ="Standardsprache der Benutzeroberfläche"

    override val user_preferences_dataPresentationLanguage = "Datenpräsentationssprache"
    override val user_preferences_uiLanguage ="Sprache der Benutzeroberfläche"

    override val system_preferences_edit = "Systemeinstellungen"

    override val informationshare_management = "Informationsaustausch"
    override val informationshare_short_description = """Information sharing referrs to sharing of cybersecurity information with cybersecurity professionals outside your organization. 
Examples of such professionals may be computer security incident response teams (CSIRTs) your organization is collaborating with.

The purpose of sharing this information with cybersecurity professionals is for them to be better able to assess and analyze cybersecurity issues within organizations and to be able to set it in context with information gathered from other sources in order to be able to devise better mitigation and prevention mechanisms, which in turn may help your organization to better protect against attacks in the future.

If you decide to share, the information will be posted on a protected repository. Only people and organizations that have been authorized by your organization will be able to access the information."""
    override val informationshare_edit = "Informationsaustausch bearbeiten"
    override val informationshare_id = "Id"
    override val informationshare_description = "Beschreibung"
    override val informationshare_state = "Zustand"
    override val informationshare_create = "Created"
    override val informationshare_modified = "Zuletzt geändert"
    override val informationshare_initiator = "Wer"
    override val informationshare_history = "Verlauf"
    override val informationshare_summary = "Summary"

    override val ShareState_AwaitDecision = "Austausch akzeptieren"
    override val ShareState_Accept = "akzeptiert"
    override val ShareState_Decline = "abgelehnt"
    override val ShareState_Unknown = "unbekannt"

    override val system_dependencies = "System"

    override val system_depend_id = "Id"
    override val system_depend_name  = "Name"
    override val system_depend_description= "Beschreibung"
    override val system_depend_connectedFrom= "Verbunden von"
    override val system_depend_connectedTo= "Verbunden zu"
    override val system_depend_infoflow= "Informationsfluss"
    override val system_depend_category= "Kategorie"
    override val system_depend_fields= "Felder"
    override val system_depend_fields_valueset = "Feldwerte"
    override val system_depend_fields_valueset_values = "Werte"
    override val system_depend_fields_to_shape_mapping = "NodeType to Shape"
    override val system_depend_layout = "Anordnung"
    override val system_depend_layoutDirection = "Anordnungsrichtung"
    override val system_depend_layout_horizontal = "Horizontal"
    override val system_depend_layout_vertical = "Vertikal"
    override val system_depend_layout_roots = "Startknoten"
    override val system_depend_layout_user_root = "Anordnungsstart"
    override val system_depend_layout_default_root = "Standard Anordnung"
    override val system_depend_spacing = "Abstand"

    override val system_depend_action_search = "Systemressource durchsuchen"
    override val system_depend_action_new = "Neu erstellen"
    override val system_depend_action_edit = "Bearbeiten"
    override val system_depend_action_delete = "Löschen"
    override val system_depend_action_delete_confirm_title = "Systemressource Löschen"
    override val system_depend_action_config = "Konfiguration"
    override val system_depend_action_import = "Importieren"
    override val system_depend_action_export = "Exportieren"
    override val system_depend_action_config_import = "Konfiguration Importieren"
    override val system_depend_action_config_export = "Konfiguration Exportieren"
    override val system_depend_action_print = "Drucken"
    override val system_depend_label_all = "Alle"

    override val system_depend_field_id = "ID"
    override val system_depend_field_label = "Name"
    override val system_depend_field_type = "Typ"
    override val system_depend_field_cardinality = "Kardinalität "
    override val system_depend_field_add = "Feld einbeziehen"
    override val system_depend_fields_config = "Felderkonfiguration.."
    override val system_depend_field_validation_error_txt = "'%value%' ist nicht gültig für '%type%'"

    override val sign_out = "Abmelden"
    override val sign_out_disclaimer = """
        |Die CS-AWARE Sitzung wurde beendet.  
        |Um automatisches login zu vermeiden, müssen Sie sich
        |zusätzlich beim externen Authorierungsanbieter abmelden. 
        |""".trimMargin()
    override val sign_out_link="Abmeldung beim Authorisierungsanbieter."

    override val mail_dialog_title = "E-mail senden"
    override val mail_to = "An"
    override val mail_from = "Von"
    override val mail_subject = "Betreff"
    override val mail_body = "Nachricht"
    override val mail_send = "Senden"

    override val socialmedia_reader : String = "Soziale Medien"
    override val socialmedia_reader_filter_readstate_both = "Sowohl gelesen als auch ungelesen"
    override val socialmedia_reader_filter_readstate_not_read = "Nur ungelesen"
    override val socialmedia_reader_filter_readstate_read = "Nur gelesen"

    override val socialmedia_reader_read_on_source = "Quelle lesen"
    override val socialmedia_createdAt : String = "Erstellt am"
    override val socialmedia_keywords : String = "Schlüsselwörter"
    override val socialmedia_text : String = "Text"
    override val socialmedia_account : String = "Konto"
    override val socialmedia_id:String = "Id"

    override val socialmedia_system_where = "Bezieht sich auf"

    override val socialmedia_account_included = "Enthalten"
    override val socialmedia_management_title = "Soziale Medien Management"
    override val socialmedia_account_edit = "Bearbeiten"
    override val socialmedia_account_new = "Neu"
    override val socialmedia_account_mediaType = "Typ"
    override val socialmedia_account_collectorState = "Sammler"
    override val socialmedia_account_id = "Id"
    override val socialmedia_account_name = "Name"
    override val socialmedia_account_displayName = "Anzeigename"
    override val socialmedia_account_description= "Beschreibung"
    override val socialmedia_account_lastCollected = "Zuletzt gesammelt"

    override val socialmedia_account_twitter_new = "Neue X-Kontosammlung"
    override val socialmedia_account_include = "Enthalten kontosammlung"
    override val socialmedia_account_exclude = "Ausschließen kontosammlung"

    override val socialmedia_account_action_export = "Exportieren"
    override val socialmedia_account_action_import = "Importieren"
    override val socialmedia_account_action_include_page = "Include collection for accounts on the page"
    override val socialmedia_account_action_exclude_page = "Exclude collection for accounts on the page"
    override val socialmedia_create_threatobservation = "Bedrohungsbericht erstellen"

    override val markdown_bold = "Fett"
    override val markdown_italic = "Kursiv"
    override val markdown_strikethrough = "Durchgestrichen"
    override val markdown_heading = "Überschrift"
    override val markdown_heading_smaller = "Überschrift kleiner"
    override val markdown_heading_bigger = "Überschrift größer"
    override val markdown_heading_1x = "Überschrift 1"
    override val markdown_heading_2x = "Überschrift 2"
    override val markdown_heading_3x = "Überschrift 3"
    override val markdown_code = "Code"
    override val markdown_quote = "Zitat"
    override val markdown_unordered_list = "Ungeordnete Liste"
    override val markdown_ordered_list = "Geordnete Liste"
    override val markdown_clean_block = "Sauberer _Block"
    override val markdown_link = "Link einfügen"
    override val markdown_image = "Bild einfügen"
    override val markdown_mde_table = "Tabelle einfügen"
    override val markdown_horizontal_rule = "Horizontale Regel"
    override val markdown_preview = "Vorschau einschalten"
    override val markdown_side_by_side = "Seitenweise Ansicht"
    override val markdown_fullscreen = "Vollbildansicht"
    override val markdown_guide = "Leitfaden"
    override val markdown_undo = "Rückgängig"
    override val markdown_redo = "Wiederherstellen"

}

