package dk.rheasoft.csaware.api

import dk.rheasoft.csaware.utils.JsonUtilSerialization
import kotlinx.serialization.Serializable
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.decodeFromJsonElement


@Serializable
data class SystemPreferences(
    var accountUiLanguage: Language = Language.ENGLISH,
    var accountPresentationLanguage: Language = Language.ENGLISH,
) {

    fun toJsonString(): String =
        JsonUtilSerialization.json.encodeToString(this)

    companion object {
        fun QueryResult<SystemPreferences>.toJson(): String =
            JsonUtilSerialization.json.encodeToString(QueryResult.serializer(serializer()), this)

        fun fromQueryResultJson(json: String): QueryResult<SystemPreferences> =
            JsonUtilSerialization.json.decodeFromString(QueryResult.serializer(serializer()), json)

        fun fromJson(json: JsonObject): SystemPreferences=
            JsonUtilSerialization.json.decodeFromJsonElement(json)

        fun fromJson(json: String): SystemPreferences =
            JsonUtilSerialization.json.decodeFromString(json)

    }
}

