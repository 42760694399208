package csaware.threats

import csaware.main.CsawareServices
import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import kafffe.bootstrap.modifier.BootstrapPopoverModifier
import kafffe.bootstrap.modifier.BootstrapTooltipModifier

/**
 * Example fot listing threats returned by backend.
 *
 * Currently loads a "new" threats on button click.
 */
class ThreatsTableRecentClosed : ThreatsTableBase(i18nText(CsawareMessages::nav_threatsClosed), showEndActive = true) {
    init {
        loadData()
    }

    override fun loadData() {
        // clear any tootltip,popovers
        BootstrapTooltipModifier.removeAll()
        BootstrapPopoverModifier.removeAll()
        CsawareServices.alerts.clearAlerts()

        val offset = pageSize * (pager.currentPage - 1)
        if (!filter.isFilter()) {
            CsawareServices.threatsBackend.getThreatsRecentClosed(offset, pageSize, this::receiveData)
        } else {
            CsawareServices.threatsBackend.getThreatsRecentClosedOfGroup(filter, offset, pageSize, this::receiveData)
        }
        CsawareServices.alerts.clearAlerts()
    }

}