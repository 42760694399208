package csaware.systemdepend.nodedetails

import csaware.main.CsawareServices
import csaware.main.UserConfiguration
import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import csaware.threats.Popovers
import csaware.threats.ThreatStateChangeDlg
import csaware.threats.ThreatStateSymbol
import csaware.threats.WhereLink
import csaware.translation.TranslationModel
import dk.rheasoft.csaware.api.SystemDependencyResource
import dk.rheasoft.csaware.api.ThreatObservation
import kafffe.bootstrap.BootstrapTable
import kafffe.bootstrap.BootstrapTableStyles
import kafffe.core.*
import kafffe.core.modifiers.CssClassModifier
import kafffe.core.modifiers.StyleModifier
import kafffe.messages.MessagesObject.formatDateTime
import kotlinx.datetime.Clock
import kotlinx.datetime.toJSDate
import org.w3c.dom.HTMLTableCellElement

class Threats(nodeSelectionModel: Model<SystemDependencyResource>)  : KafffeComponentWithModel<SystemDependencyResource>(nodeSelectionModel) {
    private val threatListModel = Model.of(listOf<ThreatObservation>())
    private val threatTable = addChild(BootstrapTable(threatListModel).apply {
        rowClickHandler = {data, _ -> ThreatStateChangeDlg.show(data)}

        addStyle(BootstrapTableStyles.striped)
        modifiers.add(CssClassModifier("csaware-hover"))
        modifiersHeader.add(CssClassModifier("bg-primary"))

        modifiers.add(StyleModifier { display = if (model.data.isEmpty()) "none" else "block" })

        colEx(i18nText(CsawareMessages::severity), fun(rowData: ThreatObservation, cell: HTMLTableCellElement): ThreatStateSymbol {
            cell.style.backgroundColor = UserConfiguration.default.severityColorMap[rowData.severity]
            return ThreatStateSymbol(Model.of(rowData), includeSeverityText = true)
        })
        col(i18nText(CsawareMessages::threat_firstObserved), { Label(it.firstObserved.toJSDate().formatDateTime()) })
        col(i18nText(CsawareMessages::threat_group), { Label(it.threatGroup) })
        col(i18nText(CsawareMessages::threat_where), { WhereLink(it.whereSightedRefs) }).apply { rowClick = false }

        colEx(i18nText(CsawareMessages::name), { observation, cell ->
            val translationModel = TranslationModel(Model.of(observation.description))
            Popovers.description { translationModel.translation }.modify(cell)
            Label(observation.name).apply { setModelChangedRerender() }
        })

    })

    init {
        modelChangedStandardBehavior = { loadData() }
        loadData()
    }

    fun loadData() {
        CsawareServices.alerts.clearAlerts()
        threatListModel.data = listOf()
        CsawareServices.threatsBackend.getSystemResourceThreatsCurrentActive(
            Clock.System.now(),
            model.data.id,
            offset = 0,
            pagesize = 4
        ) {
            threatListModel.data = it.result
        }
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {

        return div {
            add(threatTable.html)
        }
    }

}