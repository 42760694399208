package csaware.systemdepend

import csaware.main.CsawareServices
import csaware.main.UserInformation
import csaware.messages.CsawareMessages
import csaware.messages.csawareMessages
import csaware.messages.i18nText
import csaware.socialmedia.CreateThreatReportDlg
import csaware.systemdepend.config.SystemResourceConfigChangeDlg
import csaware.systemdepend.nodedetails.SystemResourceChangeDlg
import csaware.utilities.FileImportDlg
import dk.rheasoft.csaware.api.SystemDependencyResource
import dk.rheasoft.csaware.api.ThreatObservation
import dk.rheasoft.csaware.api.ThreatState
import dk.rheasoft.csaware.api.access.MainFeature
import dk.rheasoft.csaware.api.access.Permission
import dk.rheasoft.csaware.api.access.UserRole
import kafffe.bootstrap.Modal
import kafffe.core.Model
import kotlinx.browser.window
import kotlinx.datetime.Clock
import kotlin.time.Duration.Companion.days

@Suppress("EnumEntryName")
enum class UiFunctions(val singleResource: Boolean, val label: String, val iconCls: String, val feature: MainFeature, val permission: Permission) {
    createThreat(true, csawareMessages().socialmedia_create_threatobservation, "fas fa-exclamation-triangle", MainFeature.Threats, Permission.Write) {
        override fun doIt(currentResource: SystemDependencyResource, selectedModel: Model<SystemDependencyResource>) {
            val newThreatId = generateUuid("tick--")
            val report = ThreatObservation(
                id = newThreatId,
                threatGroup = "Social Media",
                stixType = "report",
                name = "",
                description = "",
                threatId = newThreatId,
                assignee = UserInformation.current.email,
                firstObserved = Clock.System.now(),
                endActive = Clock.System.now() + 365.days,
                lastObserved = Clock.System.now(),
                state = ThreatState.Active,
                severity = 2,
                riskLevel = 0.0,
                exploitability = 0.0,
                count = 1,
                whereSightedRefs = mutableSetOf(currentResource.id)
            )
            CreateThreatReportDlg.show(report)
        }
    },
    edit(true, csawareMessages().system_depend_action_edit, "fas fa-edit", MainFeature.SystemDependencies, Permission.Write) {
        override fun doIt(currentResource: SystemDependencyResource, selectedModel: Model<SystemDependencyResource>) {
            if (currentResource.hasId()) {
                SystemResourceChangeDlg(currentResource, csawareMessages().system_depend_action_edit, CsawareServices.systemDependencyService.connectedFrom(currentResource)).apply {
                    onSubmitOk = {
                        val sr = this.model.data
                        CsawareServices.systemDependencyService.store(sr, selectedModel, connectsFromModel.data.toSet())
                    }
                    attach()
                }
            }
        }
    },
    delete(true, csawareMessages().system_depend_action_delete, "fas fa-trash", MainFeature.SystemDependencies, Permission.Delete) {
        override fun doIt(currentResource: SystemDependencyResource, selectedModel: Model<SystemDependencyResource>) {
            if (currentResource.hasId()) {
                Modal.confirm(i18nText(CsawareMessages::system_depend_action_delete_confirm_title), Model.of(
                    currentResource.name
                )) {
                    CsawareServices.systemDependencyService.delete(currentResource, selectedModel)
                }
            }
        }

    },
    create(true, csawareMessages().system_depend_action_new, "far fa-plus-square", MainFeature.SystemDependencies, Permission.Create) {
        override fun doIt(currentResource: SystemDependencyResource, selectedModel: Model<SystemDependencyResource>) {
            val id = generateUuid()
            val from = if (currentResource.id.isNotEmpty()) {
                mutableListOf(currentResource.id)
            } else {
                mutableListOf()
            }
            val resource =
                SystemDependencyResource(id, "", Clock.System.now(), Clock.System.now(), "Some new resource", from)
            SystemResourceChangeDlg(resource, csawareMessages().system_depend_action_new, connectedFrom = from).apply {
                onSubmitOk = {
                    val sr = this.model.data
                    CsawareServices.systemDependencyService.store(sr, selectedModel, connectsFromModel.data.toSet())
                }
                attach()
            }
        }
    },
    config(false, csawareMessages().system_depend_action_config, "fas fa-wrench", MainFeature.SystemDependencyConfig, Permission.Write) {
        override fun doIt(currentResource: SystemDependencyResource, selectedModel: Model<SystemDependencyResource>) {
            SystemResourceConfigChangeDlg(CsawareServices.systemDependencyService.config.data).apply {
                attach()
            }
        }
    },
    export(false, csawareMessages().system_depend_action_export, "fas fa-file-export", MainFeature.SystemDependencies, Permission.Read) {
        override fun doIt(currentResource: SystemDependencyResource, selectedModel: Model<SystemDependencyResource>) {
            window.parent.open("/sysdep/export")
        }
    },
    import(false, csawareMessages().system_depend_action_import, "fas fa-file-import", MainFeature.CsawareAdministration, Permission.Write) {
        override fun doIt(currentResource: SystemDependencyResource, selectedModel: Model<SystemDependencyResource>) {
            FileImportDlg(i18nText(CsawareMessages::system_depend_action_import), "system_depend_").apply {
                onSubmitOk = {
                    val jsonData = fileData.data
                    CsawareServices.systemDependencyService.import(jsonData, replaceCurrent = true)
                }
                attach()
            }
        }
    },
    configExport(false, csawareMessages().system_depend_action_config_export, "fas fa-file-export", MainFeature.SystemDependencyConfig, Permission.Read) {
        override fun doIt(currentResource: SystemDependencyResource, selectedModel: Model<SystemDependencyResource>) {
            window.parent.open("/sysdep/config/export")
        }
    },
    configImport(false, csawareMessages().system_depend_action_config_import, "fas fa-file-import", MainFeature.CsawareAdministration, Permission.Write) {
        override fun doIt(currentResource: SystemDependencyResource, selectedModel: Model<SystemDependencyResource>) {
            FileImportDlg(i18nText(CsawareMessages::system_depend_action_config_import), "system_depend_").apply {
                onSubmitOk = {
                    val jsonData = fileData.data
                    CsawareServices.systemDependencyService.configImport(jsonData)
                }
                attach()
            }
        }
    }
;

    companion object {
        val resourceFunctions = entries.filter { it.singleResource }
        val globalFunctions = entries.filter { !it.singleResource }
        fun generateUuid(prefix: String = "identity--"): String {
            val ran = js("""(""+1e7+-1e3+-4e3+-8e3+-1e11).replace(/1|0/g,function(){return(0|Math.random()*16).toString(16)})""")
            return "$prefix$ran"
        }
    }

    abstract fun doIt(currentResource: SystemDependencyResource, selectedModel: Model<SystemDependencyResource>)

}