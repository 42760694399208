@file:Suppress("unused")

package csaware.color

object Colors {
    const val aliceblue               = "#F0F8FF"
    const val antiquewhite            = "#FAEBD7"
    const val aqua                    = "#00FFFF"
    const val aquamarine              = "#7FFFD4"
    const val azure                   = "#F0FFFF"
    const val beige                   = "#F5F5DC"
    const val bisque                  = "#FFE4C4"
    const val black                   = "#000000"
    const val blanchedalmond          = "#FFEBCD"
    const val blue                    = "#0000FF"
    const val blueviolet              = "#8A2BE2"
    const val brown                   = "#A52A2A"
    const val burlywood               = "#DEB887"
    const val cadetblue               = "#5F9EA0"
    const val chartreuse              = "#7FFF00"
    const val chocolate               = "#D2691E"
    const val coral                   = "#FF7F50"
    const val cornflowerblue          = "#6495ED"
    const val cornsilk                = "#FFF8DC"
    const val crimson                 = "#DC143C"
    const val cyan                    = "#00FFFF"
    const val darkblue                = "#00008B"
    const val darkcyan                = "#008B8B"
    const val darkgoldenrod           = "#B8860B"
    const val darkgray                = "#A9A9A9"
    const val darkgreen               = "#006400"
    const val darkgrey                = "#A9A9A9"
    const val darkkhaki               = "#BDB76B"
    const val darkmagenta             = "#8B008B"
    const val darkolivegreen          = "#556B2F"
    const val darkorange              = "#FF8C00"
    const val darkorchid              = "#9932CC"
    const val darkred                 = "#8B0000"
    const val darksalmon              = "#E9967A"
    const val darkseagreen            = "#8FBC8F"
    const val darkslateblue           = "#483D8B"
    const val darkslategray           = "#2F4F4F"
    const val darkslategrey           = "#2F4F4F"
    const val darkturquoise           = "#00CED1"
    const val darkviolet              = "#9400D3"
    const val deeppink                = "#FF1493"
    const val deepskyblue             = "#00BFFF"
    const val dimgray                 = "#696969"
    const val dimgrey                 = "#696969"
    const val dodgerblue              = "#1E90FF"
    const val firebrick               = "#B22222"
    const val floralwhite             = "#FFFAF0"
    const val forestgreen             = "#228B22"
    const val fuchsia                 = "#FF00FF"
    const val gainsboro               = "#DCDCDC"
    const val ghostwhite              = "#F8F8FF"
    const val gold                    = "#FFD700"
    const val goldenrod               = "#DAA520"
    const val gray                    = "#808080"
    const val green                   = "#008000"
    const val greenyellow             = "#ADFF2F"
    const val grey                    = "#808080"
    const val honeydew                = "#F0FFF0"
    const val hotpink                 = "#FF69B4"
    const val indianred               = "#CD5C5C"
    const val indigo                  = "#4B0082"
    const val ivory                   = "#FFFFF0"
    const val khaki                   = "#F0E68C"
    const val lavender                = "#E6E6FA"
    const val lavenderblush           = "#FFF0F5"
    const val lawngreen               = "#7CFC00"
    const val lemonchiffon            = "#FFFACD"
    const val lightblue               = "#ADD8E6"
    const val lightcoral              = "#F08080"
    const val lightcyan               = "#E0FFFF"
    const val lightgoldenrodyellow    = "#FAFAD2"
    const val lightgray               = "#D3D3D3"
    const val lightgreen              = "#90EE90"
    const val lightgrey               = "#D3D3D3"
    const val lightpink               = "#FFB6C1"
    const val lightsalmon             = "#FFA07A"
    const val lightseagreen           = "#20B2AA"
    const val lightskyblue            = "#87CEFA"
    const val lightslategray          = "#778899"
    const val lightslategrey          = "#778899"
    const val lightsteelblue          = "#B0C4DE"
    const val lightyellow             = "#FFFFE0"
    const val lime                    = "#00FF00"
    const val limegreen               = "#32CD32"
    const val linen                   = "#FAF0E6"
    const val magenta                 = "#FF00FF"
    const val maroon                  = "#800000"
    const val mediumaquamarine        = "#66CDAA"
    const val mediumblue              = "#0000CD"
    const val mediumorchid            = "#BA55D3"
    const val mediumpurple            = "#9370DB"
    const val mediumseagreen          = "#3CB371"
    const val mediumslateblue         = "#7B68EE"
    const val mediumspringgreen       = "#00FA9A"
    const val mediumturquoise         = "#48D1CC"
    const val mediumvioletred         = "#C71585"
    const val midnightblue            = "#191970"
    const val mintcream               = "#F5FFFA"
    const val mistyrose               = "#FFE4E1"
    const val moccasin                = "#FFE4B5"
    const val navajowhite             = "#FFDEAD"
    const val navy                    = "#000080"
    const val oldlace                 = "#FDF5E6"
    const val olive                   = "#808000"
    const val olivedrab               = "#6B8E23"
    const val orange                  = "#FFA500"
    const val orangered               = "#FF4500"
    const val orchid                  = "#DA70D6"
    const val palegoldenrod           = "#EEE8AA"
    const val palegreen               = "#98FB98"
    const val paleturquoise           = "#AFEEEE"
    const val palevioletred           = "#DB7093"
    const val papayawhip              = "#FFEFD5"
    const val peachpuff               = "#FFDAB9"
    const val peru                    = "#CD853F"
    const val pink                    = "#FFC0CB"
    const val plum                    = "#DDA0DD"
    const val powderblue              = "#B0E0E6"
    const val purple                  = "#800080"
    const val rebeccapurple           = "#663399"
    const val red                     = "#FF0000"
    const val rosybrown               = "#BC8F8F"
    const val royalblue               = "#4169E1"
    const val saddlebrown             = "#8B4513"
    const val salmon                  = "#FA8072"
    const val sandybrown              = "#F4A460"
    const val seagreen                = "#2E8B57"
    const val seashell                = "#FFF5EE"
    const val sienna                  = "#A0522D"
    const val silver                  = "#C0C0C0"
    const val skyblue                 = "#87CEEB"
    const val slateblue               = "#6A5ACD"
    const val slategray               = "#708090"
    const val slategrey               = "#708090"
    const val snow                    = "#FFFAFA"
    const val springgreen             = "#00FF7F"
    const val steelblue               = "#4682B4"
    const val tan                     = "#D2B48C"
    const val teal                    = "#008080"
    const val thistle                 = "#D8BFD8"
    const val tomato                  = "#FF6347"
    const val turquoise               = "#40E0D0"
    const val violet                  = "#EE82EE"
    const val wheat                   = "#F5DEB3"
    const val white                   = "#FFFFFF"
    const val whitesmoke              = "#F5F5F5"
    const val yellow                  = "#FFFF00"
    const val yellowgreen             = "#9ACD32"
}
