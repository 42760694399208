package csaware.systemdepend

import csaware.main.CsawareServices
import dk.rheasoft.csaware.api.SystemDependencyResource
import kafffe.core.KafffeComponentWithModel
import kafffe.core.KafffeHtml
import kafffe.core.KafffeHtmlBase
import kafffe.core.Model
import kafffe.core.modifiers.debounceEvent
import kotlinx.browser.window
import kotlinx.dom.addClass
import kotlinx.dom.removeClass
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.asList

class SystemSearch(private val selectedResourceModel: Model<SystemDependencyResource>, resourcesModel: Model<List<SystemDependencyResource>>) : KafffeComponentWithModel<List<SystemDependencyResource>>(resourcesModel) {
    private var inputElement: HTMLInputElement? = null
    private var dropdownElement: HTMLDivElement? = null

    override fun KafffeHtmlBase.kafffeHtml() =
            div {
                addClass("btn-group sd_search_bar ms-3")
                inputElement = input {
                    addClass("sd_search_input form-control")
                    withElement {
                        type = "text"
                        accessKey = "s"
                        onfocus = {
                            it
                        }
                        onblur = {
                            // delay display none to after click in selection, otherwise the click seems to be lost
                            window.setTimeout({ dropdownElement?.style?.display = "none" }, 600)
                            it
                        }
                        oninput =  debounceEvent(200,200) {
                            renderMatches()
                        }
                        onkeydown = {
                            when (it.key) {
                                "Escape" -> inputElement?.blur()
                                "Enter" -> inputElement?.blur()
                                "ArrowDown" -> selectNext()
                                "ArrowUp" -> selectPrev()
                            }
                            it
                        }
                    }
                }.element
                button {
                    onClick {
                        inputElement?.focus()
                    }
                    addClass("sd_search_icon btn")
                    i {
                        addClass("fas fa-search")
                    }
                }
                dropdownElement = div {
                    addClass("sd_dropdown bg-light")
                }.element
            }

    private val maxMatches: Int = 7
    private var selectIndex: Int = -1
    fun selectNext() {
        if (maxMatches >= selectIndex + 1) {
            ++selectIndex
        }
        select(selectIndex)
    }

    private fun selectPrev() {
        if (selectIndex > 0) {
            --selectIndex
        }
        select(selectIndex)
    }

    fun select(index: Int) {
        val matches = matches()
        if (index in matches.indices) {
            selectedResourceModel.data = matches[index]
            // set and remove "sd_selected" class
            dropdownElement?.children?.asList()?.forEachIndexed { i, element ->
                if (i == index) {
                    element.addClass("sd_selected")
                } else {
                    element.removeClass("sd_selected")
                }
            }
        }
    }

    fun matches(): List<SystemDependencyResource> {
        val txt = inputElement?.value ?: ""
        return if (txt.length > 1) {
            model.data.filter { systemDependencyResource -> systemDependencyResource.name.contains(txt, ignoreCase = true) } //
                    .union(model.data.filter { it.description.contains(txt, ignoreCase = true) }) //
                    .union(model.data.filter { systemDependencyResource ->
                        val fieldValues = systemDependencyResource.allFieldValues()
                        fieldValues.any { value -> value.contains(txt, ignoreCase = true) }
                    }) //
                    .take(maxMatches).toList()
        } else {
            listOf()
        }
    }


    private fun renderMatches() {
        selectIndex = -1
        dropdownElement?.let { dropdownElement ->
            dropdownElement.innerHTML = ""
            val htmlConsumer = KafffeHtml(dropdownElement)
            val matches = matches()

            for (sdr in matches) {
                val item =
                    htmlConsumer.div {
                        addClass("sd_dropdown_item")
                        val txt = inputElement!!.value
                        h4 { addHighlightMatch(txt, sdr.name) }
                        val textLength = 70
                        val descTxt =
                            if (sdr.description.contains(txt, ignoreCase = true)) {
                                val i = sdr.description.indexOf(txt, ignoreCase = true)
                                if (i < 10) sdr.description.take(textLength) + " ..." else "... " + (sdr.description.substring(
                                    i - 10
                                ).take(textLength)) + " ..."
                            } else {
                                sdr.description.take(textLength) + " ..."
                            }
                        div { addHighlightMatch(txt, descTxt) }
                        val singleField = CsawareServices.systemDependencyService.config.data.fields.find {
                            sdr.data[it.id]?.contains(txt, ignoreCase = true)
                                ?: false
                        }
                        if (singleField != null) {
                            div {
                                strong { +singleField.label }
                                text(": ")
                                addHighlightMatch(txt, sdr.data[singleField.id]!!)
                            }
                        } else {
                            val multiField = CsawareServices.systemDependencyService.config.data.fields.find { field ->
                                sdr.dataLists[field.id]?.let { values ->
                                    values.find {
                                        it.contains(
                                            txt,
                                            ignoreCase = true
                                        )
                                    } != null
                                } ?: false
                            }
                            if (multiField != null) {
                                div {
                                    strong { +multiField.label }
                                    text(": ")
                                    addHighlightMatch(txt, sdr.dataLists[multiField.id]!!.joinToString(", "))
                                }
                            }
                        }
                    }
                item.element.onclick = {
                    selectedResourceModel.data = sdr
                    inputElement?.blur()
                    it
                }
            }
            dropdownElement.style.display = if (matches.isEmpty()) "none" else "block"
        }
    }

    private fun KafffeHtml<out HTMLElement>.addHighlightMatch(tobeMatched: String, txt: String) {
        if (txt.contains(tobeMatched, ignoreCase = true)) {
            val startIx = txt.indexOf(tobeMatched, ignoreCase = true)
            text(txt.substring(0,startIx))
            span {
                addClass("bg-success")
                text(txt.substring(startIx, startIx + tobeMatched.length))
            }
            text(txt.substring(startIx + tobeMatched.length))
        } else {
            text(txt)
        }
    }

}