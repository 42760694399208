package dk.rheasoft.csaware.api

class QueryLimit(val offset: Int = 0, val size: Int = 20)

@kotlinx.serialization.Serializable
data class QueryResult<T : Any>(val nofResult: Int, val offset: Int = 0, val sizeRequested: Int = 10000, val result: List<T>) {
    constructor(nofResult: Int, result: List<T>, limit: QueryLimit) : this(nofResult, limit.offset, limit.size, result)

    companion object {
        fun <T : Any> empty() : QueryResult<T> = QueryResult(0, 0, 0, emptyList())
    }
}