package csaware.main

import csaware.comm.*
import csaware.messages.*
import csaware.systemdepend.SystemDependencyService
import csaware.utilities.ConfigurationService
import kafffe.bootstrap.Alert
import kafffe.bootstrap.AlertsPanel
import kafffe.bootstrap.BootstrapLevel
import kafffe.core.ServiceRegistry

object CsawareServices {
    // alerts registered by Csaware main it is a UI component.
    val alerts by lazy { ServiceRegistry.findByType(AlertsPanel::class)!! }
    val systemDependencyService by lazy { ServiceRegistry.findByType(SystemDependencyService::class)!! }

    val configrationService = ConfigurationService()

    val backend = CSAwareBackend(::exceptionHandler)
    val socialMediaBackend = SocialMediaBackend(::exceptionHandler)
    val systemDependenciesBackend = SystemDependenciesBackend(::exceptionHandler)
    val userBackend = UserBackend(::exceptionHandler)
    val threatsBackend = ThreatsBackend(::exceptionHandler)
    val infoShareBackend = InformationShareBackend(::exceptionHandler)
    val systemPreferencesBackend = SystemPreferencesBackend(:: exceptionHandler)

    fun exceptionHandler(@Suppress("UNUSED_PARAMETER") status: Int, message: String) {
        alerts.addAlert(Alert(message, BootstrapLevel.error))
    }

    fun initServices() {
        ServiceRegistry.register("csaware_en", CsawareMessages_en())
        ServiceRegistry.register("csaware_da", CsawareMessages_da())
        ServiceRegistry.register("csaware_it", CsawareMessages_it())
        ServiceRegistry.register("csaware_gr", CsawareMessages_el())
        ServiceRegistry.register("csaware_fr", CsawareMessages_fr())
        ServiceRegistry.register("csaware_de", CsawareMessages_de())

        ServiceRegistry.register("systemDependencies", SystemDependencyService())
    }

    fun refeshData() {
        systemDependencyService.refresh()
        systemDependencyService.refreshConfig()
        configrationService.refreshAll()
    }


}