package dk.rheasoft.csaware.api

import dk.rheasoft.csaware.utils.JsonUtilSerialization
import dk.rheasoft.csaware.utils.SystemDependencyResourceSerializer
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.decodeFromJsonElement
import kotlinx.serialization.json.encodeToJsonElement
import kotlinx.serialization.json.jsonObject

@Serializable
data class SystemDependency(
    var type: String,
    var id: String,
    var name: String,
    var created: Instant,
    var modified: Instant,
    var version: String,
    var objects: MutableList<SystemDependencyResource> = mutableListOf()
) {
    /**
     * Replaces system dependency resources "source" that reference other by name, with id of other.
     */
    fun resolveSourceReferenceByName() {
        Companion.resolveSourceReferenceByName(objects)
    }

    fun toJsonString(): String =
        JsonUtilSerialization.json.encodeToString(this)

    fun toJsonObject(): JsonObject =
        JsonUtilSerialization.json.encodeToJsonElement(this).jsonObject

    fun cloneToHeaderWithoutObjects() =
        fromJson(toJsonString()).apply { objects = mutableListOf() }

    companion object {
        /**
         * Replaces system dependency resources "source" that reference other by name, with id of other.
         */
        fun resolveSourceReferenceByName(resources: List<SystemDependencyResource>) {
            val resourcesNameMap = resources.associateBy { it.name }
            val resourcesIdMap = resources.associateBy { it.id }

            for (resource: SystemDependencyResource in resources) {
                val idList = resource.source.map { sourceKey: String ->
                    if (sourceKey !in resourcesIdMap.keys && sourceKey in resourcesNameMap.keys)
                        resourcesNameMap[sourceKey]!!.id
                    else sourceKey
                }
                resource.source = idList.toMutableList()
            }
        }

        fun fromJson(json: JsonObject): SystemDependency =
            JsonUtilSerialization.json.decodeFromJsonElement(json)

        fun fromJson(json: String): SystemDependency =
            JsonUtilSerialization.json.decodeFromString(json)

        var NULL = SystemDependency("", "---", "", Clock.System.now(), Clock.System.now(), "")

        fun fromStixJson(json: JsonObject): SystemDependency =
            JsonUtilSerialization.json.decodeFromJsonElement(json)
    }
}

/**
 * Holds data of a resource from Graphing "Wiki".
 */
@Serializable(with = SystemDependencyResourceSerializer::class)
data class SystemDependencyResource(
    var id: String,
    var name: String,
    var created: Instant,
    var modified: Instant,
    var description: String,
    var source: MutableList<String> = mutableListOf(),
    @Suppress("PropertyName") var x_infoflow: MutableList<String> = mutableListOf(),
    /** single value fields */
    var data: MutableMap<String, String> = mutableMapOf(),
    /** multi value fields */
    var dataLists: MutableMap<String, MutableList<String>> = mutableMapOf()
) {

    fun hasId() = id.isNotEmpty() && id != NULL.id

    companion object {
        const val KEYWORDS_ATTRIBUTE = "x_categories"

        val NULL = SystemDependencyResource("----", "", Clock.System.now(), Clock.System.now(), "")
        val definedPropertyNames = setOf(
            SystemDependencyResource::id.name,
            SystemDependencyResource::name.name,
            SystemDependencyResource::created.name,
            SystemDependencyResource::modified.name,
            SystemDependencyResource::description.name,
            SystemDependencyResource::source.name,
            SystemDependencyResource::x_infoflow.name,
            SystemDependencyResource::data.name,
            SystemDependencyResource::dataLists.name,
            "type", "relationship_type"
        )

        fun fromJson(json: JsonObject): SystemDependencyResource =
            JsonUtilSerialization.json.decodeFromJsonElement(json)

        fun fromJson(json: String): SystemDependencyResource =
            JsonUtilSerialization.json.decodeFromString(json)

        fun fromListJson(json: String): List<SystemDependencyResource> =
            JsonUtilSerialization.json.decodeFromString(json)
    }

    val keywords: Set<String>
        get() = dataLists[KEYWORDS_ATTRIBUTE]?.toSet() ?: emptySet()

    fun getFieldSingleValue(fieldId: String) : String? = data[fieldId]
    fun getFieldMultipleValueField(fieldId: String) : List<String> = dataLists[fieldId] ?: listOf()

    fun allFieldValues() = data.values + dataLists.values.flatten() + x_infoflow

    /**
     * Checks if the given field has a value in this
     */
    fun hasValue(field: SystemDependencyField) =
        if (field.cardinality.isSingle) !getFieldSingleValue(field.id).isNullOrBlank() else getFieldMultipleValueField(field.id).isNotEmpty()

    fun toJsonString(): String =
        JsonUtilSerialization.json.encodeToString(this)

    fun toJsonObject(): JsonObject =
        JsonUtilSerialization.json.encodeToJsonElement(this).jsonObject

}

@Serializable
data class SystemDependencyResourceWithSourceInOthers(
    val systemDependencyResource: SystemDependencyResource,
    val sourceInOtherResourceIds: Set<String>
) {
    fun toJsonString(): String =
        JsonUtilSerialization.json.encodeToString(this)

    companion object {
        fun fromJson(json: String): SystemDependencyResourceWithSourceInOthers =
            JsonUtilSerialization.json.decodeFromString(json)
    }
}