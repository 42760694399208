package csaware.systemdepend.graph

import csaware.overview.get
import dk.rheasoft.csaware.api.SystemDependencyConfig
import dk.rheasoft.csaware.api.SystemDependencyResource
import dk.rheasoft.csaware.api.SystemDependencyResourcesThreatOverview
import kafffe.core.*
import kotlinx.browser.window
import mxgraph.*
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement

class SystemGraph(
    resourcesModel: Model<List<SystemDependencyResource>>,
    private val configModel: Model<SystemDependencyConfig>,
    private val threatSummary: Model<SystemDependencyResourcesThreatOverview>,
    private val resourceCountModel: Model<Map<String, Long>>,
    selectResource: SystemDependencyResource? = null
) : KafffeComponentWithModel<List<SystemDependencyResource>>(resourcesModel) {

    private fun debounceRerender(waitMs: Int, waitFirstMs: Int): () -> Unit {
        var timeoutHandle: Int? = null
        return {
            val waitingFunc = {
                timeoutHandle = null
                rerender()
            }

            timeoutHandle = if (timeoutHandle == null) {
                // First time in period handle directly
                window.setTimeout(waitingFunc, waitFirstMs)
            } else {
                window.clearTimeout(timeoutHandle!!)
                window.setTimeout(waitingFunc, waitMs)
            }
        }
    }

    init {
        modelChangedStandardBehavior = debounceRerender(300, 300)
    }

    val selectionModel: Model<SystemDependencyResource> = Model.of(selectResource ?: SystemDependencyResource.NULL)
    val dependencyFieldModel: ModelNullable<String> = Model.ofNullable(null)

    val hightlightModel: Model<String> = Model.of(SELECTION_HIGHLIGHT)

    private var graph: StaticMxGraph? = null

    private var resources: List<SystemDependencyResource> by delegateToModel()

    val graphConfig: SystemDependencyConfig
        get() = configModel.data

    // An optional  user specified node id used for layout
    var currentUserRootId: String? = null
        set(value) {
            field = value
            graph?.doLayout()
        }

    fun cellToResource(cell: mxCell) = resources.find { it.id == cell.getId() }

    private fun idToCell(id: String): mxCell? {
        val vertices = graph?.getChildCells(graph?.getDefaultParent()!!, vertices = true, edges = false)
        return vertices?.find { it.id == id }
    }

    private val onThreatsChanged = ModelChangeListener {
        graph?.applyStyleToAllElements()
    }

    private val onSelectionHighlightChanged = ModelChangeListener {
        graph?.makeSelectionVisible()
        graph?.applyStyleToAllElements()
    }

    override fun attach() {
        super.attach()
        threatSummary.listeners.add(onThreatsChanged)
        hightlightModel.listeners.add(onSelectionHighlightChanged)
        selectionModel.listeners.add(onSelectionHighlightChanged)
        dependencyFieldModel.listeners.add(onModelChanged)
        configModel.listeners.add(onModelChanged)
    }

    override fun detach() {
        super.detach()
        graph?.let {
            it.destroy()
            graph = null
        }
        threatSummary.listeners.remove(onThreatsChanged)
        hightlightModel.listeners.remove(onSelectionHighlightChanged)
        selectionModel.listeners.remove(onSelectionHighlightChanged)
        dependencyFieldModel.listeners.remove(onModelChanged)
        configModel.listeners.remove(onModelChanged)
    }

    fun riskStyle(resourceId: String): String {
        val severity = threatSummary.data[resourceId].severityMax
        return "severity-$severity"
    }

    private fun getCurrentConnections(resource: SystemDependencyResource): List<String> =
        dependencyFieldModel.data
            ?.let{resource.getFieldMultipleValueField(it)}
            ?: resource.source

    private fun getResourcesToInclude(): List<SystemDependencyResource> =
        dependencyFieldModel.data?.let { field: String ->
            resources.filter { res ->
                res.getFieldMultipleValueField(field).isNotEmpty()
                        || resources.find { res2 -> res2.getFieldMultipleValueField(field).contains(res.id) } != null
            }
        } ?: resources

    fun filterIncludeNodeIds(nodeIds: List<String>): List<String> {
        val includedIds = getResourcesToInclude().map { it.id }.toSet()
        return nodeIds.filter { it in includedIds }
    }


    private fun updateGraph() {
        val invisibleEdges = mutableListOf<mxCell>()
        val doubleArrows = mutableListOf<mxCell>()
        if (resources.isNotEmpty()) {
            graph?.let {
                val g = it
                g.popupMenuHandler.hideMenu()
                g.setHtmlLabels(true)
                try {
                    val p = g.getDefaultParent()
                    g.getModel().beginUpdate()
                    g.removeCells(g.getChildCells(g.getDefaultParent(), vertices = true, edges = true))

                    val idToVertex = mutableMapOf<String, mxCell>()
                    val pairToEdge = mutableMapOf<Pair<String, String>, mxCell>()

                    for (resource in getResourcesToInclude()) {
                        val vertex = g.insertVertex(
                            p,
                            resource.id,
                            resource.name,
                            0.0,
                            0.0,
                            180.0,
                            180.0,
                            nodeTypeToShape(resource.data["x_csaware_node_type"]) + ";vertice_normal_color"
                        )
                        idToVertex[resource.id] = vertex
//                        g.updateCellSize(vertex, true)
                        updateResourceLabel(resource)
                    }
                    for (resource in resources) {
                        for (connect in getCurrentConnections(resource)) {
                            val fromCell = idToVertex[resource.id]
                            var toCell = idToVertex[connect]
                            if (toCell == null) {
                                // Hack missing data in graph
                                toCell = g.insertVertex(p, connect, connect, 0.0, 0.0, 80.0, 30.0, "edge")
                                idToVertex[connect] = toCell
//                                g.updateCellSize(toCell, true)
                            }
                            val doubleEdge: mxCell? = doesEdgeExist(fromCell, toCell, pairToEdge)
                            if (doubleEdge != null) {
                                val edge: mxCell = g.insertEdge(p, resource.id + "->" + connect, "", fromCell!!, toCell)
                                pairToEdge[Pair(fromCell.id, toCell.id)] = edge
                                invisibleEdges.add(edge)
                                doubleArrows.add(doubleEdge)
                            } else {
                                val edge: mxCell = g.insertEdge(p, resource.id + "->" + connect, "", fromCell!!, toCell)
                                pairToEdge[Pair(fromCell.id, toCell.id)] = edge
                            }
//                            g.insertEdge(p, resource.id + "->" + connect, "", fromCell!!, toCell)
                        }
                    }
                } catch (a: Throwable) {
                    println(a)
                } finally {
                    g.setInvisibleEdges(invisibleEdges)
                    g.setDoubleArrowEdges(doubleArrows)
                    g.applyStyleToAllElements()
                    g.getModel().endUpdate()
                    g.doLayout()
                    // Delayed to make sure the outline is refreshed after config changes.
                    window.setTimeout({ g.outline.update(true) }, 300)
                }
            }
        }
    }

    private fun resourceCount(resource: SystemDependencyResource): Long = resourceCountModel.data[resource.id] ?: 0L

    private fun updateResourceLabel(resource: SystemDependencyResource) {
        val label: String = resource.name.map {
            when (it) {
                '&' -> "&amp;"
                '<' -> "&lt;"
                '>' -> "&gt;"
                '"' -> "&uot;"
                '\'' -> "&#39;"
                else -> it.toString()
            }
        }.joinToString("")
        fun labelWithBadge(label: String, fontSize: String, bg: String, num: Long) =
            """<div style='position: relative;'>
                |$label
                |<badge style='font-size: $fontSize' class='sysdep_badge badge badge-pill $bg text-dark'>
                |  <i class='fas fa-share-alt'></i>
                |  $num
                | </badge>
                |</div>""".trimMargin().replace("\n", "").replace("\r", "")
        val node: mxCell? = idToCell(resource.id)
        if (node != null) {
            val decoratedLabel = when (val nofMessages = resourceCount(resource)) {
                0L -> label
                in 1L .. 10L -> labelWithBadge(label, "8px", "bg-info", nofMessages)
                else -> labelWithBadge(label, "9px", "bg-warning", nofMessages)
            }
            node.setValue(decoratedLabel)
        }
    }

    private fun doesEdgeExist(from: mxCell?, to: mxCell?, pairToEdge: Map<Pair<String, String>, mxCell>): mxCell? {
        if (from == null || to == null) {
            return null
        }
        if (pairToEdge.containsKey(Pair(from.id, to.id))) return pairToEdge[Pair(from.id, to.id)]
        if (pairToEdge.containsKey(Pair(to.id, from.id))) return pairToEdge[Pair(to.id, from.id)]
        return null
    }

    fun addEdgeToMap(name: String, map: MutableMap<String, MutableList<mxCell>>, element: mxCell) {
        map.getOrPut(name) { mutableListOf() }.add(element)
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {
        var graphElement: HTMLElement? = null
        var outlineElement: HTMLElement? = null
        graph?.destroy()
        return div {
                    withElement {
                        style.apply {
                            backgroundColor = "var(--bs-secondary)"
                            position = "relative"
                            width = "100%"
                            height = "85vh"
                        }
                    }
                    div {
                        withElement {
                            graphElement = this
                            style.apply {
                                width = "100%"
                                height = "100%"
                                padding = "1em"
                                overflowX = "hidden"
                                overflowY = "hidden"
                            }
                        }
                    }
                    div {
                        withElement {
                            id = "outlineContainer"
                            outlineElement = this
                            style.apply {
                                zIndex = "1"
                                position = "absolute"
                                overflowX = "hidden"
                                overflowY = "hidden"
                                bottom = "1vh"
                                left = "8px"
                                width = "20%"
                                height = "17vh" // 20% of 85vh
                                borderStyle = "solid"
                                border= "1px solid darkgrey"
                                borderRadius = "0.3rem"
                                backgroundColor = "rgba(108, 117, 125, 0.7)"
                            }
                        }
                    }

                    // need both div for graph and outline
                    graphElement?.let {
                        graph = StaticMxGraph(it, outlineElement!!, this@SystemGraph)
                        mxEvent.disableContextMenu(it)
                    }
                    zoomButtons()
                    updateGraph()
                }
    }

    private fun KafffeHtml<HTMLDivElement>.zoomButtons() {
        val btnClass = "btn btn-sm btn-secondary opacity-75"
        div {
            addClass("btn-group")
            element.style.apply {
                border = "1px darkgrey solid"
                zIndex = "2"
                position = "absolute"
                top = "1vh"
                left = "8px"
                backgroundColor = "rgba(108, 117, 125, 0.7)"
                // opacity = "0.7"
            }
            button {
                addClass(btnClass)
                i { addClass("fas fa-search-minus fa-2x") }
                onClick { graph?.zoomOut() }
            }
            button {
                addClass(btnClass)
                span {
                    addClass("fa-stack")
                    i { addClass("fas fa-search fa-stack-2x") }
                    i { addClass("far fa-square fa-stack-1x") }
                }
                onClick { graph?.fit() }
            }
            button {
                addClass(btnClass)
                i { addClass("fas fa-search-plus fa-2x") }
                onClick { graph?.zoomIn() }
            }
        }
    }

    fun nodeTypeToShape(nodeType: String?): String {
        return nodeType?.let { graphConfig.shapeMap[it] } ?: "rectangle_shape"
    }

    fun panToSelection() {
        if (graph != null && isRendered) {
            graph?.makeSelectionVisible()
        } else {
            window.setTimeout({ panToSelection() }, 500)
        }
    }

    companion object {
        const val SELECTION_HIGHLIGHT = "SELECTION_HIGHLIGHT_NOT_A_CATEGORY"
    }

}