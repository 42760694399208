package csaware.test

import csaware.comm.CSAwareBackend
import csaware.main.CsawareServices
import dk.rheasoft.csaware.api.SystemDependencyResource
import kafffe.bootstrap.*
import kafffe.bootstrap.form.*
import kafffe.core.*
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kafffe.core.modifiers.HtmlElementModifier
import kafffe.core.modifiers.StyleModifier
import kafffe.core.modifiers.onchange
import org.w3c.dom.HTMLTextAreaElement

class BundleTestPage : DivContainer() {
    private val bundleData = ""

    val alerts = addChild(AlertsPanel())

    data class FormData(var bundle: String, var bundleName: String = "")

    val input = Model.of(FormData(bundleData))
    private val bundleNames: Model<List<String>> = Model.of(listOf(""))
    private val resourceIds: Model<List<String>> = Model.ofGet { CsawareServices.systemDependencyService.model.data.sortedBy(
        SystemDependencyResource::name).map { it.id } }

    private val systemNodeId: Model<String?> = Model.ofNullable(null)
    private val systemNodeSelect = object : SingleEditSelect<String>("systemNode", systemNodeId, resourceIds) {
        override fun display(choice: String?): String = choice?.let { CsawareServices.systemDependencyService.byId(choice)?.name }
                ?: ""
    }

    private var textAreaElement: HTMLTextAreaElement? = null

    val form = addChild(BootstrapForm(input).apply {
        cssClassModifier("hgap-3")
        //        row {
//            col(ColWidth(ResponsiveSize.sm, 4)) {
        button(Model.of("Delete Demo Threat")) { clearDemoThreat() }.color = BasicColor.warning
        button(Model.of("Generate some random Threats")) { generateThreats() }.color = BasicColor.info
//            }.apply { modifiers.add(CssClassModifier("hgap-3")) }
//        }

        group {
            row {
                col(ResponsiveSize.sm.col(6)) {
                    val bundleSelector: DropdownString = dropdown(FormData::bundleName, bundleNames)
                    bundleSelector.onchange {
                        bundleSelector.updateValueModel()
                        loadBundle()
                    }
                }
                col(ResponsiveSize.sm.col(6)) {
                    decorateAndAdd(Model.of("Insert System Node"), systemNodeSelect)
                    systemNodeSelect.changeListeners.add(::addNodeIdToWhereSightedRef)
                }
            }
        }

        textArea(FormData::bundle).apply {
            modifiers.add(StyleModifier {
                height = "70vh"
                // width = "80vw"
            })
            required = true
            modifiers.add(HtmlElementModifier.create { textAreaElement = this as HTMLTextAreaElement })
        }

        submit("Visualisation", this@BundleTestPage::postVisualisation).color = BasicColor.primary
        submit("Self Healing", this@BundleTestPage::postSelfHealing).color = BasicColor.info
        submit("Information Sharing", this@BundleTestPage::postInformationSharing).color = BasicColor.info
    })

    private fun addNodeIdToWhereSightedRef(@Suppress("UNUSED_PARAMETER") source: SingleEditSelect<String>, nodeId: String?) {
            if (!nodeId.isNullOrEmpty()) {
                textAreaElement?.let { ta ->
                    val current = ta.value
                    if (current.contains("where_sighted_refs")) {
                        val startIx = current.indexOf("[", current.indexOf("where_sighted_refs"))
                        val inserted = current.substring(0, startIx + 1) + " \"$nodeId\", " + current.substring(startIx + 1)
                        ta.value = inserted
                    }
                }
            }
    }

    private fun postVisualisation() {
        alerts.clearAlerts()
        println(input.data.bundle)
        backend().sendJsonTxt("/test/bundle/consume", "POST", input.data.bundle) {
            alerts.addAlert(Alert("Bundle send: $it", BootstrapLevel.info))
        }
    }

    private fun postSelfHealing() {
        alerts.clearAlerts()
        println(input.data.bundle)
        backend().sendJsonTxt("/test/selfhealing", "POST", input.data.bundle) {
            alerts.addAlert(Alert("Bundle send: $it", BootstrapLevel.info))
        }
    }

    private fun postInformationSharing() {
        alerts.clearAlerts()
        println(input.data.bundle)
        backend().sendJsonTxt("/test/informationsharing", "POST", input.data.bundle) {
            alerts.addAlert(Alert("Bundle send: $it", BootstrapLevel.info))
        }
    }
    private fun errorHandler(code: Int, text: String) {
        alerts.addAlert(Alert("($code) $text", BootstrapLevel.danger))
    }

    private fun backend() = CSAwareBackend(this::errorHandler)

    private fun loadBundle() {
        backend().getTxt("/test/usecase/analysis/" + input.data.bundleName) { data ->
            input.data = input.data.also { it.bundle = data }
            rerenderRecursive()
        }

    }

    private fun clearDemoThreat() {
        alerts.clearAlerts()
        backend().sendJsonTxt("/test/threats/clearById?id=sighting--8356e820-8080-4692-aa91-ecbe94006833", "POST", "") {
            alerts.addAlert(Alert("Demo Threat deleted", BootstrapLevel.info))
        }
    }

    private fun generateThreats() {
        alerts.clearAlerts()
        backend().sendJsonTxt("/test/threats/generate", "POST", "") {
            alerts.addAlert(Alert("Threats generated", BootstrapLevel.info))
        }
    }


    init {
        backend().getTxt("/test/usecase/analysis/list") {
            val json: Array<String> = JSON.parse(it)
            bundleNames.data = json.toList()
            rerenderRecursive()
        }
    }
}