package csaware.utilities

import kafffe.core.KafffeHtml
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLHeadingElement

/**
 * Standard title formatting for content components
 */
fun <T : HTMLElement> KafffeHtml<T>.componentTitle(titleBodyProvider: KafffeHtml<HTMLHeadingElement>.() -> Unit) =
    h4 {
        addClass("mb-2 mt-4")
        this.titleBodyProvider()
    }