@file:Suppress("UnsafeCastFromDynamic", "LeakingThis")

package csaware.systemdepend.graph

import csaware.main.UserConfiguration
import csaware.main.UserInformation
import csaware.messages.csawareMessages
import csaware.systemdepend.UiFunctions
import csaware.systemdepend.graph.shapes.NodeShapeRegistry
import dk.rheasoft.csaware.api.SystemDependencyResource
import dk.rheasoft.csaware.api.access.MainFeature
import dk.rheasoft.csaware.api.access.Permission
import dk.rheasoft.csaware.api.access.UserRole
import mxgraph.*
import org.w3c.dom.HTMLElement

open class StaticMxGraph(graphElement: HTMLElement, outlineElement: HTMLElement, private val systemGraph: SystemGraph) : MxGraphWithStyles(graphElement) {
    var outline: mxOutline
    private var layout: mxHierarchicalLayout
    private var invisibleEdges = mutableListOf<mxCell>()
    private var doubleArrorEdges = mutableListOf<mxCell>()

    fun setInvisibleEdges(invisibleEdgeList: MutableList<mxCell>) {
        invisibleEdges = invisibleEdgeList
    }

    fun setDoubleArrowEdges(doubleArrowList: MutableList<mxCell>) {
        doubleArrorEdges = doubleArrowList
    }

    override fun isCellSelectable(cell: Any): Boolean {
        return cell is mxCell && cell.isVertex()
    }

    override fun isCellMovable(cell: Any): Boolean = false
    override fun isCellEditable(cell: Any): Boolean = false
    override fun isCellResizable(cell: Any): Boolean = false

    private fun cellForSystemDependencyResource(resource: SystemDependencyResource): mxCell? =
        getChildCells(getDefaultParent()).find { it.id == resource.id }

    private fun cellForSystemDependencyResourceId(nodeId: String): mxCell? =
        getChildCells(getDefaultParent()).find { it.id == nodeId }

    fun doLayout() {
        val rootIds =
            systemGraph.filterIncludeNodeIds(
                when {
                    systemGraph.currentUserRootId != null -> listOf(systemGraph.currentUserRootId!!)
                    else -> systemGraph.graphConfig.rootNodeIds
                }
            )

        val layoutRoots = rootIds.mapNotNull { cellForSystemDependencyResourceId(it) }.toTypedArray()

        if (layoutRoots.isEmpty()) {
            layout.execute(getDefaultParent())
        } else {
            layout.execute(getDefaultParent(), roots = layoutRoots)
        }
    }

    @Suppress("UNUSED_PARAMETER")
    private fun selectionHandler(sender: Any, event: Any) {
        val selectedCell = getSelectionCell()
        val res = selectedCell?.let { systemGraph.cellToResource(it) }
        systemGraph.selectionModel.data = res ?: SystemDependencyResource.NULL
    }

    private fun vertices() = getChildCells(getDefaultParent(), vertices = true, edges = false)
    private fun edges() = getChildCells(getDefaultParent(), vertices = false, edges = true)

    private fun allNeighbourEdgeIds(cell: mxCell): Set<String> {
        val edges = getEdges(cell, null, incoming = true, outgoing = true, includeLoops = true)
        return edges.map { it.id }.toSet()
    }

    private fun allNeighbourCellIds(cell: mxCell): Set<String> {
        val incomming = getIncomingEdges(cell).map { it.source }.map { it.id }
        val outgoing = getOutgoingEdges(cell).map { it.target }.map { it.id }
        return incomming.union(outgoing)
    }

    fun makeSelectionVisible() {
        val selectedResource = systemGraph.selectionModel.data
        val selectedCell = cellForSystemDependencyResource(selectedResource)
        if (selectedCell != null) {
            scrollCellToVisible(selectedCell, false)
        }
    }

    fun applyStyleToAllElements() {
        val selectedResource = systemGraph.selectionModel.data
        val selectedCell = cellForSystemDependencyResource(selectedResource)
        val neighbourCellIds: Set<String> = selectedCell?.let { allNeighbourCellIds(it) } ?: setOf()
        val neighbourEdgeIds: Set<String> = selectedCell?.let { allNeighbourEdgeIds(it) } ?: setOf()
        val idsWithLabel = if (systemGraph.hightlightModel.data == SystemGraph.SELECTION_HIGHLIGHT) {
            vertices().map { it.id }
        } else {
            systemGraph.model.data.filter { it.x_infoflow.contains(systemGraph.hightlightModel.data) }.map { it.id }
        }
        for (edge in edges()) {
            var edgeStyle = if (edge.id in neighbourEdgeIds) "edge_highlight" else "edge"
            val lowlight = !(edge.target.id in idsWithLabel && edge.source.id in idsWithLabel)
            if (lowlight) {
                edgeStyle += ";lowlight"
            }
            edgeStyle += if (doubleArrorEdges.indexOf(edge) > -1) {
                ";doubleArrow"
            } else {
                ";singleArrow"
            }
            setCellStyle(edgeStyle, arrayOf(edge))
        }
        for (vertice in vertices()) {
            var cellStyle =
                systemGraph.nodeTypeToShape(systemGraph.cellToResource(vertice)?.data?.get("x_csaware_node_type"))
            cellStyle += if (vertice.id in neighbourCellIds) ";vertice_highlight_color" else ";vertice_normal_color"
            cellStyle += ";${systemGraph.riskStyle(vertice.id)}"
            if (vertice.id !in idsWithLabel) {
                cellStyle += ";lowlight"
            }
            setCellStyle(cellStyle, arrayOf(vertice))
            updateCellSize(vertice, true)
        }

        invisibleEdges.forEach { it.setVisible(false) }
    }

    init {
        getSelectionModel().addListener(mxEvent.CHANGE, this::selectionHandler)

        mxPanningManager(this)
        panningHandler.asDynamic()["popupMenuHandler"] = false

        apply {
            setConnectable(false)
            setPanning(true)
            centerZoom = false
            setAutoSizeCells(false)
            panningHandler.useLeftButtonForPanning = true
        }

        layout = mxHierarchicalLayout(
            this,
            orientation = if (systemGraph.graphConfig.layoutDirection.isVertical()) mxConstants.DIRECTION_NORTH else mxConstants.DIRECTION_WEST
        ).apply {
            interRankCellSpacing = systemGraph.graphConfig.spacing
        }

        if (UserInformation.hasAccess(MainFeature.SystemDependencies, Permission.Write)) {
            popupMenuHandler.factoryMethod = { menu, cell, _ ->
                popupmenuFactory(cell, menu)
            }
        }

        outline = mxOutline(this, outlineElement)
    }

    open fun popupmenuFactory(cell: mxCell?, menu: mxPopupMenu) {
        val resource: SystemDependencyResource? = cell?.let { systemGraph.cellToResource(it) }
        val sm = systemGraph.selectionModel
        val usedAsLayoutRoot = resource?.id == null || resource.id == systemGraph.currentUserRootId
        val layoutRootMsg = if (usedAsLayoutRoot) {
            csawareMessages().system_depend_layout_default_root
        } else {
            csawareMessages().system_depend_layout_user_root
        }
        menu.addItem(
            layoutRootMsg,
            null,
            { systemGraph.currentUserRootId = if (usedAsLayoutRoot) null else resource?.id },
            iconCls = "fas fa-sitemap"
        )
        menu.addSeparator()

        if (resource != null) {
            for (func in UiFunctions.resourceFunctions) {
                if (UserInformation.hasAccess(func.feature, func.permission)) {
                    menu.addItem(func.label, null, { func.doIt(resource, sm) }, iconCls = func.iconCls)
                }
            }

        } else {
            for (func in UiFunctions.globalFunctions) {
                if (UserInformation.hasAccess(func.feature, func.permission)) {
                    menu.addItem(
                        func.label,
                        null,
                        { func.doIt(SystemDependencyResource.NULL, sm) },
                        iconCls = func.iconCls
                    )
                }
            }
        }
        menu.addSeparator()

        if (systemGraph.hightlightModel.data == SystemGraph.SELECTION_HIGHLIGHT) {
            menu.addItem(
                csawareMessages().system_depend_label_all,
                null,
                { systemGraph.hightlightModel.data = SystemGraph.SELECTION_HIGHLIGHT },
                iconCls = "fas fa-check"
            )
        } else {
            menu.addItem(
                csawareMessages().system_depend_label_all,
                null,
                { systemGraph.hightlightModel.data = SystemGraph.SELECTION_HIGHLIGHT })
        }
        val tags = resource?.x_infoflow ?: systemGraph.graphConfig.getValueSet("infoflow")
        for (tag in tags.sorted()) {
            if (systemGraph.hightlightModel.data == tag) {
                menu.addItem(tag, null, { systemGraph.hightlightModel.data = tag }, iconCls = "fas fa-check")
            } else {
                menu.addItem(tag, null, { systemGraph.hightlightModel.data = tag })
            }
        }
    }

    override fun createVertexHandler(state: Any): mxVertexHandler {
        return object : mxVertexHandler(state) {

            /**
             * Override default selection bounds to be square (and shape is ellipse => circle)
             */
            override fun getSelectionBounds(state: Any): mxRectangle {
                val bounds = super.getSelectionBounds(state)
                val x = bounds.x.toInt()
                val y = bounds.y.toInt()
                val width = bounds.width.toInt()
                val height = bounds.height.toInt()
                val newHeight = height + 40
                val newWidth = width - 10
                val newX = x  + (width - newWidth) / 2
                val newY = y + (height - newHeight) / 2 + 10
                val newBounds = mxRectangle(newX, newY, newWidth, newHeight)
                return newBounds
            }

            /**
             * Override default selection rectangular shape to ellipse and bounds are overridden to be  square
             */
            override fun createSelectionShape(bounds: mxRectangle): mxShape {
                val newBounds = mxRectangle(bounds.x, bounds.y.toInt() - 10, bounds.width, bounds.height.toInt() + 20  )
                val shape = mxEllipse(newBounds,null, getSelectionColor(), getSelectionStrokeWidth())
                shape.strokewidth = getSelectionStrokeWidth()
                shape.isDashed = isSelectionDashed()
                return shape
            }
        }
    }
}