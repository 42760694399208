package csaware.main

import csaware.comm.CSAwareBackend
import csaware.infoshare.InformationShareView
import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import csaware.overview.ThreatDashBoard
import csaware.socialmedia.SocialMediaReader
import csaware.socialmedia.administration.SocialMediaAccountManagement
import csaware.systemdepend.SystemDependencies
import csaware.systempreferences.SystemPreferencesEdit
import csaware.threats.ThreatsTableCurrentActive
import csaware.threats.ThreatsTableRecentClosed
import csaware.translation.AutoTranslationModel
import csaware.user.UserManagement
import csaware.userpreferences.UserPreferencesEdit
import dk.rheasoft.csaware.api.UpdateEvent
import dk.rheasoft.csaware.api.access.MainFeature
import dk.rheasoft.csaware.api.access.Permission
import kafffe.bootstrap.*
import kafffe.bootstrap.navigation.Nav
import kafffe.bootstrap.navigation.NavItem
import kafffe.core.*
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kafffe.core.modifiers.HtmlElementModifier
import kafffe.messages.Messages
import kafffe.messages.MessagesObject
import kafffe.messages.i18nText
import kafffe.messages.messagesModel
import kotlinx.browser.window
import org.w3c.dom.HTMLImageElement
import org.w3c.dom.get

private var infoItem: NavItem? = null
private var infoCount: Int = 0

fun updateInfoCount(count: Int) {
    infoCount = count
    infoItem?.rerender()
}

fun updateInfoCount() {
    CsawareServices.infoShareBackend.getInformationShareCount(::updateInfoCount)
}

private lateinit var rootNavigation: NavigationElement
private lateinit var rootNav: Nav

fun navigateTo(path: String) {
    val navigationPath = NavigationPath.fromString(path)
    rootNavigation.navigateTo(navigationPath)
    rootNav.currentActivePath(navigationPath)
}

@OptIn(ExperimentalJsExport::class)
@JsExport
@JsName("cs_initUI")
fun initUI() {
    val alerts = AlertsPanel()
    val autoTranslationModel = AutoTranslationModel
    ServiceRegistry.register("alerts", alerts)
    CsawareServices.initServices()
        UserInformation.loadCurrent {
        MessagesObject.currentLanguage = UserInformation.current.preferences.uiLanguage.shortName
        messagesModel.changed()
        CsawareServices.refeshData()
        // Load system administrators cache
        UserInformation.refreshThreatManagers()

        rootNavigation = NavigationElement.create("root") {
            if (UserInformation.hasAnyAccess()) {
                component("pie") { ThreatDashBoard() }
                component("threats") { ThreatsTableCurrentActive() }
                component("threatsClosed") { ThreatsTableRecentClosed() }
                component("socialmedia") { SocialMediaReader() }
                if (UserInformation.hasAccess(MainFeature.SocialMediaAccount, Permission.Write)) {
                    component("socialmedia_management") { SocialMediaAccountManagement() }
                }
                component("systemDependencies") { path: NavigationPath ->
                    val nodeName = if (!path.rest.empty) path.rest.head else "none"
                    val systemDependencyResource = CsawareServices.systemDependencyService.byName(nodeName)
                    SystemDependencies(systemDependencyResource)
                }
            }
            if (UserInformation.hasAccess(MainFeature.InformationSharing, Permission.Read)) {
                component("infoshare") { InformationShareView() }
            }
            if (UserInformation.hasAccess(MainFeature.UserManagement, Permission.Read)) {
                component("users") { UserManagement() }
            }

            component("about") { About() }
            component("signout") { Signout() }

            sub("userpreferences"){
                onNavigateTo ={
                    UserPreferencesEdit(UserInformation.current).apply{
                        attach()}
                }
            }
            if (UserInformation.hasAccess(MainFeature.SystemPreferences, Permission.Write)) {
                sub("systempreferences") {
                    onNavigateTo = {
                        SystemPreferencesEdit().apply {
                            attach()
                        }
                    }
                }
            }
        }
        BootstrapRoot().apply {
            val container = BootstrapContainer.fluid()
            rootNavigation.componentNavigator = { container.replaceContent(it) }
            addChild(Nav.create(rootNavigation) {
                rootNav = this
                addExpand(ResponsiveSize.xl)
                style = ColorStrength.light
                background = BasicColor.primary
                brand("", "images/SVG-logo-cs-aware_white.svg", imageHeight = "2rem",path =  NavigationPath.fromString("root/pie")).cssClassModifier("ms-3")
                    .apply { modifiers.add(HtmlElementModifier.create {
                        (this.getElementsByTagName("img")[0] as HTMLImageElement).onclick = {mouseEvent ->  navigateTo("root/pie") }
                    })}
                toggle("menuToggle")
                toggleBlock("menuToggle") {
                    if (UserInformation.hasAnyAccess()) {
                        if (UserInformation.hasAccess(MainFeature.Threats, Permission.Read)) {
                            item(i18nText(CsawareMessages::nav_overview),NavigationPath.fromString("root/pie"),"fas fa-chart-pie")
                        }
                        if (UserInformation.hasAccess(MainFeature.SocialMedia, Permission.Read)) {
                            item(i18nText(CsawareMessages::socialmedia_reader), NavigationPath.fromString("root/socialmedia"), "fas fa-share-alt")
                        }
                        if (UserInformation.hasAccess(MainFeature.Threats, Permission.Read)) {
                            item(i18nText(CsawareMessages::nav_threats), NavigationPath.fromString("root/threats"), "fas fa-exclamation-triangle")
                            item(i18nText(CsawareMessages::nav_threatsClosed), NavigationPath.fromString("root/threatsClosed"), "fas fa-check")
                        }
                    }
                    if (UserInformation.hasAccess(MainFeature.SystemDependencies, Permission.Read)) {
                        item(i18nText(CsawareMessages::system_dependencies), NavigationPath.fromString("root/systemDependencies"), "fas fa-network-wired")
                    }
                    if (UserInformation.hasAccess(MainFeature.InformationSharing, Permission.Read)) {
                        infoItem = item(i18nText(CsawareMessages::informationshare_management), NavigationPath.fromString("root/infoshare"), "fas fa-info")
                        infoItem?.modifiers?.add(HtmlElementModifier.create {
                            if (infoCount > 0) {
                                appendChild(
                                    KafffeHtml.start.sup {
                                        addClass("ms-1 badge badge-pill bg-warning")
                                        element.style.fontSize = "0.7rem"
                                        text(infoCount.toString())
                                    }.element
                                )
                            }
                        }
                        )
                    }

                    val settingsDropdownMenu =
                        dropdown(
                            i18nText(CsawareMessages::settings),
                            NavigationPath.fromString(""),
                            "fas fa-cog",
                            dropdownClasses = "bg-light"
                        )
                    settingsDropdownMenu.cssClassModifier("csaware-ms-auto-start-end-7")
                    settingsDropdownMenu.item(Model.ofGet { UserInformation.current.email }, NavigationPath.fromString("root/signout"), "fas fa-sign-out-alt")
                    settingsDropdownMenu.item(i18nText(CsawareMessages::user_preferences_edit), NavigationPath.fromString("root/userpreferences"),"fa-solid fa-user-gear")
                    if (UserInformation.hasAccess(MainFeature.UserManagement, Permission.Read)) {
                        settingsDropdownMenu.item(
                            i18nText(CsawareMessages::user_management),
                            NavigationPath.fromString("root/users"),
                            "fas fa-users"
                        )
                    }
                    if (UserInformation.hasAccess(MainFeature.SystemPreferences, Permission.Write)) {
                        settingsDropdownMenu.item(i18nText(CsawareMessages::system_preferences_edit),NavigationPath.fromString("root/systempreferences"),
                            "fa-solid fa-user-gear")
                    }
                    settingsDropdownMenu.item(i18nText(Messages::nav_about), NavigationPath.fromString("root/about"), "fas fa-question")
                }
            })
            addChild(alerts)
            addChild(container)
            // rerender with autotranslation or messages changes
            messagesModel.listeners.add(ModelChangeListener { container.rerenderRecursive() })
            autoTranslationModel.listeners.add(ModelChangeListener { container.rerenderRecursive() })
            attach()
        }
        if (UserInformation.hasAnyAccess() ) {
            if (UserInformation.hasFeature(MainFeature.Threats)) {
                rootNavigation.navigateTo(NavigationPath.fromString("root/pie"))
                rootNav.currentActivePath(NavigationPath.fromString("root/pie"))
            } else {
                rootNavigation.navigateTo(NavigationPath.fromString("root/socialmedia"))
                rootNav.currentActivePath(NavigationPath.fromString("root/socialmedia"))
            }
        } else if (UserInformation.hasAccess(MainFeature.UserManagement, Permission.Read)) {
            rootNavigation.navigateTo(NavigationPath.fromString("root/users"))
            rootNav.currentActivePath(NavigationPath.fromString("root/users"))
        } else {
            rootNavigation.navigateTo(NavigationPath.fromString("root/about"))
            rootNav.currentActivePath(NavigationPath.fromString("root/about"))
            window.alert("Contact your CS-AWARE User Administration in order to gain access")
        }
    }

    CSAwareBackend.updateListeners.add(mainOnServerUpdate)
    updateInfoCount()
}

private val mainOnServerUpdate: (UpdateEvent) -> Unit = {
    if (it.type == UpdateEvent.EntityType.InformationShare) {
        updateInfoCount()
    }
    if (it.type == UpdateEvent.EntityType.UserRole) {
        UserInformation.loadCurrent {} //Migth be another user that's been updated - but we reload current in all cases
        MessagesObject.currentLanguage = UserInformation.current.preferences.uiLanguage.shortName
        messagesModel.changed()
        rootNav.rerenderRecursive()
    }
}