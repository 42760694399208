package csaware.systempreferences

import csaware.main.CsawareServices
import csaware.messages.CsawareMessages
import csaware.messages.csawareMessageStrategy
import csaware.messages.i18nText
import csaware.utilities.DisplayLanguageDropdown
import dk.rheasoft.csaware.api.SystemPreferences
import kafffe.bootstrap.BasicColor
import kafffe.bootstrap.ColWidth
import kafffe.bootstrap.ModalSize
import kafffe.bootstrap.ResponsiveSize
import kafffe.bootstrap.form.FormDialog
import kafffe.bootstrap.modifier.BootstrapPopoverModifier
import kafffe.bootstrap.modifier.BootstrapTooltipModifier
import kafffe.core.Model
import kafffe.core.kafffenv
import kafffe.core.modifiers.CssClassModifier
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kafffe.core.modifiers.StyleModifier
import kafffe.core.property


class SystemPreferencesEdit() :
    FormDialog<SystemPreferences>(
        i18nText(CsawareMessages::system_preferences_edit),
        Model.of(SystemPreferences())
    ) {


   private fun loadData(){
       CsawareServices.systemPreferencesBackend.get() {receiveData(it)}
       CsawareServices.alerts.clearAlerts()
       // clear any tooltip,popovers
       BootstrapTooltipModifier.removeAll()
       BootstrapPopoverModifier.removeAll()
   }

    private fun receiveData(response: SystemPreferences){
        this.model.data = response
        this.rerenderRecursive()
    }

    init {
        loadData()
        cssClassModifier("hgap-3 vgap-3")
        labelStrategy = csawareMessageStrategy("system_")
        size = ModalSize.large
        modal.modifiersBody.add(StyleModifier {
            overflowY = "auto"
            maxHeight = "75vh"
        })
        modal.modifiersModal.add(StyleModifier {
            maxWidth = "90vw"
            width = "1400px"
        })
        modal.modifiersContent.add(CssClassModifier("bg-light"))

       readonly(Model.of((CsawareMessages::environment_name).name), Model.of(kafffenv.name as String))


        row{
            col(ColWidth(ResponsiveSize.md, 3)) {
                group {
                    cssClassModifier("hgap-3 vgap-3")
                    var datapresentationlanguageModel =
                    this.model.property(SystemPreferences::accountPresentationLanguage)
                    var lang = DisplayLanguageDropdown("lang", datapresentationlanguageModel)
                    decorateAndAdd(i18nText(CsawareMessages::system_preferences_accountDataPresentationLanguage), lang)

                    var uilanguageModel = this.model.property(SystemPreferences::accountUiLanguage)
                    var uilang = DisplayLanguageDropdown("uilang", uilanguageModel)
                    decorateAndAdd(i18nText(CsawareMessages::system_preferences_accountUiLanguage), uilang)
                }
            }
        }


        submit("save", onOk = {
            CsawareServices.alerts.clearAlerts()
            CsawareServices.systemPreferencesBackend.store(model.data) {
            }
            detach()
        }).color = BasicColor.primary
        cancel().color = BasicColor.secondary
    }

}