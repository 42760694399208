package csaware.socialmedia

import dk.rheasoft.csaware.api.SystemDependencyResource
import csaware.main.CsawareServices
import csaware.main.navigateTo
import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut

class KeywordsWhereLink(val keywords: Set<String>): KafffeComponent() {
    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
        div{
            val systemResources: List<SystemDependencyResource> = CsawareServices.systemDependencyService
                .findAllHavingByKeywords(keywords)
            systemResources.forEachIndexed{ index: Int, node: SystemDependencyResource ->
                a {
                    text(node.name)
                    withElement {
                        className = "link-primary"
                        style.whiteSpace = "nowrap"
                        href="#"
                        onclick = {
                            onclick(node.name)
                        }
                    }
                }
                if (index < systemResources.size - 1) {
                    text(", ")
                }
            }
        }

    private fun onclick(name: String) {
        navigateTo("root/systemDependencies/$name")
    }
}
