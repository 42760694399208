package dk.rheasoft.csaware.api.aiqueries

import dk.rheasoft.csaware.utils.JsonUtilSerialization
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString

@Serializable
data class QuestionRequest(
    val question: String,
    val language: String
) {
    fun toJsonString(): String =  JsonUtilSerialization.json.encodeToString(this)
}