package dk.rheasoft.csaware.api

import dk.rheasoft.csaware.api.access.MainFeature
import dk.rheasoft.csaware.utils.JsonUtilSerialization
import kotlinx.serialization.Serializable

@Serializable
data class UserInfo(val user: User, val mainFeatures: Set<MainFeature>) {
    fun toJson() : String = JsonUtilSerialization.json.encodeToString(serializer(), this)

    companion object {
        fun fromJson(json: String) : UserInfo =
            JsonUtilSerialization.json.decodeFromString(serializer(), json)

    }
}