package dk.rheasoft.csaware.api.access

/**
 * Values for varios operations that are needed for some access.
 */
enum class Permission {
    Read, Write, Create, Delete, Execute, None;

    companion object {
        fun findByName(name: String): Permission? = Permission.entries.find { it.name.equals(name, ignoreCase = true) }
        val all: Set<Permission> = entries.toSet()
        val readonly: Set<Permission> = setOf(Read)
        val update: Set<Permission> = all - Execute
    }

}
