package csaware.socialmedia

import csaware.main.CsawareServices
import csaware.main.UserInformation
import csaware.utilities.markdown.MarkdownRender
import dk.rheasoft.csaware.api.QueryResult
import dk.rheasoft.csaware.api.aiqueries.QuestionRequest
import dk.rheasoft.csaware.api.aiqueries.QuestionResult
import dk.rheasoft.csaware.api.socialmedia.SocialMediaAccount
import dk.rheasoft.csaware.api.socialmedia.SocialMediaEntry
import kafffe.bootstrap.BasicColor
import kafffe.bootstrap.BootstrapButton
import kafffe.bootstrap.BootstrapTable
import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut
import kafffe.core.Model

class QuestionAI(selectionModel: Model<SocialMediaEntry?>) : KafffeComponent() {
    private var question: String = ""
    private var result: QuestionResult? = null
    // this will be set by outer component when loaded
    var accountById: Map<String, SocialMediaAccount> = mapOf()

    private val askButton = BootstrapButton(Model.of("Ask AI")) {
        ask()
    }.apply {
        color = BasicColor.primary
        iconClasses = "fas fa-question ms-2 me-2"
    }

    val table: BootstrapTable<SocialMediaEntry> = addChild(SocialMediaObservationTable.create(accountById, selectionModel))

    private var active: Boolean = false

    private fun ask() {
        if (question.isEmpty()) return
        val request = QuestionRequest(question = question, language = UserInformation.current.preferences.dataPresentationLanguage.shortName)
        active = true
        rerender()
        //TODO errorhandler clear active
        CsawareServices.socialMediaBackend.aiQuestion(request) {
            result = it
            active = false
            // enhance with keywords
            val keywords: Set<String> = CsawareServices.systemDependencyService.usedKeyWords()
            for (entry in it.context) {
                val strings = listOfNotNull(
                    entry.observation.title,
                    entry.observation.text,
                    entry.translation?.title,
                    entry.translation?.text
                )
                val matches: Set<String> = keywords.filter {
                        k -> strings.find { s -> s.contains(k, ignoreCase = true)  } != null
               }.toSet()
                entry.matches += matches
            }

            table.data = it.context
            rerender()
        }
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
        div {
            textarea {
                addClass("form-input")
                withElement {
                    value = question
                    cols = 80
                    rows = 4
                    oninput = {
                        question = value
                        it.preventDefault()
                    }
                }
            }.element
            div {
                addClass("mt-1 mb-3")
                if (active) {
                    div {
                        addClass("spinner-border")
                        span {
                            addClass("visually-hidden")
                            text("Loading...")
                        }
                    }
                } else {
                    add(askButton.html)
                }
            }
            div {
                if (result != null) {
                    add(MarkdownRender(result?.answer ?: "").html)
                }
            }
            div {
                addClass("mt-2")
                add(table.html)
            }
        }
}
