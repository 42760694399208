package dk.rheasoft.csaware.api

import dk.rheasoft.csaware.utils.JsonUtilSerialization
import kotlinx.serialization.Serializable
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString

/**
 * Simple text mail, with no formatting or attachements.
 */
@Serializable
class MailSimple(var to :List<String>, var from: String, var subject: String, var body: String) {
    fun toJson() = JsonUtilSerialization.json.encodeToString(this)

    companion object {
        fun fromJson(json: String): MailSimple =
            JsonUtilSerialization.json.decodeFromString(json)

    }
}