package csaware.overview

//import io.data2viz.shape.ArcGenerator
//import io.data2viz.viz.ParentElement
//import io.data2viz.viz.TextAlignmentBaseline
//import io.data2viz.viz.TextAnchor
//import io.data2viz.viz.viz
import csaware.color.Colors
import csaware.main.UserConfiguration
import dk.rheasoft.csaware.api.ThreatOverview
import kafffe.core.KafffeComponent
import kafffe.core.Model
import kafffe.svg.GeometryHelp
import kotlinx.browser.document
import kotlinx.datetime.Clock
import org.w3c.dom.HTMLElement
import org.w3c.dom.events.Event
import org.w3c.dom.svg.SVGElement
import kotlin.math.PI
import kotlin.math.min

open class SvgGraph : KafffeComponent() {

    val svg: SVGElement = document.createElementNS("http://www.w3.org/2000/svg", "svg") as SVGElement

    override fun createHtml(): HTMLElement =
        htmlStart.div {
            withElement {
                append(svg)
            }
        }.element
}

class DartGraph(
    val config: UserConfiguration = UserConfiguration.default,
    var clickHandler: (event: Event, threatType: String?) -> Unit = { _, _ -> }
) : SvgGraph() {

    var threats = ThreatOverview(Clock.System.now(), listOf())

    val threatGroupsModel = Model.of(listOf<String>())

    init {
        redraw()
    }

    fun redraw() {
        val width = config.dartWidth
        val height = config.dartHeight

        val maxRadius: Double = min(width, height) / 2.0

        val pieStrokeWidth: Double = config.dartStrokeWidth

        val threatTypesAll = mutableSetOf<String>()
        threatTypesAll.addAll(threats.threatGroups.map { it.group })
        threatTypesAll.addAll(threatGroupsModel.data)
        val threatTypes = threatTypesAll.toList().sorted()

        val numberOfSlices = threatTypes.size
        val sliceSize: Double = PI * 2 / numberOfSlices
        val pieRadius: Double = maxRadius - 2.0 * pieStrokeWidth
        val centerRadius: Double = config.dartCenterCircleSize

        svg.setAttribute("xmlns", "http://www.w3.org/2000/svg")
        svg.setAttribute("height", config.dartHeight.toString())
        svg.setAttribute("width", config.dartWidth.toString())

        val svgUtil = SvgUtil()
        val transX = config.dartWidth / 2.0
        val transY = config.dartHeight / 2.0
        svgUtil.clearGraphics(svg)
//        svg.querySelector("g")?.remove()
//        svgUtil.translate(svg, x = config.dartWidth / 2.0, y = config.dartHeight / 2.0)
//        svg.viz {
//            transform {
//                translate(x = config.dartWidth / 2.0, y = config.dartHeight / 2.0)
//            }
//
        // Background Circle
        svgUtil.circle(svg, 0.0 + transX, 0.0 + transY, pieRadius, Colors.wheat, Colors.black, pieStrokeWidth)

        // Center Circle
        val level = threats.level()
        val center = svgUtil.circle(svg, transX, transY, centerRadius - 4.0,
            config.severityColor(level), Colors.black, pieStrokeWidth )
        center.addEventListener("click", { e -> clickHandler(e, null) })

        // if maxseverity is low draw smiley
        val mood: SmileyMood = when (level) {
            0,1 -> SmileyMood.Happy
            2,3 -> SmileyMood.Neutral
            4,5 -> SmileyMood.Sad
            else -> SmileyMood.Neutral
        }
        val smiley = svgUtil.buildSmiley(svg, 0.0 + transX,0.0 + transY, centerRadius, mood)

        // draw pie sections
        var startRad = -PI / 2
        var colorIx = 0

        val maxSeveritySum = threats.threatGroups.maxOfOrNull { it.countSum * it.severityAvg } ?: 1.0

        var id = 0
        for (threatType in threatTypes) {
            val threat = threats[threatType]
            // background arch
            var fill = Colors.wheat
            var ir = centerRadius
            var or = pieRadius
            val startAngle = startRad
            val endAngle = startAngle + sliceSize
            val slice = svgUtil.buildArc(
                svg, svgUtil.describeArc(transX, transY, ir, or, startAngle, endAngle),
                fill, Colors.black, pieStrokeWidth.toString(), "arc$id"
            )
            id++

            // fill pie slice to show severity
            fill = config.severityColor(threat.severityMax)
            val severity = threat.levelRatio(maxSeveritySum)
            ir = centerRadius
            or = (pieRadius - centerRadius) * severity + centerRadius

            val colorSlice = svgUtil.buildArc(
                svg, svgUtil.describeArc(transX, transY, ir, or, startAngle, endAngle),
                fill, Colors.black, pieStrokeWidth.toString(), "arc$id"
            )
            id++

            // outer slice to mark highest severity
            val strokeWidth = 1.0
            ir = pieRadius - config.dartSeverityWidth
            or = pieRadius
            fill = config.severityColor(threat.severityMax)
            svgUtil.buildArc(
                svg, svgUtil.describeArc(transX, transY, ir, or, startAngle, endAngle),
                fill, Colors.black, strokeWidth.toString(), "arc$id"
            )
            id++

            // text to state threat type (group)
//            val textRad = (startRad + sliceSize / 2) - (PI / 2) // the angle of the arcs start at twelwe
            val textRad = (startAngle + endAngle) / 2
            val point = GeometryHelp.polarToCartesian(textRad, 0.7 * pieRadius)
            var textX = point.first
            var textY = point.second
            textX += transX
            textY += transY
            svgUtil.text(svg, textX, textY, TextAlignmentBaseline.MIDDLE, TextAnchor.MIDDLE,
                Colors.black, threatType)

            colorSlice.addEventListener("click", { e -> clickHandler(e, threatType) })
            slice.addEventListener("click", { e -> clickHandler(e, threatType) })

            startRad += sliceSize
            colorIx++
            if (colorIx >= config.threatColerList.size) {
                colorIx = if (threatTypes.size % config.threatColerList.size < 2) {
                    2
                } else {
                    0
                }
            }
        }
    }
}
