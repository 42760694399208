package csaware.utilities

import csaware.main.UserConfiguration
import csaware.messages.csawareMessages
import kafffe.bootstrap.form.FormInput
import kafffe.core.KafffeComponent
import kafffe.core.KafffeComponentWithModel
import kafffe.core.KafffeHtmlBase
import kafffe.core.Model
import kafffe.messages.MessagesObject
import org.w3c.dom.HTMLSelectElement

/**
 * FormInput Component to select Severity based on their names
 */
class SeverityDropdown(
    val idInput: String,
    valueModel: Model<Int>
) : KafffeComponentWithModel<Int>(valueModel), FormInput {

    private val values: List<Int> = (0..5).toList()

    /**
     * Controls whether a selection is required
     */
    var required: Boolean by rerenderOnChange(false)

    /**
     * Controls whether the current value can be changed
     */
    var readOnly: Boolean by rerenderOnChange(false)

    private lateinit var htmlSelect: HTMLSelectElement

    override fun KafffeHtmlBase.kafffeHtml() =
        select {
            withElement {
                htmlSelect = this
                addClass("form-select")
                id = idInput
                value = model.data.toString()
                required = this@SeverityDropdown.required
                disabled = this@SeverityDropdown.readOnly
                onchange = {
                    style.apply {
                        color = UserConfiguration.default.severityTextColor(value.toInt())
                        backgroundColor = UserConfiguration.default.severityColor(value.toInt())
                    }
                }
            }
            withStyle {
                color = UserConfiguration.default.severityTextColor(model.data)
                backgroundColor = UserConfiguration.default.severityColor(model.data)
            }
            for (c in values) {
                option {
                    withElement {
                        value = c.toString()
                        selected = (c == model.data)
                    }
                    withStyle {
                        color = UserConfiguration.default.severityTextColor(c)
                        backgroundColor = UserConfiguration.default.severityColor(c)
                    }
                    text(csawareMessages().threatLevelName(c))
                }
            }
        }

    override val htmlId: String get() = idInput

    override var validationMessageModel: Model<String> =
        Model.ofGet { if (required) MessagesObject.get().validation_required else htmlSelect.validationMessage }

    override fun component(): KafffeComponent = this

    override fun updateValueModel() {
        model.data = htmlSelect.value.toInt()
    }

    /**
     * Chcks if the current value is valid
     */
    override fun validate(): Boolean = htmlSelect.checkValidity()
}