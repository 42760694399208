package dk.rheasoft.csaware.api

import dk.rheasoft.csaware.utils.JsonUtilSerialization
import kotlinx.serialization.Serializable
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.decodeFromJsonElement
import kotlinx.serialization.json.encodeToJsonElement
import kotlinx.serialization.json.jsonObject

/**
 * Holds data of a resource from Graphing "Wiki".
 */
@Serializable
data class SystemDependencyConfig(
    var fields: MutableList<SystemDependencyField> = mutableListOf(),
    var valueSets: MutableMap<String, MutableSet<String>> = mutableMapOf(),
    var layoutDirection: LayoutDirection = LayoutDirection.vertical,
    var rootNodeIds: MutableList<String> = mutableListOf(),
    var spacing: Int = 45,
    var shapeMap: MutableMap<String, String> = mutableMapOf()
) {
    fun getValueSet(key: String): MutableSet<String> = valueSets.getOrPut(key) { mutableSetOf() }
    fun getInfoflowValues(): List<String> = getValueSet(infoflowValueSet).toList()

    fun addValueList(name: String) {
        valueSets[name] = mutableSetOf()
    }

    fun findField(fieldId : String): SystemDependencyField? = fields.find { it.id == fieldId }
    fun findFieldsOfType(fieldType : FieldType): List<SystemDependencyField> = fields.filter { it.type == fieldType }

    
    /**
     * Remove undefined fields from resource
     */
    fun removeUndefinedFields(resource: SystemDependencyResource) {
        val singleFieldsIds = fields.filter { it.cardinality.isSingle }.map { it.id }
        resource.data.keys.forEach { if (it !in singleFieldsIds) resource.data.remove(it) }
        val multiFieldsIds = fields.filter { it.cardinality.isMany }.map { it.id }
        resource.dataLists.keys.forEach { if (it !in multiFieldsIds) resource.dataLists.remove(it) }
    }


    fun toJsonString(): String =
        JsonUtilSerialization.json.encodeToString(this)

    fun toJsonObject(): JsonObject =
        JsonUtilSerialization.json.encodeToJsonElement(this).jsonObject


    companion object {
        /** Name of value set used for Information Flows*/
        const val infoflowValueSet = "infoflow"

        val default: SystemDependencyConfig = SystemDependencyConfig().apply {
            fields = mutableListOf(
                SystemDependencyField("x_categories", "Categories", cardinality = Cardinality.ZeroToMany),
                SystemDependencyField(
                    "x_csaware_node_type",
                    "Node Type",
                    cardinality = Cardinality.ZeroOrOne,
                    valueSet = "node_type"
                ),
            )
            valueSets = mutableMapOf(
                "infoflow" to mutableSetOf("HR", "Finance"),
                "node_type" to mutableSetOf(
                    "Switch",
                    "Network component",
                    "Committee",
                    "Firewall",
                    "Database",
                    "Pump",
                    "Valve",
                    "Customer"
                ),
            )
            shapeMap = mutableMapOf(
                "Switch" to "ellipse_shape",
                "Network component" to "cloud_shape",
                "Committee" to "committee_shape",
                "Firewall" to "firewall_shape",
                "Database" to "cylinder_shape",
                "Pump" to "pump_shape",
                "Valve" to "valve_shape",
                "Customer" to "personel_shape"
            )
        }

        fun fromJson(json: JsonObject): SystemDependencyConfig =
            JsonUtilSerialization.json.decodeFromJsonElement(json)

        fun fromJson(json: String): SystemDependencyConfig =
            JsonUtilSerialization.json.decodeFromString(json)

    }
}

enum class FieldType {
    STRING,
    DEPENDENCY,
    SECRET,
    IPv4 {
        override fun validate(value: String): Boolean {
            val regex = Regex("^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\$")
            return regex.matches(value)
        }
    },
    IPv6 {
        override fun validate(value: String): Boolean {
            val regex = Regex("^(?:[A-F0-9]{1,4}:){7}[A-F0-9]{1,4}\$", RegexOption.IGNORE_CASE)
            return regex.matches(value)
        }
    },
    CPE {
        override fun validate(value: String): Boolean {
            val cpe23 = Regex("^cpe:2\\.3:[aho](?::(?:[a-zA-Z0-9!\"#\$%&'()*+,\\\\\\-_.\\/;<=>?@\\[\\]^`{|}~]|\\\\:)+){10}\$")
            val cpe22 = Regex("^[c][pP][eE]:/[AHOaho]?(:[A-Za-z0-9\\._\\-~%]*){0,6}\$", RegexOption.IGNORE_CASE)
            return cpe22.matches(value) || cpe23.matches(value)
        }
    },
    URL {
    },
    MARKDOWN;

    open fun validate(value: String): Boolean = true

    companion object {
        fun parse(value: String): FieldType = try {
            valueOf(value)
        } catch (any: Throwable) {
            STRING
        }
    }
}

enum class Cardinality {
    ZeroOrOne, One, ZeroToMany, OneToMany;

    val isSingle: Boolean get() = this in setOf(ZeroOrOne, One)
    val isMany: Boolean get() = !isSingle

    companion object {
        fun parse(value: String): Cardinality {
            return try {
                valueOf(value)
            } catch (any: Throwable) {
                One
            }
        }
    }
}

@Serializable
data class SystemDependencyField(
    var id: String,
    var label: String,
    var type: FieldType = FieldType.STRING,
    var cardinality: Cardinality = Cardinality.One,
    var valueSet: String? = null
)