package csaware.utilities

import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut
import kafffe.core.modifiers.debounceEvent

class SearchInput(val onSearch: (input: String) -> Unit) : KafffeComponent() {

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
        div {
            addClass("btn-group sd_search_bar ms-3")
            val filterInput = input {
                addClass("sd_search_input form-control")
            }.element
            filterInput.apply {
                type = "text"
                accessKey = "s"
                onfocus = {
                    it
                }
                oninput = debounceEvent(400,300) {
                    onSearch(filterInput.value)
                }
                onkeydown = {
                    when (it.key) {
                        "Escape" -> {
                            filterInput.value = ""
                            onSearch(filterInput.value)
                            filterInput.blur()
                        }

                        "Enter" -> filterInput.blur()
                    }
                    it
                }
            }
            button {
                onClick {
                    filterInput.focus()
                }
                addClass("sd_search_icon btn")
                i {
                    addClass("fas fa-search")
                }
            }
        }

}