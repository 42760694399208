package csaware.threats

import csaware.main.CsawareServices
import csaware.main.navigateTo
import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut

open class WhereLink(
    private val whereSightedRefs: Set<String>,
    private val linkClass: String = "link-primary"
): KafffeComponent() {


    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
        div{
            val namesFromIds = CsawareServices.systemDependencyService.namesFromIds(whereSightedRefs)
            namesFromIds.forEachIndexed{ index: Int, name: String ->
                a {
                    text(name)
                    withElement {
                        className = linkClass
                        style.whiteSpace = "nowrap"
                        href="#"
                        onclick = {
                            onclick(name)
                        }
                    }
                }
                if (index < namesFromIds.size - 1) {
                    text(", ")
                }
            }
        }

    open fun onclick(name: String) {
        navigateTo("root/systemDependencies/$name")
    }
}