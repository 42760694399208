package csaware.main

import csaware.messages.csawareMessages
import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtmlBase
import kafffe.core.kafffenv
import kotlinx.browser.document

class Signout : KafffeComponent() {
    private fun removeCookies() {
        val res = document.cookie
        res.split(";").forEach {
            document.cookie = "${it.trim()}= ; expires = Thu, 01 Jan 1970 00:00:00 UTC"
        }
        CsawareServices.backend.getTxt("/logout") { console.log("logout: $it")}
    }

    override fun KafffeHtmlBase.kafffeHtml() =
            div {
                removeCookies()
                addClass("card mt-2")
                h3 {
                    addClass("card-header csaware-field text-light text-center")
                    text(csawareMessages().sign_out)
                }
                div {
                    addClass("card-body")
                    div {
                        addClass("text-center")
                        text(csawareMessages().sign_out_disclaimer)
                    }
                    div {
                        addClass("text-center mt-3 mb-3")
                        a {
                            withElement {
                                href = kafffenv.myaccount_url.unsafeCast<String>()
                            }
                            addClass("btn btn-info btn-lg")
                            text(csawareMessages().sign_out_link)
                        }
                    }
                }
            }

}