package csaware.systemdepend

import csaware.comm.CSAwareBackend
import csaware.main.CsawareServices
import csaware.main.CsawareServices.systemDependencyService
import csaware.main.CsawareServices.threatsBackend
import csaware.main.UserInformation
import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import csaware.systemdepend.graph.SystemGraph
import csaware.systemdepend.nodedetails.NodeDetails
import csaware.utilities.ActionBar
import csaware.utilities.StringSelector
import csaware.utilities.componentTitle
import dk.rheasoft.csaware.api.*
import dk.rheasoft.csaware.api.access.MainFeature
import dk.rheasoft.csaware.api.access.Permission
import dk.rheasoft.csaware.api.access.UserRole
import kafffe.bootstrap.ColWidth
import kafffe.bootstrap.ResponsiveSize
import kafffe.bootstrap.bootstrapCol
import kafffe.bootstrap.bootstrapRow
import kafffe.core.*
import kotlinx.datetime.Clock

class SystemDependencies(selectResource: SystemDependencyResource? = null) : KafffeComponent() {

    @Suppress("unused")
    private val titleDetailsLabel = addChild(Label(Model.of("")))

    private var resources: Model<List<SystemDependencyResource>> = systemDependencyService.model
    private var threatSummary: Model<SystemDependencyResourcesThreatOverview> = Model.of(SystemDependencyResourcesThreatOverview.empty())

    private val graphLabel = addChild(Label(i18nText(CsawareMessages::system_dependencies)))
    val graph = addChild(SystemGraph(resources, systemDependencyService.config, threatSummary, systemDependencyService.keywordCount, selectResource))

    private val titleLabel = addChild(Label(graph.selectionModel.property(SystemDependencyResource::name)))
    val details = addChild(NodeDetails(graph.selectionModel, graph.hightlightModel))

    private val searchBox = addChild(SystemSearch(graph.selectionModel, resources))
    private val infoFlowBox = addChild(
        StringSelector(
            graph.hightlightModel,
            Model.of(systemDependencyService.config.data.getInfoflowValues().sorted()),
            SystemGraph.SELECTION_HIGHLIGHT
        )
    )
    private val dependencySelector = addChild(
        DependencyDropdown(graph.dependencyFieldModel)
    )

    init {
        loadThreats()
        if (selectResource != null) {
            graph.panToSelection()
        }
    }

    private val onResourcesUpdate = ModelChangeListener {
        loadThreats()
    }

    private val onServerUpdate: (UpdateEvent) -> Unit = { event: UpdateEvent ->
        CsawareServices.alerts.clearAlerts()
        when (event.type) {
            UpdateEvent.EntityType.ThreatObservation -> loadThreats()
            UpdateEvent.EntityType.SystemDependencies -> {
                systemDependencyService.refresh()
            }

            UpdateEvent.EntityType.SystemDependencyNode -> {
                systemDependencyService.refresh()
            }

            UpdateEvent.EntityType.SystemDependencyEdge -> {
                systemDependencyService.refresh()
            }

            UpdateEvent.EntityType.SystemDependencyConfig -> {
                systemDependencyService.refreshConfig()
            }

            else -> {}
        }
    }


    private fun loadThreats() {
        threatsBackend.getSystemResourceOverview(Clock.System.now()) {
            threatSummary.data = it
        }
    }

    override fun attach() {
        super.attach()
        CSAwareBackend.updateListeners.add(onServerUpdate)
        resources.listeners.add(onResourcesUpdate)
    }

    override fun detach() {
        CSAwareBackend.updateListeners.remove(onServerUpdate)
        resources.listeners.remove(onResourcesUpdate)
        super.detach()
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {
        return bootstrapRow {
            bootstrapCol(ColWidth(ResponsiveSize.sm, 8)) {
                div {
                    componentTitle {
                        add(graphLabel.html)
                        add(searchBox.html)
                        add(infoFlowBox.html)
                        add(dependencySelector.html)
                        if (UserInformation.hasAccess(MainFeature.SystemDependencies, Permission.Write)) {
                            add(actionBar.html)
                        }
                    }
                    add(graph.html)
                }
            }
            bootstrapCol(ColWidth(ResponsiveSize.sm, 4)) {
                componentTitle {
                    add(titleLabel.html)
                }
                add(details.html)
            }
        }
    }

    private val actionBar = addChild(ActionBar {
        for (func in UiFunctions.resourceFunctions) {
            item(Model.of(func.label), func.iconCls) {
                accessRequirement = func.feature to func.permission
                action = { func.doIt(graph.selectionModel.data, graph.selectionModel) }
            }
        }
        dropdownItem(Model.of(""), "fas fa-cog") {
            for (func in UiFunctions.globalFunctions) {
                item(Model.of(func.label), func.iconCls) {
                    accessRequirement = func.feature to func.permission
                    action = { func.doIt(graph.selectionModel.data, graph.selectionModel) }
                }
            }
        }
    })

}