package dk.rheasoft.csaware.utils

import kotlinx.datetime.Instant
import kotlinx.serialization.json.*

/**
 * Extension functions to help get properties of a JsonObject
 */
object JsonAccessUtils {
    fun JsonObject?.optionalString(key: String, defaultValue: String = ""): String =
        this?.get(key)?.jsonPrimitive?.content ?: defaultValue


    fun JsonElement.getElement(path: List<String>): JsonElement? {
        var cur: JsonElement? = this
        for (elm in path) {
            cur = (cur as? JsonObject)?.get(elm)
            if (cur == null)
                break
        }
        return cur
    }

    fun JsonElement.getElement(vararg path: String): JsonElement? = getElement(path.toList())

    fun JsonElement.getString(path: List<String>): String? = getElement(path)?.jsonPrimitive?.content
    fun JsonElement.getString(vararg path: String): String? = getString(path.toList())

    fun JsonElement.getStrings(path: List<String>): List<String>? =
        getElement(path)?.jsonArray?.map { it.jsonPrimitive.content }

    fun JsonElement.getStrings(vararg path: String): List<String>? = getStrings(path.toList())

    fun JsonElement.getDouble(path: List<String>): Double? = getElement(path)?.jsonPrimitive?.doubleOrNull
    fun JsonElement.getDouble(vararg path: String): Double? = getDouble(path.toList())

    fun JsonElement.getInt(path: List<String>): Int? = getElement(path)?.jsonPrimitive?.intOrNull
    fun JsonElement.getInt(vararg path: String): Int? = getInt(path.toList())

    fun JsonElement.getLong(path: List<String>): Long? = getElement(path)?.jsonPrimitive?.longOrNull
    fun JsonElement.getLong(vararg path: String): Long? = getLong(path.toList())

    fun JsonElement.getBoolean(path: List<String>): Boolean? = getElement(path)?.jsonPrimitive?.booleanOrNull
    fun JsonElement.getBoolean(vararg path: String): Boolean? = getBoolean(path.toList())

    fun JsonElement.getTimestamp(path: List<String>): Instant? = getString(path)?.let { Instant.parse(it) }
    fun JsonElement.getTimestamp(vararg path: String): Instant? = getTimestamp(path.toList())

    /** convert to simple MutbaleMap and types */
    fun JsonObject.toKotlinMutableMap(): MutableMap<String, Any> {
        return this.map { (key: String, value: JsonElement) ->
            val js = value.toMutableKotlin()
            if (js != null) Pair(key, js) else Pair("", "")
        }
            .filter { it.first.isNotEmpty() } //
            .associateBy({ it.first }, { it.second }).toMutableMap()
    }

    /**
     *  Convert JsonElement to "native" Kotlin.
     *  *  JsonObject -> Map
     *  * JsonArray -> List
     *  * ...
     */
    fun JsonElement?.toMutableKotlin(): Any? =
        when (this) {
            is JsonObject -> toKotlinMutableMap()
            is JsonPrimitive -> {
                val primi = jsonPrimitive
                when {
                    primi.isString -> primi.content
                    primi.content in setOf("true", "false") -> primi.boolean
                    else -> try {
                        if (primi.content.contains(".")) primi.double else primi.long
                    } catch (e: NumberFormatException) {
                        primi.content
                    }
                }
            }

            is JsonArray -> this.map { it.toMutableKotlin() }.toMutableList()
            is JsonNull -> null
            else -> this
        }

    /** convert to simple Map and types */
    fun JsonObject.toKotlinMap(): Map<String, Any> = this.toKotlinMutableMap().toMap()

}
