package dk.rheasoft.csaware.utils

import dk.rheasoft.csaware.api.ObservedDataObject
import dk.rheasoft.csaware.utils.JsonAccessUtils.toKotlinMap
import dk.rheasoft.csaware.utils.JsonBuilderUtils.fromMap
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.descriptors.buildClassSerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.jsonObject

object ObservedDataObjectSerializer : KSerializer<ObservedDataObject> {
    override val descriptor: SerialDescriptor = buildClassSerialDescriptor("ObservedDataObject")

    override fun deserialize(decoder: Decoder): ObservedDataObject {
        val json: JsonObject = (decoder as JsonDecoder).decodeJsonElement().jsonObject
        val fields: Map<String, Any> = json.toKotlinMap()
        return ObservedDataObject(fields)
    }

    override fun serialize(encoder: Encoder, value: ObservedDataObject) {
        val jsonObj: JsonObject = fromMap(value.fields)
        (encoder as JsonEncoder).encodeJsonElement(jsonObj)
    }
}