package csaware.overview

import kafffe.bootstrap.BootstrapButton
import kafffe.core.modifiers.CssClassModifier
import kafffe.core.DivContainer
import kafffe.core.Model

class PlayButtons(val onAction: (action: PlayAction) -> Unit) : DivContainer() {
    enum class PlayAction {
        Play, Stop, StepForward, StepBackward, FastForward, FastBackward
    }

    private fun addButton(iconClassed: String, action: PlayAction): BootstrapButton {
        val btn = BootstrapButton(Model.of("")) { onAction(action) }
        btn.iconClasses = iconClassed
        addChild(btn)
        return btn
    }

    private val fastBackwardBtn = addButton("fas fa-fast-backward", PlayAction.FastBackward)
    private val stepBackwardBtn = addButton("fas fa-step-backward", PlayAction.StepBackward)
    private val stopBtn = addButton("fas fa-pause", PlayAction.Stop)
    private val playBtn = addButton("fas fa-play", PlayAction.Play)
    private val stepForwardBtn = addButton("fas fa-step-forward", PlayAction.StepForward)
    private val fastForwardBtn = addButton("fas fa-fast-forward", PlayAction.FastForward)

    var playing: Boolean = false
        set(value) {
            field = value
            fastBackwardBtn.disabled = value
            stepBackwardBtn.disabled = value
            stopBtn.disabled = !value
            playBtn.disabled = value
            stepForwardBtn.disabled = value
            fastForwardBtn.disabled = value
        }

    init {
        modifiers.add(CssClassModifier("hgap-3 text-center"))
        playing = false
    }
}
