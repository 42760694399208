package dk.rheasoft.csaware.api.aiqueries

import dk.rheasoft.csaware.api.socialmedia.SocialMediaEntry
import dk.rheasoft.csaware.utils.JsonUtilSerialization
import kotlinx.serialization.Serializable
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString

@Serializable
data class QuestionResult(
    /**
     * The question asked
     */
    val question: String,

    /**
     * These are the SocialMedia posts used as context to AI in order to try to answer the question
     */
    val context: List<SocialMediaEntry>,
    /**
     * Answer is expected to me Markdown an in the same language as the asked question, but can be other,
     * because the AI is not always correct
     */
    val answer: String
) {
    fun toJsonString(): String =  JsonUtilSerialization.json.encodeToString(this)

    companion object {
        fun fromJson(jsonString: String): QuestionResult =
            JsonUtilSerialization.json.decodeFromString(jsonString)
    }
}