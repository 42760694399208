package csaware.systemdepend.nodedetails

import dk.rheasoft.csaware.api.SystemDependencyResource
import kafffe.core.KafffeComponentWithModel
import kafffe.core.KafffeHtmlBase
import kafffe.core.Model


class NodeDetails(
    selectionModel: Model<SystemDependencyResource>,
    hightlightModel: Model<String>
) : KafffeComponentWithModel<SystemDependencyResource>(selectionModel) {
    init {
        setModelChangedRerenderRecursive()
    }

    private val details = addChild(SystemDependencyResourceDetails(model, hightlightModel))
    private val threats = addChild(Threats(model))
    private val socialMedia = addChild(SocialMedia(model))


    override fun KafffeHtmlBase.kafffeHtml() =
            div {
                withElement {
                    style.apply {
                        width = "100%"
                        maxHeight = "85vh"
                        overflowY = "auto"
                    }

                    add(threats.html)

                    add(socialMedia.html)
                    add(details.html)

                }
            }


}