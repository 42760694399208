package csaware.threats

import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import csaware.utilities.markdown.MarkdownRender
import kafffe.bootstrap.modifier.BootstrapPopoverModifier
import kafffe.core.KafffeHtml

object Popovers {

    fun text(textContent: () -> String): BootstrapPopoverModifier {
        return BootstrapPopoverModifier().apply {
            with(options) {
                content = textContent
                delay = 0
                template = infoTemplate()
                container = "body"
                trigger = "click hover focus"
                boundary = "viewport"
            }
        }
    }

    fun markdown(markdownText: () -> String): BootstrapPopoverModifier {
        return BootstrapPopoverModifier().apply {
            with(options) {
                title = i18nText(CsawareMessages::description).data
                html = true
                content = MarkdownRender(markdownText()).html
                delay = 0
                template = infoTemplate()
                container = "body"
                trigger = "focus"
                boundary = "viewport"
            }
        }
    }

    fun description(desc: () -> String): BootstrapPopoverModifier {
        return BootstrapPopoverModifier().apply {
           with(options) {
                title = i18nText(CsawareMessages::description).data
                content = "<strike>$desc</strike>"
                delay = 0
                template = infoTemplate()
                container = "body"
                trigger = "click hover focus"
                boundary = "viewport"
            }
        }
    }

    private fun infoTemplate(): String {
        return KafffeHtml.start.div {
            addClass("popover shadow")
            div { addClass("arrow") }
            h3 { addClass("popover-header bg-info text-dark") }
            div {
                addClass("popover-body bg-light text-dark")
                element.style.apply {
                    maxHeight = "50vh"
                    width = "30em"
                    overflowX = "hidden"
                    overflowY = "hidden"
                }
            }
        }.element.outerHTML
    }

    fun showInfo (titleText: String, desc: () -> String): BootstrapPopoverModifier     {
        return BootstrapPopoverModifier().apply {
            with(options) {
                this.placement = "bottom"
                title = titleText
                content = desc
                delay = 0
                template = infoTemplate()
                container = "body"
                trigger = "click hover focus"
                boundary = "viewport"
            }
        }
    }

    fun removeAll() = BootstrapPopoverModifier.removeAll()
}
