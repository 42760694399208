package csaware.systemdepend

import csaware.main.CsawareServices
import dk.rheasoft.csaware.api.FieldType
import kafffe.core.KafffeComponentWithModel
import kafffe.core.KafffeHtmlBase
import kafffe.core.ModelNullable
import org.w3c.dom.HTMLSelectElement

class DependencyDropdown(model: ModelNullable<String>) : KafffeComponentWithModel<String?>(model) {

    init {
        setModelChangedRerenderRecursive()
    }

    private lateinit var htmlSelect: HTMLSelectElement

    private fun fieldIds(): List<String> =
            systemDependencyConfig()
                .findFieldsOfType(FieldType.DEPENDENCY)
                .map { it.id }

    private fun systemDependencyConfig() = CsawareServices.systemDependencyService.config.data

    override fun KafffeHtmlBase.kafffeHtml() =
        div {
            addClass("btn-group sd_search_bar ms-2")
            select {
                withElement {
                    htmlSelect = this
                    addClass("form-select")
                    value = model.data ?: ""
                    onchange = {
                        model.data = if (value == "---") null else value
                        it
                    }
                }
                option {
                    withElement {
                        value = "---"
                        selected = (model.data == null)
                    }
                    text(display(""))
                }
                for (fieldId in fieldIds()) {
                    option {
                        withElement {
                            value = fieldId
                            selected = (fieldId == model.data)
                        }
                        text(display(fieldId))
                    }
                }
            }
            i {
                addClass("sd_search_icon fas fa-route btn")
            }
        }

    private fun display(fieldId: String): String =
        systemDependencyConfig().findField(fieldId)?.label ?: fieldId

}