package dk.rheasoft.csaware.api

import kotlinx.serialization.Serializable

/**
 * Threat Taxonomy
 */
@Serializable
data class ThreatGroup(val name: String, val description: String)

/**
 * Transport format to load ThreatGroup taxonomy with corresponding translations
 */
@Serializable
data class ThreatGroupWithTranslations(val threatGroup: ThreatGroup, val languageToTranslation: Map<String, ThreatGroup>)

