package dk.rheasoft.csaware.utils

import dk.rheasoft.csaware.api.SystemDependencyResource
import dk.rheasoft.csaware.utils.JsonAccessUtils.getString
import dk.rheasoft.csaware.utils.JsonAccessUtils.getStrings
import dk.rheasoft.csaware.utils.JsonBuilderUtils.putStrings
import kotlinx.datetime.Instant
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.descriptors.buildClassSerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.*

object SystemDependencyResourceSerializer : KSerializer<SystemDependencyResource> {
    override val descriptor: SerialDescriptor = buildClassSerialDescriptor("SystemDependencyResource")

    override fun deserialize(decoder: Decoder): SystemDependencyResource {
        val json: JsonObject = (decoder as JsonDecoder).decodeJsonElement().jsonObject
        val data: MutableMap<String, String> = mutableMapOf()
        val dataLists: MutableMap<String, MutableList<String>> = mutableMapOf()
        json.forEach { (propName: String, jsonProp: JsonElement) ->
            if (propName !in SystemDependencyResource.definedPropertyNames) {
                if (jsonProp is JsonArray) {
                    dataLists[propName] = jsonProp.jsonArray.map { it.jsonPrimitive.content }.toMutableList()
                } else {
                    data[propName] = jsonProp.jsonPrimitive.content
                }
            }
        }
        return SystemDependencyResource(
            id = json.getString("id") ?: "",
            name = json.getString("name") ?: "",
            created = Instant.parse(json.getString("created")!!),
            modified = Instant.parse(json.getString("modified")!!),
            description = json.getString("description") ?: "",
            source = (json.getStrings("source") ?: listOf()).toMutableList(),
            x_infoflow = (json.getStrings("x_infoflow") ?: listOf()).toMutableList(),
            data = data,
            dataLists = dataLists
        )
    }

    override fun serialize(encoder: Encoder, value: SystemDependencyResource) {
        val jsonObj = buildJsonObject {
            put("type", "node")
            put("id", value.id)
            put("name", value.name)
            put("created", value.created.toString())
            put("modified", value.modified.toString())
            put("description", value.description)
            putStrings("source", value.source)
            putStrings("x_infoflow", value.x_infoflow)
            value.data.forEach { (key: String, value: String) ->
                put(key, value)
            }
            value.dataLists.forEach { (key: String, value: List<String>) ->
                putStrings(key, value)
            }
        }
        (encoder as JsonEncoder).encodeJsonElement(jsonObj)
    }
}