package dk.rheasoft.csaware.api.aiqueries
import dk.rheasoft.csaware.utils.JsonUtilSerialization
import kotlinx.serialization.Serializable
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

@Serializable
data class DailySummaryResult(
    val summaries: List<DailySummary>
) {
    fun toJsonString() = Json{ prettyPrint = true}.encodeToString(this)

    companion object {
        fun fromJson(jsonString: String): DailySummaryResult =
            JsonUtilSerialization.json.decodeFromString(jsonString)
    }
}

