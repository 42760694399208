package csaware.main

import csaware.color.Colors

data class UserConfiguration(
    var dartWidth: Int = 500,
    var dartHeight: Int = 500,
    var dartStrokeWidth: Double = 1.0,
    var dartCenterCircleSize: Double = 90.0,
    var dartSeverityWidth: Double = 12.0,

    var severityColorMap: List<String> = listOf(Colors.green, Colors.limegreen, Colors.royalblue, Colors.yellow, Colors.darkorange, Colors.red),
    var severityTextColorMap: List<String> = listOf(Colors.black, Colors.black, Colors.white, Colors.black, Colors.white, Colors.yellow),
    var threatColerList: List<String> = listOf("#3e9f8b", "#0076ff", "#66b7aa", "#7de5fb", "#8ccec7", "#b3e7e4", "#00c5ff",
            "#00a1ff", "#7180A2", "#CCA75F", "#CC905F", "#A07929","#D7D079","#FFEFBD","#AFA63F"),

    var topNumberOfThreats: Int = 10,
    var nofHistoryDays: Int = 21,
    var playDelayMs: Int = 600
) {

    fun severityColor(severity: Int) = severityColorMap[severity % severityColorMap.size]
    fun severityTextColor(severity: Int) = severityTextColorMap[severity % severityTextColorMap.size]

    companion object {
        val default = UserConfiguration()
    }

}