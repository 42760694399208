package csaware.utilities.markdown

import kafffe.core.KafffeComponentWithModel
import kafffe.core.KafffeHtmlBase
import kafffe.core.Model

/**
 * Render markdown using remarkable (needs to have include of javascript.
 */
class MarkdownRender(textModel: Model<String>) : KafffeComponentWithModel<String>(textModel) {
    constructor(text: String) : this(Model.of(text))

    var text: String by delegateToModel()

    override fun KafffeHtmlBase.kafffeHtml() =
            div {
                addClass("csa-markdown")
                withElement {
                    val md = js("new Remarkable('full', {linkTarget: '_blank'})")
                    innerHTML = md.render(text).unsafeCast<String>()
                }
            }

}