package dk.rheasoft.csaware.api

import dk.rheasoft.csaware.utils.JsonUtilSerialization
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString

@Serializable
data class SystemDependencyResourceThreatOverview(
    val resourceId: String,
    val severityMax: Int,
    val severityAvg: Double,
    val docCount: Int,
    val countSum: Int
)

@Serializable
data class SystemDependencyResourcesThreatOverview(
    val activeAt: Instant,
    val resources: List<SystemDependencyResourceThreatOverview>
) {
    fun toJsonString(): String = JsonUtilSerialization.json.encodeToString(this)

    operator fun get(resourceId: String): SystemDependencyResourceThreatOverview =
        resources.find{ it.resourceId == resourceId} ?: SystemDependencyResourceThreatOverview(resourceId, 0, 0.0, 0, 0)

    companion object {
        fun fromJson(jsonString: String): SystemDependencyResourcesThreatOverview =
            JsonUtilSerialization.json.decodeFromString(jsonString)

        fun empty(): SystemDependencyResourcesThreatOverview = SystemDependencyResourcesThreatOverview(Clock.System.now(), emptyList())
    }
}