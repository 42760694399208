package csaware.comm

import dk.rheasoft.csaware.api.QueryResult
import dk.rheasoft.csaware.api.User
import dk.rheasoft.csaware.api.UserInfo
import dk.rheasoft.csaware.api.access.MainFeature
import dk.rheasoft.csaware.api.access.Permission
import dk.rheasoft.csaware.api.access.UserRole
import dk.rheasoft.csaware.utils.JsonUtilSerialization
import kotlinx.serialization.decodeFromString

open class UserBackend(exceptionHandler: (errorCode: Int, errorText: String) -> Unit) : CSAwareBackend(exceptionHandler) {


    fun getCurrentUser(receiver: (UserInfo) -> Unit) {
        getTxt("/user/info") {
            val x: UserInfo = UserInfo.fromJson(it)
            receiver(x)
        }
    }

    fun getUsers(offset: Int, pagesize: Int, receiver: (QueryResult<User>) -> Unit, filter: String) {
        getTxt("/user/users?offset=$offset&limit=$pagesize&filter=$filter") {
            val x: QueryResult<User> = User.fromQueryResultJson(it)
            receiver(x)
        }
    }

    fun getUsersByRole(offset: Int, pagesize: Int, role: UserRole, receiver: (QueryResult<User>) -> Unit) {
        getTxt("/user/usersByRole?offset=$offset&limit=$pagesize&role=$role") {
            val x: QueryResult<User> = User.fromQueryResultJson(it)
            receiver(x)
        }
    }

    fun getUsersByAccess(offset: Int, pagesize: Int, feature: MainFeature, permission: Permission,  receiver: (QueryResult<User>) -> Unit) {
        getTxt("/user/usersByAccess?offset=$offset&limit=$pagesize&feature=$feature&permission=$permission") {
            val x: QueryResult<User> = User.fromQueryResultJson(it)
            receiver(x)
        }
    }

    fun updateUser(user: User, receiver: (User) -> Unit) {
        sendJsonTxt("/user/update", "POST", user.toJsonString()) {
            val x: User = User.fromJson(it)
            receiver(x)
        }
    }

    fun deleteUser(user: User, receiver: () -> Unit) {
        sendJsonTxt("/user/delete/${user.email}", "DELETE", user.toJsonString()) {
            receiver()
        }
    }

    fun importUsers(jsonData: String, receiver: (List<String>) -> Unit) {
        sendJsonTxt("/user/import", "POST", jsonData) {
            val x: List<String> = JsonUtilSerialization.json.decodeFromString(it)
            receiver(x)
        }
    }

}

