package csaware.systemdepend.graph.shapes

import dk.rheasoft.csaware.api.systemdependencies.NodeShape
import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtml
import kafffe.core.KafffeHtmlBase
import kafffe.core.Model
import kafffe.core.modifiers.debounceEvent
import kotlinx.browser.window
import kotlinx.dom.addClass
import kotlinx.dom.removeClass
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.asList

/**
 * "dropdowm" selection of shapes with search after shape name
 */
class ShapeSelector(
    private val selectedModel: Model<NodeShape>,
    private val choicesModel: Model<List<NodeShape>> = Model.ofGet {
        NodeShapeRegistry.shapes.sortedBy { it.styleName }.toList()
    }
) : KafffeComponent() {
    private var inputElement: HTMLInputElement? = null
    private var dropdownElement: HTMLDivElement? = null

    public fun focus() {
        inputElement?.focus()
        inputElement?.select()
    }

    override fun KafffeHtmlBase.kafffeHtml() =
        div {
            addClass("btn-group ms-3")
            inputElement = input {
                addClass("bg-secondary text-light")
                withStyle {
                    width = "1px"
                    border = "0px"
                }
                withElement {
                    type = "text"
                    value = selectedModel.data.styleName
                    accessKey = "s"
                    onfocus = {
                        style.width = "150px"
                        it
                    }
                    onblur = {
                        // delay display none to after click in selection, otherwise the click seems to be lost
                        window.setTimeout({
                            dropdownElement?.style?.display = "none"
                            rerender()
                        }, 300)
                        style.width = "1px"
                        it
                    }
                    oninput = debounceEvent(200, 200) {
                        renderMatches()
                    }
                    onkeydown = {
                        when (it.key) {
                            "Escape" -> inputElement?.blur()
                            "Enter" -> inputElement?.blur()
                            "ArrowRight" -> selectNext()
                            "ArrowDown" -> selectNext()
                            "ArrowLeft" -> selectPrev()
                            "ArrowUp" -> selectPrev()
                        }
                        it
                    }
                }
            }.element
            div {
                addClass("bg-secondary")
                onClick {
                    inputElement?.focus()
                }
                element.appendChild(
                    NodeShapeRegistry.shapeThumb(selectedModel.data).apply {
                        style.width = "130px"
                        style.height = "100px"
                    }
                )
            }
            dropdownElement = div {
                addClass("sd_dropdown bg-light")
                withStyle {
                    width = "30vw"
                }
            }.element
        }

    private val maxMatches: Int = 100
    private var selectIndex: Int = -1

    private fun selectNext() {
        if (maxMatches >= selectIndex + 1) {
            ++selectIndex
        }
        select(selectIndex)
    }

    private fun selectPrev() {
        if (selectIndex > 0) {
            --selectIndex
        }
        select(selectIndex)
    }

    private fun select(index: Int) {
        val matches = matches()
        if (index in matches.indices) {
            selectedModel.data = matches[index]
            // set and remove "sd_selected" class
            dropdownElement?.children?.asList()?.forEachIndexed { i, element ->
                if (i == index) {
                    element.addClass("sd_selected")
                } else {
                    element.removeClass("sd_selected")
                }
            }
        }
    }

    private fun matches(): List<NodeShape> {
        val txt = inputElement?.value ?: ""
        return if (txt.length >= 1) {
            choicesModel.data.filter { shape -> shape.mxShapeName.contains(txt, ignoreCase = true) } //
                .union(choicesModel.data.filter { shape -> shape.styleName.contains(txt, ignoreCase = true) }) //
                .take(maxMatches).toList()
        } else {
            choicesModel.data.take(maxMatches).toList()
        }
    }


    private fun renderMatches() {
        selectIndex = -1
        dropdownElement?.let { dropdownElement ->
            dropdownElement.innerHTML = ""
            val htmlConsumer = KafffeHtml(dropdownElement)
            val matches = matches()

            for (shape in matches) {
                val item =
                    htmlConsumer.div {
                        addClass("sd_dropdown_item bg-secondary m-1")
                        withStyle {
                            display = "inline-block"
                        }
                        element.appendChild(
                            NodeShapeRegistry.shapeThumb(shape).apply {
                                style.width = "130px"
                                style.height = "100px"
                            }
                        )
                    }
                item.element.onclick = {
                    selectedModel.data = shape
                    inputElement?.blur()
                    it
                }
            }
            dropdownElement.style.display = if (matches.isEmpty()) "none" else "block"
        }
    }

}