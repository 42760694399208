package dk.rheasoft.csaware.api.socialmedia

import dk.rheasoft.csaware.api.QueryResult
import dk.rheasoft.csaware.utils.JsonUtilSerialization
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlin.time.Duration.Companion.days

@kotlinx.serialization.Serializable
data class SocialMediaAccount(
    var id: String = "",
    var name: String = "",
    var displayName: String = "",
    var description: String = "",
    var mediaType: SocialMediaType= SocialMediaType.Other,
    var excluded:Boolean = false,
    // "runtime data" maybe split main data adn runtime
    var collectorState: CollectorState = CollectorState.local,

) {
    @Suppress("EnumEntryName")
    enum class CollectorState{
        local,
        global,
    }

    fun isLocallyCollected() = collectorState == CollectorState.local
    fun isTwitter() = mediaType == SocialMediaType.Twitter
    fun isReddit() = mediaType == SocialMediaType.Reddit

    fun toJson() = JsonUtilSerialization.json.encodeToString(serializer(),this)

    companion object {
        fun QueryResult<SocialMediaAccount>.toJsonString() : String =
            JsonUtilSerialization.json.encodeToString(QueryResult.serializer(serializer()),this)

        fun fromQueryResultJson(json: String) : QueryResult<SocialMediaAccount> =
            JsonUtilSerialization.json.decodeFromString(QueryResult.serializer(serializer()),json)

        fun fromJson(json: String) : SocialMediaAccount =
            JsonUtilSerialization.json.decodeFromString(serializer(), json)

    }

}