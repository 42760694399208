package csaware.systemdepend.config

import csaware.systemdepend.graph.shapes.NodeShapeRegistry
import csaware.systemdepend.graph.shapes.ShapeSelector
import dk.rheasoft.csaware.api.SystemDependencyConfig
import kafffe.bootstrap.form.FormValueProvider
import kafffe.core.KafffeComponentWithModel
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut
import kafffe.core.Model

class TypeToShapeMapEditor(model: Model<Map<String, String>>, modelConfig: Model<SystemDependencyConfig>) :
    KafffeComponentWithModel<Map<String, String>>(model), FormValueProvider {

    private val currentMap: MutableMap<String, String> = model.data.toMutableMap()
    private val localNodeTypes: List<String> = modelConfig.data.valueSets["node_type"]?.sorted() ?: listOf()
    private val defaultShape = "rectangle_shape"

    /**
     * The shape selectors to be used for each nodetype
     */
    private val selectorMap: Map<String, ShapeSelector> = localNodeTypes.associateWith {
        ShapeSelector(
            Model.ofGetSet(
                getter = { NodeShapeRegistry.fromStyleName(currentMap[it] ?: "") },
                setter = { shape -> currentMap[it] = shape.styleName }
            )
        )
    }

    override fun updateValueModel() {
        model.data = currentMap.toMap()
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {
        val idWidth = "24rem"
        return div {
            addClass("form-group")
            div {
                div {
                    addClass("list-group")
                    localNodeTypes.forEach { nodeType: String ->
                        div {
                            addClass("form-inline")
                            div {
                                addClass("form-control")
                                withElement {
                                    style.width = idWidth
                                    style.maxWidth = idWidth
                                    setAttribute("readOnly", "true")
                                }
                                text(nodeType)
                            }
                            add(selectorMap[nodeType]!!.html)
                            element.onclick = {
                                selectorMap[nodeType]?.focus()
                            }

                        }
                    }
                }
            }
        }
    }

    init {
        localNodeTypes.forEach {
            if (it !in currentMap) {
                currentMap[it] = defaultShape
            }
        }
    }
}