package dk.rheasoft.csaware.api

import dk.rheasoft.csaware.utils.JsonUtilSerialization
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.decodeFromJsonElement

enum class ShareState {
    PermissionNeeded,
    SharingAccepted,
    SharingDenied,
    Unknown;

    companion object {
        fun parse(value: String): ShareState = try { valueOf(value) } catch (any: Throwable) { Unknown }
    }
}

@Serializable
data class ShareStateHistory(
        var initiator: String = "who initiated",
        var state: ShareState,
        var time: Instant,
        var comment: String
)

@Serializable
data class InformationShareData (
        var id: String,
        var name: String,
        var editable: Boolean = true,
        var deletable: Boolean = true,
        var isChanged: Boolean = false,
        var isDeleted: Boolean = false,
        var information: String
)

@Serializable
data class InformationShare(
    var type: String,
    var id: String,
    var bundleID: String,
    var state: ShareState,
    var summary: String = "",
    var created: Instant = Clock.System.now(),
    var modified: Instant = Clock.System.now(),
    var data: List<InformationShareData> = listOf()
//        val stateHistory: MutableList<ShareStateHistory> = mutableListOf()
) {
    init {
        type = "InformationShare"
//        if (stateHistory.isEmpty()) {
//            stateHistory.add(ShareStateHistory("CA-Aware", ShareState.PermissionNeeded, modified, "initial"))
//        }
    }

    fun toJsonString(): String =
        JsonUtilSerialization.json.encodeToString(this)

    companion object {
        fun QueryResult<InformationShare>.toJson() : String =
            JsonUtilSerialization.json.encodeToString(QueryResult.serializer(serializer()),this)

        fun fromQueryResultJson(json: String) : QueryResult<InformationShare> =
            JsonUtilSerialization.json.decodeFromString(QueryResult.serializer(serializer()),json)


        fun fromJson(json: JsonObject) : InformationShare =
            JsonUtilSerialization.json.decodeFromJsonElement(json)

        fun fromJson(json: String) : InformationShare =
            JsonUtilSerialization.json.decodeFromString(json)

    }

}
