package csaware.comm

import dk.rheasoft.csaware.api.InformationShare
import dk.rheasoft.csaware.api.QueryResult

open class InformationShareBackend(exceptionHandler: (errorCode: Int, errorText: String) -> Unit) : CSAwareBackend(exceptionHandler) {

    fun updateInformationShare(data: InformationShare, receiver: () -> Unit) {
        sendJsonTxt("/informationshare/decision/update", "POST", data.toJsonString()) {
            receiver()
        }
    }

    fun getInformationShares(offset: Int, pageSize: Int, receiver: (QueryResult<InformationShare>) -> Unit) {
        getTxt("/informationshare/decisions?offset=$offset&limit=$pageSize") {
            val x: QueryResult<InformationShare> = InformationShare.fromQueryResultJson(it)
            receiver(x)
        }
    }

    fun getInformationShareCount(receiver: (Int) -> Unit) {
        getTxt("/informationshare/count") {
            val x: Int = it.toInt()
            receiver(x)
        }
    }


}

