package dk.rheasoft.csaware.api

import dk.rheasoft.csaware.utils.JsonUtilSerialization
import kotlinx.serialization.Serializable
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString

@Serializable
class TranslationText(val source: String, val target: String, val text: String) {
    fun toJsonString(): String = JsonUtilSerialization.json.encodeToString(this)

    companion object {
        fun fromJson(json: String) : TranslationText =
            JsonUtilSerialization.json.decodeFromString(json)

    }
}