package csaware.systemdepend.nodedetails

import csaware.main.CsawareServices
import csaware.messages.csawareMessages
import csaware.systemdepend.graph.SystemGraph
import csaware.threats.WhereLink
import csaware.utilities.markdown.MarkdownRender
import dk.rheasoft.csaware.api.FieldType
import dk.rheasoft.csaware.api.SystemDependencyField
import dk.rheasoft.csaware.api.SystemDependencyResource
import kafffe.core.*
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLTableCellElement
import org.w3c.dom.HTMLTableElement


class SystemDependencyResourceDetails(
    selectionModel: Model<SystemDependencyResource>,
    private val hightlightModel: Model<String>
) : KafffeComponentWithModel<SystemDependencyResource>(selectionModel) {
    init {
        setModelChangedRerenderRecursive()
    }

    private val descriptionRender = addChild(MarkdownRender(model.property(SystemDependencyResource::description)))

    override fun attach() {
        super.attach()
        hightlightModel.listeners.add(onModelChanged)
    }

    override fun detach() {
        super.detach()
        hightlightModel.listeners.remove(onModelChanged)
    }

    override fun KafffeHtmlBase.kafffeHtml() =
        div {
            addClass("clearfix")
            withElement {

                htmlDescription()

                htmlInfoFlow()

                table {
                    withElement {
                        style.apply {
                            borderCollapse = "separate"
                            borderSpacing = "1em 0.3em"
                            marginLeft = "-1em"
                            marginTop = "0.2em"
                        }
                    }
                    for (field in CsawareServices.systemDependencyService.config.data.fields.filter { model.data.hasValue(it) }) {
                        htmlFieldRow(field)
                    }
                }

            }

        }


    private fun KafffeHtml<HTMLTableElement>.htmlFieldRow(field: SystemDependencyField) {
        val hasSingleValue = model.data.data.containsKey(field.id) && field.cardinality.isSingle
        // Multi value 0..* or 1..* - actual if 0 it should not be there
        val hasMultiValue = model.data.dataLists.containsKey(field.id) && field.cardinality.isMany

        if (hasSingleValue || hasMultiValue) {
            tr {
                td {
                    h5 {
                        addClass("csaware-label-infront")
                        text(field.label)
                    }
                }
                td {
                    addClass("csaware-field pl-2")
                    element.style.minWidth = "16em"
                    if (hasSingleValue) {
                        singleValue(field)
                    } else {
                        text(" ")
                        multiValue(field)
                    }
                }
            }

        }
    }

    private fun KafffeHtml<HTMLTableCellElement>.multiValue(field: SystemDependencyField) {
        for (value in model.data.dataLists[field.id]!!) {
            span {
                addClass("badge badge-multi-value")
                valueRender(field, value)
            }
        }
    }

    private fun KafffeHtml<HTMLTableCellElement>.singleValue(field: SystemDependencyField) {
        val value = model.data.data[field.id]!!
        valueRender(field, value)
    }

    private fun <T : HTMLElement> KafffeHtml<T>.valueRender(
        field: SystemDependencyField,
        value: String
    ) {
        when (field.type) {
            FieldType.MARKDOWN -> add(MarkdownRender(value).html)
            FieldType.DEPENDENCY -> add(WhereLink(setOf(value), "link-dark").html)
            FieldType.URL -> a {
                withElement {
                    href = value
                    target = "_"
                }
                text(value)
            }

            else -> text(if (field.type == FieldType.SECRET) "******" else value)
        }
    }

    private fun KafffeHtml<HTMLDivElement>.htmlInfoFlow() {
        if (model.data.x_infoflow.isNotEmpty()) {
            h4 {
                addClass("csaware-label-above")
                text(csawareMessages().system_depend_infoflow)
            }
            div {
                addClass("csaware-field")
                text(" ")
                for (tag in model.data.x_infoflow) {
                    span {
                        addClass("badge badge-multi-value")
                        if (tag == hightlightModel.data) {
                            addClass("csaware-highlight-border")
                            i { addClass("fas fa-check csaware-highlight-color") }
                        }
                        element.onclick = {
                            hightlightModel.data =
                                if (tag == hightlightModel.data) SystemGraph.SELECTION_HIGHLIGHT else tag; it.preventDefault()
                        }
                        text(tag)
                    }
                }
            }
        }
    }

    private fun KafffeHtml<HTMLDivElement>.htmlDescription() {
        if (model.data.description.isNotBlank()) {
            div {
                addClass("csaware-field")
                add(descriptionRender.html)
            }
        }
    }

}