@file:Suppress("unused")

package dk.rheasoft.csaware.api

import dk.rheasoft.csaware.utils.JsonUtilSerialization
import dk.rheasoft.csaware.utils.ObservedDataObjectSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.decodeFromString

/**
 * STIX Observed data object can have many forms, this class is made to be very generic in order to represent this.
 */
@Serializable(with= ObservedDataObjectSerializer::class)
data class ObservedDataObject (val fields: Map<String, Any>) {
    @Suppress("EnumEntryName")
    enum class PredefinedTypes(val typeName: String, val fieldNames: Set<String>) {
        artifact("artifact", setOf("mime_type", "payload_bin", "url", "hashes")),
        autonomous_system("autonomous-system", setOf("number", "name", "rir")),
        directory("directory", setOf("path", "path_enc", "created", "modified", "accessed", "contains_refs")),
        domain_name("domain-name", setOf("value", "resolves_to_refs"))
        // ...
    }

    operator fun get(fieldName: String) = fields[fieldName]
    fun getString(fieldName : String) = fields[fieldName]?.toString() ?: ""
    val id: String get() = getString("id")
    val type: String get() = getString("type")
    fun fieldNames() = fields.keys.toMutableSet().also{it.removeAll(setOf("id", "type"))}.toSet()

    fun toJson() = JsonUtilSerialization.json.encodeToString(serializer(),this)

    companion object {
        fun fromJson(json: String) : ObservedDataObject =
            JsonUtilSerialization.json.decodeFromString(serializer(), json)

        fun fromListJson(json: String) : List<ObservedDataObject> =
            JsonUtilSerialization.json.decodeFromString(json)

    }
}