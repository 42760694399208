package csaware.user

import csaware.main.CsawareServices
import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import csaware.utilities.ActionBar
import csaware.utilities.FileImportDlg
import csaware.utilities.SearchInput
import csaware.utilities.componentTitle
import dk.rheasoft.csaware.api.QueryResult
import dk.rheasoft.csaware.api.User
import dk.rheasoft.csaware.api.access.MainFeature
import dk.rheasoft.csaware.api.access.Permission
import dk.rheasoft.csaware.api.access.UserRole
import kafffe.bootstrap.BootstrapTable
import kafffe.bootstrap.BootstrapTableStyles
import kafffe.bootstrap.pagination.BootstrapPagination
import kafffe.bootstrap.pagination.Pager
import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut
import kafffe.core.Label
import kafffe.core.modifiers.CssClassModifier
import kotlinx.browser.window
import kotlin.math.ceil

class UserManagement : KafffeComponent() {
    private val titleLabel = addChild(Label(i18nText(CsawareMessages::user_management)))

    val table = addChild(BootstrapTable.create<User>(listOf()) {
        rowClickHandler = { user, _ -> showUserDlg(user, allowDelete = true) }

        addStyle(BootstrapTableStyles.striped)
        modifiers.add(CssClassModifier("csaware-hover"))
        modifiersHeader.add(CssClassModifier("bg-primary"))
        col(i18nText(CsawareMessages::user_subject), { Label(it.subject) })
        col(i18nText(CsawareMessages::user_email), { Label(it.email) })
        col(i18nText(CsawareMessages::user_fullname), { Label(it.fullname) })
        col(i18nText(CsawareMessages::user_roles), { Label(it.roles.joinToString(", ") {role -> role.namePrettified })})
    })

    var pageSize: Int = 10

    val pager = Pager(1)
    private val paginator = addChild(BootstrapPagination(pager).apply {
        prevNextPage = true
        modifiers.add(CssClassModifier("float-end"))
    })

    private var filterInput: String = ""
    private val searchInput = addChild(SearchInput {
        filterInput = it
        loadData()
    })


    private val actionBar = addChild(ActionBar().apply {
      item(i18nText(CsawareMessages::user_new),"far fa-plus-square") {
          accessRequirement = (MainFeature.UserManagement to Permission.Create)
          action = {
              showUserDlg(
                  User("-", "", "", "", "", setOf(UserRole.Viewer)),
                  allowDelete = false
              )
          }
      }
      dropdownItem(i18nText(CsawareMessages::nav_config), "fas fa-cog") {
          item(i18nText(CsawareMessages::user_action_export),"fas fa-file-export") {
              accessRequirement = (MainFeature.UserManagement to Permission.Read)
              action = ::export
          }
          item(i18nText(CsawareMessages::user_action_import),"fas fa-file-import") {
              accessRequirement = (MainFeature.CsawareAdministration to Permission.Write)
              action = ::import
          }
      }
    })

    private fun export() {
        window.parent.open("/user/export")
    }

    private fun import() {
        FileImportDlg(i18nText(CsawareMessages::user_action_import), "user_").apply {
            onSubmitOk = {
                val jsonData = fileData.data
                CsawareServices.userBackend.importUsers(jsonData) {
                    println("Imports: \n\t" + it.joinToString("\n\t"))
                }
            }
            attach()
        }
    }

    init {
        pager.changeListeners.add { loadData() }
        loadData()
    }

    fun loadData() {
        CsawareServices.alerts.clearAlerts()
        val offset = pageSize * (pager.currentPage - 1)
        CsawareServices.userBackend.getUsers(offset, pageSize, this::receiveData, filterInput)
    }

    private fun receiveData(response: QueryResult<User>) {
        val pageCount = ceil(response.nofResult.toDouble() / pageSize.toDouble()).toInt()
        if (pager.nofPages != pageCount) {
            pager.nofPages = pageCount
        }
        table.data = response.result
    }

    private fun showUserDlg(user: User, allowDelete: Boolean) {
        UserEditDlg(user, allowDelete, this::loadData).attach()
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {

        return div {
            componentTitle {
                add(titleLabel.html)
                add(searchInput.html)
                add(actionBar.html)
            }
            add(table.html)
            add(paginator.html)
        }
    }


}