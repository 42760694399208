package csaware.systemdepend.config

import csaware.messages.csawareMessages
import dk.rheasoft.csaware.api.SystemDependencyConfig
import kafffe.bootstrap.form.FormValueProvider
import kafffe.core.KafffeComponentWithModel
import kafffe.core.KafffeHtml
import kafffe.core.KafffeHtmlBase
import kafffe.core.Model
import org.w3c.dom.HTMLDivElement

class ValueSetsEditor(model: Model<MutableMap<String, MutableSet<String>>>,
                      val countFunction: () -> Map<String, Int>,
                      val valueCountFunction: (setName: String) -> Map<String, Int>) : KafffeComponentWithModel<MutableMap<String, MutableSet<String>>>(model), FormValueProvider {

    private val currentValueSets: MutableMap<String, MutableSet<String>> =
            // Deep clone to not make edits directly in real model
            model.data.map {
                Pair(it.key, it.value.toList().toMutableSet())
            }.toMap().toMutableMap()

    /**
     * The current valueSet under editing
     */
    private var selectedValueSet = SystemDependencyConfig.infoflowValueSet
    private val currentValues: MutableSet<String>
        get() = currentValueSets.getOrPut(selectedValueSet) { mutableSetOf() }

    override fun updateValueModel() {
        model.data = currentValueSets
    }

    override fun KafffeHtmlBase.kafffeHtml() =
            div {
                addClass("form-group")
                div {
                    addClass("row")
                    renderValueSets()
                    renderValues()
                }
            }

    private fun KafffeHtml<HTMLDivElement>.renderValues() {
        val tagWidth = "16rem"
        val count: Map<String, Int> = valueCountFunction(selectedValueSet)
        div {
            addClass("list-group me-4 col")
            label {
                text(csawareMessages().system_depend_fields_valueset_values)
                text(": $selectedValueSet")
            }
            currentValues.sorted().forEach { value ->
                div {
                    addClass("input-group")
                    div {
                        addClass("form-control list-group-item")
                        withElement {
                            style.width = tagWidth
                            style.maxWidth = tagWidth
                            setAttribute("readOnly", "true")
                        }
                        text(value)
                        val usageCount = count[value] ?: 0
                        if (usageCount > 0) {
                            sup {
                                addClass("badge badge-pill bg-warning")
                                text("$usageCount")
                            }
                        }
                    }
                    span {
                        addClass("input-group-append")
                        button {
                            addClass("btn btn-secondary")
                            withElement {
                                type = "button"
                                onclick = {
                                    currentValues.remove(value)
                                    rerender()
                                }
                            }

                            i {
                                addClass("fas fa-trash")
                            }

                        }
                    }
                }

            }

            div {
                addClass("input-group mt-1")
                val tagInput = input {
                    addClass("form-control list-group-item")
                    withElement {
                        type = "text"
                        value = ""
                        style.width = tagWidth
                        style.maxWidth = tagWidth
                    }
                }.element
                span {
                    addClass("input-group-append")
                    button {
                        addClass("btn btn-secondary")
                        withElement {
                            type = "button"
                            onclick = {
                                val newTag = tagInput.value
                                if (newTag.isNotBlank() && newTag !in currentValues) {
                                    currentValues.add(newTag)
                                    rerender()
                                }
                            }
                        }
                        i {
                            addClass("fas fa-plus")

                        }
                    }
                }
            }
        }
    }

    private fun KafffeHtml<HTMLDivElement>.renderValueSets() {
        val tagWidth = "16rem"
        val count: Map<String, Int> = countFunction()
        div {
            addClass("list-group col")
            label { text(csawareMessages().system_depend_fields_valueset) }
            currentValueSets.keys.forEach { setName ->
                div {
                    addClass("input-group")
                    div {
                        addClass("form-control list-group-item")
                        if (setName == selectedValueSet) {
                            addClass("selected")
                        } else {
                            element.onclick = {
                                selectedValueSet = setName
                                rerender()
                                it.preventDefault()
                            }
                        }
                        withElement {
                            style.width = tagWidth
                            style.maxWidth = tagWidth
                            setAttribute("readOnly", "true")
                        }
                        text(setName)
                        val usageCount = count[setName] ?: 0
                        if (usageCount > 0) {
                            sup {
                                addClass("badge badge-pill bg-warning")
                                text("$usageCount")
                            }
                        }
                    }
                    span {
                        addClass("input-group-append")
                        button {
                            addClass("btn btn-secondary")
                            withElement {
                                type = "button"
                                val usageCount = count[setName] ?: 0
                                if (usageCount > 0 || setName == SystemDependencyConfig.infoflowValueSet) {
                                    disabled = true
                                } else {
                                    onclick = {
                                        currentValueSets.remove(setName)
                                        rerender()
                                    }
                                }
                            }
                            i {
                                addClass("fas fa-trash")
                            }

                        }
                    }
                }

            }

            div {
                addClass("input-group mt-1")
                val tagInput = input {
                    addClass("form-control list-group-item")
                    withElement {
                        type = "text"
                        value = ""
                        style.width = tagWidth
                        style.maxWidth = tagWidth
                    }
                }.element
                span {
                    addClass("input-group-append")
                    button {
                        addClass("btn btn-secondary")
                        withElement {
                            type = "button"
                            onclick = {
                                val newTag = tagInput.value
                                if (newTag.isNotBlank() && newTag !in currentValueSets) {
                                    currentValueSets[newTag] = mutableSetOf()
                                    rerender()
                                }
                            }
                        }
                        i {
                            addClass("fas fa-plus")

                        }
                    }
                }
            }
        }
    }

}