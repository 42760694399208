package csaware.overview

import kotlinx.browser.document
import org.w3c.dom.Element
import org.w3c.dom.svg.SVGCircleElement
import org.w3c.dom.svg.SVGElement
import org.w3c.dom.svg.SVGTextElement
import kotlin.math.PI
import kotlin.math.cos
import kotlin.math.sin

/*
 * Anchor point of a text
 */
enum class TextAnchor {
    START,
    MIDDLE,
    END}

/*
 * Vertical alignment of a text
 */
enum class TextAlignmentBaseline {
    HANGING,
    MIDDLE,
    BASELINE
}

/*
 * What mood is Smiley in
 */
enum class SmileyMood {
    Sad,
    Neutral,
    Happy
}

private const val SVG_NAME_SPACE = "http://www.w3.org/2000/svg"

class SvgUtil {

    fun clearGraphics(svg: SVGElement ) {
        while ( svg.lastChild != null ) {
            val lastChild = svg.lastChild
            svg.removeChild(lastChild!!)
        }
    }

    fun text (svg: SVGElement, x: Number, y: Number,
              baseline: TextAlignmentBaseline,
              anchor: TextAnchor, fill: String, theText: String) {
        val text = document.createElementNS(SVG_NAME_SPACE, "text") as SVGTextElement
        text.apply {
            setAttribute("x", x.toString())
            setAttribute("y", y.toString())
            setAttribute("alignment-baseline",
                when(baseline) {
                    TextAlignmentBaseline.BASELINE -> "baseline"
                    TextAlignmentBaseline.HANGING -> "hanging"
                    TextAlignmentBaseline.MIDDLE -> "middle"
                })
            setAttribute("text-anchor",
                when (anchor) {
                    TextAnchor.START -> "start"
                    TextAnchor.MIDDLE -> "middle"
                    TextAnchor.END -> "end"
                }
            )
            setAttribute("fill", fill)
        }
        text.textContent = theText
        svg.append(text)
    }

    fun circle(svg: SVGElement, centerX: Number, centerY: Number, radius: Number, fillColor: String, strokeColor: String , strokeWidth: Number): Element {
        val circle = document.createElementNS(SVG_NAME_SPACE, "circle") as SVGCircleElement
        circle.apply {
            setAttribute("cx", centerX.toString())
            setAttribute("cy", centerY.toString())
            setAttribute("r", radius.toString())
            setAttribute("fill", fillColor)
            setAttribute("stroke", strokeColor)
            setAttribute("stroke-width", strokeWidth.toString())
        }
        svg.append(circle)
        return circle
    }

    fun translate (svg: SVGElement, x: Double, y: Double) {
        svg.setAttribute("transform", "translate($x, $y)")
    }

    fun buildArc(svg: SVGElement, arcPath: String, color: String,
                 stroke: String, strokeWidth: String,  id: String): Element {
        val innerRing = document.createElementNS(SVG_NAME_SPACE, "path")
        innerRing.apply {
            setAttribute("d", arcPath)
            setAttribute("id", id)
            setAttribute("fill", color)
            setAttribute("stroke", stroke)
            setAttribute("stroke-width" , strokeWidth)
        }
        svg.appendChild(innerRing)
        return innerRing
    }

    private fun polarToCartesian(x: Double, y: Double, radius: Double, radianAngle: Double): Pair<Double, Double> {
        return Pair(
            (cos(radianAngle) * radius) + x,
            (sin(radianAngle) * radius) + y
        )
    }
//
//    fun describeArc(x: Double, y: Double, innerRadius: Double,
//        outerRadius: Double, startAngle: Double, endAngle: Double): String {
//        val radius = innerRadius
//        val spread = outerRadius - innerRadius
//        val innerStart = polarToCartesian(x, y, radius, endAngle)
//        val innerEnd = polarToCartesian(x, y, radius, startAngle)
//        val outerStart = polarToCartesian(x, y, radius + spread, endAngle)
//        val outerEnd = polarToCartesian(x, y, radius + spread, startAngle)
//
//        val largeArcFlag = if ((endAngle - startAngle) <= 180)  "0" else "1"
//        val dArray = arrayOf<String>(
//                "M", outerStart.first.toString() , outerStart.second.toString(),
//                "A", (radius + spread).toString(), (radius + spread).toString(),
//                "0", largeArcFlag,
//                "0", outerEnd.first.toString(), outerEnd.second.toString(),
//                "L", innerEnd.first.toString(), innerEnd.second.toString(),
//                "A", radius.toString(), radius.toString(),
//                "0", largeArcFlag,
//                "1", innerStart.first.toString(), innerStart.second.toString(),
//                "L", outerStart.first.toString(), outerStart.second.toString(),
//                "Z")
//        val d = dArray.joinToString(separator = " ")
//        return d
//    }

    fun describeArc(
        x: Double, y: Double, smileyCircleRadius: Double, outerRadius: Double,
        startAngle: Double, endAngle: Double
    ): String {

        val spread = outerRadius - smileyCircleRadius
        val innerStart = polarToCartesian(x, y, smileyCircleRadius, endAngle)
        val innerEnd = polarToCartesian(x, y, smileyCircleRadius, startAngle)
        val outerStart = polarToCartesian(x, y, smileyCircleRadius + spread, endAngle)
        val outerEnd = polarToCartesian(x, y, smileyCircleRadius + spread, startAngle)

        val largeArcFlag = if ((endAngle - startAngle) <= 180) "0" else "1"

        val dArray = arrayOf(
            "M", outerStart.first.toString(), outerStart.second.toString(),
            "A", (smileyCircleRadius + spread).toString(), (smileyCircleRadius + spread).toString(),
            "0", largeArcFlag,
            "0", outerEnd.first.toString(), outerEnd.second.toString(),
            "L", innerEnd.first.toString(), innerEnd.second.toString(),
            "A", smileyCircleRadius.toString(), smileyCircleRadius.toString(),
            "0", largeArcFlag,
            "1", innerStart.first.toString(), innerStart.second.toString(),
            "L", outerStart.first.toString(), outerStart.second.toString(),
            "Z"
        )
        return dArray.joinToString(separator = " ")
    }


    fun buildSmiley(svg: SVGElement, x: Double, y: Double, radius: Double, mood: SmileyMood) {
        when (mood) {
            SmileyMood.Sad -> buildSadSmiley(svg, x, y, radius)
            SmileyMood.Neutral -> buildNeutralSmiley(svg, x, y, radius)
            SmileyMood.Happy -> buildHappySmiley(svg, x, y, radius)
            else -> buildEyes(svg, x, y, radius)
        }
    }

    private fun buildEyes(svg: SVGElement, x: Double, y: Double, smileyRadius: Double ) {
        val offsetX = (smileyRadius * 25.0) / 100.0
        val leftCircleX = x - offsetX
        val rightCircleX = x + offsetX
        val leftRightCircleY = y - (smileyRadius * 20.0) / 100.0
        val eyeRadius = (smileyRadius * 10.0) / 100.0

        // left eye of the smiley
        val leftCircle = document.createElementNS(SVG_NAME_SPACE, "circle")
        leftCircle.setAttribute("cx", leftCircleX.toString())
        leftCircle.setAttribute("cy", leftRightCircleY.toString())
        leftCircle.setAttribute("r", eyeRadius.toString())
        leftCircle.setAttribute("fill", "white")
        leftCircle.setAttribute("stroke", "black")
        leftCircle.setAttribute("strokeWidth", "1")
        svg.appendChild(leftCircle)

        // right eye of the smiley
        val rightCircle = document.createElementNS(SVG_NAME_SPACE, "circle")
        rightCircle.setAttribute("cx", rightCircleX.toString())
        rightCircle.setAttribute("cy", leftRightCircleY.toString())
        rightCircle.setAttribute("r", eyeRadius.toString())
        rightCircle.setAttribute("fill", "white")
        rightCircle.setAttribute("stroke", "black")
        rightCircle.setAttribute("strokeWidth", "1")
        svg.appendChild(rightCircle)
    }

    private fun buildHappySmiley(svg: SVGElement, x: Double, y: Double, smileyRadius: Double) {
        buildEyes(svg, x, y, smileyRadius)

        //variables for the circle elements
        val ySmile = y + (smileyRadius * 10.0 / 100.0)
        val smileRadiusStart = (smileyRadius * 35.0 / 100.0)
        val smileRadiusEnd = (smileyRadius * 45.0 / 100.0)
        val smileStartAngle = (20.0 / 180.0) * PI
        val smileEndAngle = (160.0 / 180.0) * PI

        val smilePath = describeArc(x, ySmile, smileRadiusStart, smileRadiusEnd,
            smileStartAngle, smileEndAngle)
        val smileArc = document.createElementNS(SVG_NAME_SPACE, "path")
        smileArc.setAttribute("d", smilePath)
        smileArc.setAttribute("fill", "white")
        smileArc.setAttribute("stroke", "black")
        smileArc.setAttribute("strokeWidth", "1")
        svg.appendChild(smileArc)
    }


    private fun buildNeutralSmiley(svg: SVGElement, x: Double, y: Double, smileyCircleRadius: Double) {
        buildEyes(svg, x, y, smileyCircleRadius)

        val rectWidth = (smileyCircleRadius * 95.0) / 100.0
        val rectHeight = (smileyCircleRadius * 10.0) / 100.0
        val xRect = x - (rectWidth / 2.0)
        val yRect = y - (rectHeight / 2.0) + ((smileyCircleRadius * 25.0) / 100.0)

        val flatSmile = document.createElementNS(SVG_NAME_SPACE, "rect")
        flatSmile.setAttribute("x", xRect.toString())
        flatSmile.setAttribute("y", yRect.toString())
        flatSmile.setAttribute("width", rectWidth.toString())
        flatSmile.setAttribute("height", rectHeight.toString())
        flatSmile.setAttribute("ry", "2")
        flatSmile.setAttribute("fill", "white")
        flatSmile.setAttribute("id", "flatSmile")
        flatSmile.setAttribute("stroke", "black")
        flatSmile.setAttribute("strokeWidth", "1")
        svg.appendChild(flatSmile)
    }

    private fun buildSadSmiley(svg: SVGElement, x: Double, y: Double, radius: Double) {
        buildEyes(svg, x, y, radius)

        val ySmile = y + (radius * 65.0) / 100.0
        val smileRadiusStart = -((radius * 40.0) / 100.0)
        val smileRadiusEnd = -((radius * 50.0) / 100.0)
        val smileStartAngle = (20.0 / 180.0) * PI
        val smileEndAngle = (160.0 / 180.0) * PI

        val smilePath = describeArc(x, ySmile, smileRadiusStart, smileRadiusEnd, smileStartAngle, smileEndAngle )
        val smileArc = document.createElementNS(SVG_NAME_SPACE, "path")
        smileArc.setAttribute("d", smilePath)
        smileArc.setAttribute("fill", "white")
        smileArc.setAttribute("stroke", "black")
        smileArc.setAttribute("strokeWidth", "1")

        svg.appendChild(smileArc)
    }
}