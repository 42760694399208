package dk.rheasoft.csaware.api.socialmedia

enum class SocialMediaType {
    Twitter,
    Reddit,
    RSS,
    Other;

    companion object {
        fun fromString(value: String): SocialMediaType =
            entries.find { it.name.equals(value, ignoreCase = true) } ?: Other
    }
}