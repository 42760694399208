package csaware.infoshare

import dk.rheasoft.csaware.api.InformationShareData
import kafffe.bootstrap.form.FormValueProvider
import kafffe.core.KafffeComponentWithModel
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut
import kafffe.core.Model

class InformationShareDataEditor(model: Model<InformationShareData>) : KafffeComponentWithModel<InformationShareData>(model), FormValueProvider {
    var information = model.data.information
    var deleted = model.data.isDeleted
    var changed = model.data.isChanged

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
            div {
                addClass("form-row mt-1")
                label {
                    addClass("col-sm-3")
                    text(model.data.name)
                }
                textarea {
                    addClass("col-sm-8")
                    addClass("form-control")
                    withElement {
                        rows = 3
                        value = information
                        if (!model.data.editable) {
                            readOnly = true
                            addClass("csaware-field")
                        }
                        oninput = { iv -> changed = (value != model.data.information); information = value; iv }
                    }
                    if (deleted) {
                        element.style.textDecoration = "line-through"
                    }
                }
                div {
                    addClass("col-sm-1")
                    if (model.data.deletable) {
                        button {
                            addClass("btn")
                            if (deleted) {
                                addClass("btn-danger")
                                i { addClass("fas fa-trash-restore") }
                            } else {
                                addClass("btn-success")
                                i { addClass("fas fa-trash") }
                            }
                            withElement {
                                onclick = { click -> deleted = !deleted; information = model.data.information; rerender(); click.preventDefault() }
                            }
                        }
                    }
                }
            }

    override fun updateValueModel() {
        model.data.isChanged = changed
        model.data.information = information
        model.data.isDeleted = deleted
    }
}