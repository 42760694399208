package csaware.utilities

import csaware.main.CsawareServices
import csaware.main.UserInformation
import dk.rheasoft.csaware.api.ThreatGroupWithTranslations
import dk.rheasoft.csaware.api.access.MainFeature
import dk.rheasoft.csaware.api.access.Permission
import dk.rheasoft.csaware.api.access.UserRole
import kafffe.core.Model
import kafffe.core.RefreshingCacheModel

/**
 * Service to cache and get different configuration stored in the Backend.
 */
class ConfigurationService {

     val threatGroupsModel: Model<List<ThreatGroupWithTranslations>> = RefreshingCacheModel(::refreshThreatGroups, listOf(), timeToLiveSeconds = 60 * 60)

     private fun refreshThreatGroups(model: Model<List<ThreatGroupWithTranslations>>) {
         if (UserInformation.hasAccess(MainFeature.Threats, Permission.Read)) {
             CsawareServices.threatsBackend.getThreatConfiguredGroups {
                 model.data = it
             }
         } else {
             model.data = emptyList()
         }
    }

    fun refreshAll() {
        refreshThreatGroups(threatGroupsModel)
    }

}