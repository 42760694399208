package csaware.socialmedia

import csaware.comm.ThreatsBackend
import csaware.mail.MailEditDlg
import csaware.main.CsawareServices
import csaware.main.UserInformation
import csaware.messages.CsawareMessages
import csaware.messages.csawareMessageStrategy
import csaware.messages.csawareMessages
import csaware.messages.i18nText
import csaware.threats.ThreatStateChangeDlg
import csaware.utilities.SeverityDropdown
import csaware.utilities.ThreatGroupDropdown
import csaware.utilities.markdown.MarkDownInput
import dk.rheasoft.csaware.api.SystemDependencyResource
import dk.rheasoft.csaware.api.ThreatObservation
import dk.rheasoft.csaware.api.access.MainFeature
import dk.rheasoft.csaware.api.access.Permission
import kafffe.bootstrap.BasicColor
import kafffe.bootstrap.ColWidth
import kafffe.bootstrap.ModalSize
import kafffe.bootstrap.ResponsiveSize
import kafffe.bootstrap.form.FormDialog
import kafffe.bootstrap.form.MultipleEditSelect
import kafffe.bootstrap.form.editSelectSingle
import kafffe.bootstrap.form.textArea
import kafffe.core.Model
import kafffe.core.func
import kafffe.core.modifiers.CssClassModifier
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kafffe.core.modifiers.HtmlElementModifier
import kafffe.core.modifiers.StyleModifier
import kafffe.core.property
import org.w3c.dom.HTMLInputElement

class CreateThreatReportDlg(observation: ThreatObservation) :
    FormDialog<ThreatObservation>(
        i18nText(CsawareMessages::socialmedia_create_threatobservation),
        Model.of(observation)
    ) {

    private val assigneeModel: Model<String?> = Model.ofNullable(observation.assignee)
    private val assigneesModel: Model<List<String>> = Model.ofGet {
        val admins: List<String> = UserInformation.threatManagers.data.map { it.email }
        listOf("") + admins
    }

    private val systemResourceIds = CsawareServices.systemDependencyService.model.data.sortedBy(SystemDependencyResource::name).map { it.id }

    // Map functional submodel list to mutable set of ids
    private val whereSightedRefsModel: Model<List<String>> = model.func(
        { p -> p.data.whereSightedRefs.toList() },
        { p, v ->
            p.data.whereSightedRefs.clear()
            p.data.whereSightedRefs.addAll(v)
        }
    )

    private val whereSightedRefs =
        object : MultipleEditSelect<String>("whereSightedRefs", whereSightedRefsModel, Model.of(systemResourceIds)) {
            override fun display(choice: String): String =
                CsawareServices.systemDependencyService.byId(choice)?.name ?: ""
        }

    init {
        labelStrategy = csawareMessageStrategy("threat_")
        size = ModalSize.large
        modal.modifiersBody.add(StyleModifier {
            overflowY = "auto"
            maxHeight = "90vh"
        })
        modal.modifiersModal.add(StyleModifier {
            maxWidth = "70rem"
            width = "95vw"
        })
        modal.modifiersContent.add(CssClassModifier("bg-light"))
        // We need some gap because we do not apply whitespace between elements.
        cssClassModifier("hgap-3 vgap-3")
        row {
            col(ColWidth(ResponsiveSize.md, 4)) {
                readonly(i18nText(CsawareMessages::threat_stixtype), Model.of( csawareMessages().threatStixType(model.data.stixType) ))
            }
            col(ColWidth(ResponsiveSize.md, 4)) {
                val threatGroups = CsawareServices.configrationService.threatGroupsModel.data
                val threatGroupDropdown =
                    ThreatGroupDropdown("threatGroup", model.property(ThreatObservation::threatGroup), threatGroups)
                decorateAndAddComponent(i18nText(CsawareMessages::threat_group), threatGroupDropdown)
            }
            col(ColWidth(ResponsiveSize.md, 4)) {
                val severityDropdown = SeverityDropdown("severity", model.property(ThreatObservation::severity))
                decorateAndAddComponent(i18nText(CsawareMessages::severity), severityDropdown)
            }
        }
        row {
            col(ColWidth(ResponsiveSize.md, 12)) {
                cssClassModifier("vgap-3")
                input(ThreatObservation::name)

                val inp = MarkDownInput(model.property(ThreatObservation::description))
                decorateAndAddComponent(i18nText(CsawareMessages::description), inp)

                decorateAndAdd(i18nText(CsawareMessages::threat_where), whereSightedRefs)

                val initialCommentModel: Model<String> = Model.ofGetSet(
                    getter = { model.data.stateHistory.first().description },
                    setter = { value -> model.data.stateHistory.first().description = value }
                )
                textArea("initialComment", i18nText(CsawareMessages::threat_state_comment), initialCommentModel).apply {
                    required = true
                    lines = 4
                }
                editSelectSingle(
                    "assignee",
                    i18nText(CsawareMessages::threat_assignee),
                    assigneeModel,
                    assigneesModel
                ).apply {
                    modifiersInputControl.add(HtmlElementModifier.create {
                        (this as HTMLInputElement).readOnly = !UserInformation.hasAccess(MainFeature.Threats, Permission.Create)
                    })
                }
            }
        }
        submit("save", ::save).color = BasicColor.success
        cancel().color = BasicColor.secondary
    }


    private fun save() {
        CsawareServices.alerts.clearAlerts()
        val observation = model.data
        CsawareServices.threatsBackend.updateThreatObservation(observation) { observationAfterSave ->
            val assignee = observationAfterSave.assignee
            val reassigned =
                (assignee.isNotBlank() && !assignee.equals(UserInformation.current.email, ignoreCase = true))
            if (reassigned) {
                MailEditDlg.showThreatAssignDialog(
                    assignee,
                    observationAfterSave,
                    observationAfterSave.stateHistory.first()
                )
            }
            rerenderRecursive()
        }
        detach()
    }

    companion object {
        // Either show change state dialog or create dialog if not already existing
        fun show(observation: ThreatObservation) {
            fun notFoundHandler(code: Int, message: String) {
                if (code == 404) {
                    // Not found is expected
                    CreateThreatReportDlg(observation).attach()
                } else {
                    // normal exception handling
                    CsawareServices.exceptionHandler(code, message)
                }
            }
            ThreatsBackend(::notFoundHandler).threatWithDetails(observation.id) { observationReloaded ->
                ThreatStateChangeDlg.showWithoutReload(observationReloaded)
            }
        }

    }
}