package dk.rheasoft.csaware.api.systemdependencies

import kotlinx.serialization.Serializable

@Serializable
class NodeShape(
    val mxShapeName: String,
    val styleName: String = mxShapeName,
    val valueBelow: Boolean = false,
    val stencilShape: String? = null,
) {
}

