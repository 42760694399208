package dk.rheasoft.csaware.api

import dk.rheasoft.csaware.utils.JsonUtilSerialization
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlin.time.Duration.Companion.days

enum class ThreatState(val active: Boolean = false) {
    Active(true),
    Resolved,
    Ignored,
    HealingAwaitDecision(true),
    HealingAccepted(true),
    HealingDeclined(true),
    Healed(true),
    HealingFailed(true),
    HealingInProgress(true),
    Unknown;

    companion object {
        fun parse(value: String): ThreatState = try { valueOf(value) } catch (any: Throwable) { Unknown }
    }
}

@Serializable
data class StateHistory(
    var initiator: String = "here comes initials/system",
    var state: ThreatState,
    var time: Instant,
    var description: String
) {
    fun toJsonString(): String = JsonUtilSerialization.json.encodeToString(this)

    companion object {
        fun fromJson(jsonString: String): StateHistory =
            JsonUtilSerialization.json.decodeFromString(jsonString)
    }
}

@Serializable
data class CourseOfAction(
        var id: String,
        var name: String,
        var description: String,
        var action: String
        // Support any custom attributes, prepare for STIX 2
)

@Serializable
data class  ThreatObservation(
    var id: String,
    var threatGroup: String = "",
    var stixType: String = "",
    var name: String = "",
    var description: String = "",
    var threatId: String = "",
    var bundleId: String = "",
    val observedDataRefs: MutableSet<String> = mutableSetOf(),
    val whereSightedRefs: MutableSet<String> = mutableSetOf(),
    /**
     * Email of user (SYStem Admininstrator) that currently have this assigned.
     * There assignee have no history for now, but the iniator of a change is in the state history, so the person that assigned this can be seen it the history.
     */
    var assignee: String = "",

    /**
     * Time when this was first observed
     */
    var firstObserved: Instant = Clock.System.now(),
    /**
     * Time when this is no longer considered and active threat
     */
    var endActive: Instant = Clock.System.now() + 365.days,
    var lastObserved: Instant = Clock.System.now(),
    var state: ThreatState = ThreatState.Active,
    var severity: Int = 0,
    var riskLevel: Double = 0.0,
    var exploitability: Double = 0.0,
    var count: Int = 0,
    val courseOfActions: MutableList<CourseOfAction> = mutableListOf(),
    val stateHistory: MutableList<StateHistory> = mutableListOf(),
) {
    init {
        if (stateHistory.isEmpty()) {
            stateHistory.add(StateHistory("CS-Aware", ThreatState.Active, firstObserved, "initial"))
        }
    }

    val active: Boolean get() = endActive > Clock.System.now()


    fun changeState(
        initiator: String,
        newState: ThreatState,
        description: String,
        time: Instant = Clock.System.now()
    ): StateHistory {
        state = newState
        endActive = if (newState.active) {
            Clock.System.now() + 365.days
        } else {
            time
        }
        val stateHistoryElement = StateHistory(initiator, newState, time, description)
        stateHistory.add(0, stateHistoryElement)
        return stateHistoryElement
    }

    fun add(courseOfAction: CourseOfAction) {
        val found = courseOfActions.find { it.id == courseOfAction.id }
        if (found != null) {
            courseOfActions.remove(found)
        }
        courseOfActions.add(0, courseOfAction)
    }

     fun toJsonString(): String = JsonUtilSerialization.json.encodeToString(this)

    companion object {
        /* filter value used to signal filter those that have no assignee */
        const val nobodyFilter = "@@@@"

        fun fromJson(json: String) : ThreatObservation =
            JsonUtilSerialization.json.decodeFromString(json)

        fun QueryResult<ThreatObservation>.toJsonString() : String =
            JsonUtilSerialization.json.encodeToString(QueryResult.serializer(serializer()),this)

        fun fromQueryResultJson(json: String) : QueryResult<ThreatObservation> =
            JsonUtilSerialization.json.decodeFromString(QueryResult.serializer(serializer()),json)

        fun empty(): ThreatObservation = ThreatObservation("empty-id")
    }
}
