package dk.rheasoft.csaware.utils

import kotlinx.datetime.Instant
import kotlinx.serialization.json.*

/**
 * Extension functions to help build JSon using kotlinx.serialization.json.JsonElementBuilders
 */
object JsonBuilderUtils {
    /**
     * Adds an JSsnArray to JsonObject being build.
     */
    fun JsonObjectBuilder.putStrings(key: String, values: Collection<String>) {
        putJsonArray(key) {
            values.forEach { value ->
                add(value)
            }
        }
    }

    fun fromMap(srcMap: Map<String, Any> ) : JsonObject =
        buildJsonObject {
            srcMap.forEach {(key: String, value: Any) ->
                @Suppress("UNCHECKED_CAST")
                when (value) {
                    is Number -> put(key, value)
                    is String -> put(key, value)
                    is Boolean -> put(key, value)
                    is Instant -> put(key, value.toString())
                    is Collection<*> -> put(key, fromCollection(value as Collection<Any>))
                    is Map<*, *> -> put(key, fromMap(value as Map<String, Any>))
                }
            }
        }

    private fun fromCollection(srcCollection: Collection<Any> ) : JsonArray =
        buildJsonArray {
            srcCollection.forEach {value: Any ->
                @Suppress("UNCHECKED_CAST")
                when (value) {
                    is Number -> add(value)
                    is Boolean -> add(value)
                    is String -> add(value)
                    is Instant -> add(value.toString())
                    is Collection<*> -> add(fromCollection(value as Collection<Any>))
                    is Map<*, *> -> add(fromMap(value as Map<String, Any>))
                }
            }
        }

}
