package csaware.threats

import csaware.comm.CSAwareBackend
import csaware.main.CsawareServices
import csaware.main.UserConfiguration
import csaware.main.UserInformation
import csaware.messages.CsawareMessages
import csaware.messages.csawareMessages
import csaware.messages.i18nText
import csaware.socialmedia.CreateThreatReportDlg
import csaware.systemdepend.UiFunctions
import csaware.translation.TranslationModel
import csaware.utilities.EllipsisLabel
import csaware.utilities.componentTitle
import dk.rheasoft.csaware.api.QueryResult
import dk.rheasoft.csaware.api.ThreatObservation
import dk.rheasoft.csaware.api.ThreatState
import dk.rheasoft.csaware.api.UpdateEvent
import dk.rheasoft.csaware.api.access.MainFeature
import dk.rheasoft.csaware.api.access.Permission
import kafffe.bootstrap.BasicColor
import kafffe.bootstrap.BootstrapButton
import kafffe.bootstrap.BootstrapTable
import kafffe.bootstrap.BootstrapTableStyles
import kafffe.bootstrap.modifier.BootstrapPopoverModifier
import kafffe.bootstrap.modifier.BootstrapTooltipModifier
import kafffe.bootstrap.pagination.BootstrapPagination
import kafffe.bootstrap.pagination.Pager
import kafffe.core.*
import kafffe.core.modifiers.CssClassModifier
import kafffe.messages.Messages
import kafffe.messages.MessagesObject.formatDateTime
import kafffe.messages.i18nText
import kotlinx.datetime.Clock
import kotlinx.datetime.toJSDate
import org.w3c.dom.HTMLTableCellElement
import kotlin.math.ceil
import kotlin.time.Duration.Companion.days

/**
 * Base for table that lists threat observation.
 * Each threat observation is displayed as row and this can be opened in a dialog for editing the state and comment an/or for viewing details.
 */
abstract class ThreatsTableBase(titleModel: Model<String>, val showEndActive: Boolean = false) : KafffeComponent() {
    final override fun <Child : KafffeComponent> addChild(child: Child) = super.addChild(child)

    private val titleLabel = addChild(Label(titleModel))

    val filter = ThreatFilterData()

    val table = addChild(BootstrapTable.create<ThreatObservation>(listOf()) {
        rowClickHandler = { data, _ -> ThreatStateChangeDlg.show(data) }

        addStyle(BootstrapTableStyles.striped)
        modifiers.add(CssClassModifier("csaware-hover"))
        modifiersHeader.add(CssClassModifier("bg-primary"))

        colEx(i18nText(CsawareMessages::severity), fun(rowData: ThreatObservation, cell: HTMLTableCellElement): ThreatStateSymbol {
            cell.style.backgroundColor = UserConfiguration.default.severityColorMap[rowData.severity]
            return ThreatStateSymbol(Model.of(rowData), includeSeverityText = true)
        })

        if (showEndActive) {
            col(i18nText(CsawareMessages::threat_endActive), { Label(it.endActive.toJSDate().formatDateTime()) })
        }
        col(i18nText(CsawareMessages::threat_firstObserved), { Label(it.firstObserved.toJSDate().formatDateTime()) })
        col(i18nText(CsawareMessages::id), { Label(it.id) })
        col(i18nText(CsawareMessages::threat_stixtype), { Label(csawareMessages().threatStixType(it.stixType)) })
        col(
            i18nText(CsawareMessages::threat_group),
            { Label(it.threatGroup) },
            { ColumnHeaderDropdownFilter(it, filter.groupModel, filter.all, filter.groupChoices) })
        col(
            i18nText(CsawareMessages::threat_assignee),
            { Label(it.assignee) },
            { ColumnHeaderDropdownFilter(it, filter.assigneeModel, filter.all, filter.assigneeChoices) })
        col(i18nText(CsawareMessages::threat_where),
            { WhereLink(it.whereSightedRefs) },
            { ColumnHeaderDropdownFilter(it, filter.whereModel, filter.all, filter.whereChoices) }).apply { rowClick = false }
        col(i18nText(CsawareMessages::name), { Label(it.name) })
        colEx(i18nText(CsawareMessages::description), { observation, cell ->
            val translationModel = TranslationModel(Model.of(observation.description))
            val label = EllipsisLabel("20vw", translationModel.translationModel(), lines = 2).apply { setModelChangedRerender() }
            Popovers.markdown { translationModel.translation }.modify(cell)
            cell.tabIndex = 0
            label
        }).apply { rowClick = false }
        // Count not applicable for the current use cases  col(i18nText(CsawareMessages::count), { Label(it.count.toString()) })
    })

    var pageSize: Int = 10

    val pager = Pager(1)
    private val paginator = addChild(BootstrapPagination(pager).apply {
        prevNextPage = true
        modifiers.add(CssClassModifier("float-end"))
    })

    init {
        pager.changeListeners.add { loadData() }
    }

    val loadButton = addChild(
        BootstrapButton(i18nText(Messages::load), onClick = { loadData() }).apply {
            iconClasses = "fas fa-sync"
            color = BasicColor.primary
        })

    open fun loadData() {
        CsawareServices.alerts.clearAlerts()
        // clear any tootltip,popovers
        BootstrapTooltipModifier.removeAll()
        BootstrapPopoverModifier.removeAll()

        val offset = pageSize * (pager.currentPage - 1)
        if (!filter.isFilter()) {
            CsawareServices.threatsBackend.getThreatsCurrentActive(Clock.System.now(), offset, pageSize, this::receiveData)
        } else {
            CsawareServices.threatsBackend.getThreatsCurrentActiveWithFilter(
                Clock.System.now(),
                filter,
                offset,
                pageSize,
                this::receiveData
            )
        }
    }

    fun receiveData(response: QueryResult<ThreatObservation>) {
        val pageCount = ceil(response.nofResult.toDouble() / pageSize.toDouble()).toInt()
        if (pager.nofPages != pageCount) {
            pager.nofPages = pageCount
        }
        table.data = response.result
    }

    private fun createThreat() {
        val newThreatId = UiFunctions.generateUuid("tick--")
        val report = ThreatObservation(
            id = newThreatId,
            threatGroup = "Social Media",
            stixType = "report",
            name = "",
            description = "",
            threatId = newThreatId,
            assignee = UserInformation.current.email,
            firstObserved = Clock.System.now(),
            endActive = Clock.System.now() + 365.days,
            lastObserved = Clock.System.now(),
            state = ThreatState.Active,
            severity = 2,
            riskLevel = 0.0,
            exploitability = 0.0,
            count = 1,
            whereSightedRefs = mutableSetOf()
        )
        CreateThreatReportDlg.show(report)
    }

    override fun KafffeHtmlBase.kafffeHtml() =
        div {
            componentTitle {
                add(titleLabel.html)
                if (showEndActive) {
                    a {
                        addClass("btn btn-light ms-1 float-end")
                        element.href = "/threats/closed.export"
                        i { addClass("fas fa-file-excel") }
                        +" "
                        i { addClass("fas fa-download") }
                        +" "
                    }
                }
                if (UserInformation.hasAccess(MainFeature.Threats, Permission.Write)) {
                    a {
                        addClass("btn btn-light ms-1 float-end")
                        i { addClass("fas fa-exclamation-triangle") }
                        withElement {
                            title = csawareMessages().socialmedia_create_threatobservation
                            onClick { createThreat() }
                        }
                    }
                }
            }
            add(table.html)
            add(paginator.html)
        }

    private val onServerUpdate: (UpdateEvent) -> Unit = { serverUpdated(it) }

    private val changeListener = ModelChangeListener { loadData() }

    protected open fun serverUpdated(msg: UpdateEvent) {
        if (msg.type == UpdateEvent.EntityType.ThreatObservation) {
            loadData()
        }
    }

    override fun attach() {
        super.attach()
        CSAwareBackend.updateListeners.add(onServerUpdate)
        filter.asModel.listeners.add(changeListener)
    }

    override fun detach() {
        filter.asModel.listeners.remove(changeListener)
        CSAwareBackend.updateListeners.remove(onServerUpdate)
        super.detach()
    }
}