package csaware.test

import csaware.comm.ThreatsBackend
import csaware.main.UserConfiguration
import csaware.messages.CsawareMessages
import csaware.threats.ThreatStateSymbol
import dk.rheasoft.csaware.api.QueryResult
import dk.rheasoft.csaware.api.ThreatObservation
import dk.rheasoft.csaware.api.ThreatState
import kafffe.bootstrap.*
import kafffe.bootstrap.form.BootstrapForm
import kafffe.bootstrap.form.DropdownString
import kafffe.bootstrap.form.dropdown
import kafffe.bootstrap.form.textArea
import kafffe.bootstrap.pagination.BootstrapPagination
import kafffe.bootstrap.pagination.Pager
import kafffe.core.DivContainer
import kafffe.core.Label
import kafffe.core.Model
import kafffe.core.PropertyModel
import kafffe.core.modifiers.CssClassModifier
import kafffe.core.modifiers.StyleModifier.Companion.styleModifier
import kafffe.core.modifiers.onchange
import kafffe.messages.MessagesObject.formatDateTime
import kotlinx.datetime.Clock
import kotlinx.datetime.toJSDate
import org.w3c.dom.HTMLTableCellElement
import kotlin.math.ceil

class SelfHealingTestPage : DivContainer() {
    private var courseOfActionName = ""
    private var courseOfAction = """{
                "id": "course-of-action--8e2e2d2b-17d4-4cbf-938f-98ee46b3cd3f",
                "name": "jqueryui_mitigation",
                "description": "CS-AWARE SIMULATION: Download and apply the update packages found in the following link: https://access.redhat.com/errata/RHSA-2015:1462"
            }"""

    fun template(threatId: String, state: String): String {
        val details = if (state == "confirm") """
            "course_of_action": $courseOfAction
        """ else """
                "description": "Some change info"
        """
        return """{
      "id": "$threatId",
      "state": "$state",
      $details
    }"""
    }

    val alerts = addChild(AlertsPanel())

    data class FormData(var healingdata: String)

    val input = Model.of(FormData(template("TODO", "confirm")))
    private val bundleNames: Model<List<String>> = Model.of(listOf(""))

    val form = addChild(BootstrapForm(input).apply {
        val coaSelector: DropdownString = dropdown("select", Model.of("Course of action"), PropertyModel(this@SelfHealingTestPage::courseOfActionName), bundleNames)
        coaSelector.onchange {
            coaSelector.updateValueModel()
            loadCourseOfActions()
        }
        textArea(FormData::healingdata).apply {
            styleModifier {
                height = "20vh"
                width = "80vw"
            }
            required = true
        }
        submit()
        onSubmitOk = this@SelfHealingTestPage::submitOk
    })

    val spacer = addChild(Label(Model.of("-")))

    val table = addChild(BootstrapTable.create<ThreatObservation>(listOf()) {
        addStyle(BootstrapTableStyles.striped)
        addStyle(BootstrapTableStyles.bordered)
        modifiers.add(CssClassModifier("csaware-hover"))
        col(Model.of("Pick"), { observation ->
            DivContainer().apply {
                modifiers.add(CssClassModifier("btn-group"))
                fun btn(title: String, state: String) = addChild(BootstrapButton(Model.of(title)) { input.data = FormData(template(observation.id, state)) })
                if (observation.state in setOf(ThreatState.Active, ThreatState.HealingDeclined, ThreatState.Unknown))
                    btn("Confirm?", "confirm").color = BasicColor.primary
                if (observation.state in setOf(ThreatState.HealingAccepted))
                    btn("Progress", "progress")
                if (observation.state in setOf(ThreatState.HealingAccepted, ThreatState.HealingInProgress)) {
                    btn("Healed", "healed").color = BasicColor.success
                    btn("Failed", "failed").color = BasicColor.error
                }
            }
        })
        colEx(csaware.messages.i18nText(CsawareMessages::severity), fun(rowData: ThreatObservation, cell: HTMLTableCellElement): ThreatStateSymbol {
            cell.bgColor = UserConfiguration.default.severityColorMap[rowData.severity]
            return ThreatStateSymbol(Model.of(rowData), includeSeverityText = true)
        })
        col(csaware.messages.i18nText(CsawareMessages::threat_endActive), { Label(it.endActive.toJSDate().formatDateTime()) })
        col(csaware.messages.i18nText(CsawareMessages::threat_firstObserved), { Label(it.firstObserved.toJSDate().formatDateTime()) })
        col(csaware.messages.i18nText(CsawareMessages::threat_group), { Label(it.threatGroup) })
        col(csaware.messages.i18nText(CsawareMessages::name), { Label(it.name) })

    })

    var pageSize: Int = 6
    val pager = Pager(1)

    @Suppress("unused")
    val paginator = addChild(BootstrapPagination(pager).apply {
        prevNextPage = true
        modifiers.add(CssClassModifier("float-end"))
    })

    init {
        pager.changeListeners.add { loadData() }
    }

    init {
        loadData()
    }

    private fun loadCourseOfActions() {
        backend().getTxt("/test/usecase/selfhealing/$courseOfActionName") { data ->
            courseOfAction = data
        }
    }

    fun loadData() {
        alerts.clearAlerts()
        val offset = pageSize * (pager.currentPage - 1)
        backend().getThreatsCurrentActive(Clock.System.now(), offset, pageSize, this::receiveData)
        backend().getTxt("/test/usecase/selfhealing/list") {
            val json: Array<String> = JSON.parse(it)
            bundleNames.data = json.toList()
        }
    }

    private fun receiveData(response: QueryResult<ThreatObservation>) {
        val pageCount = ceil(response.nofResult.toDouble() / pageSize.toDouble()).toInt()
        if (pager.nofPages != pageCount) {
            pager.nofPages = pageCount
        }
        table.data = response.result
    }

    private fun submitOk() {
        alerts.clearAlerts()
        println(input.data.healingdata)
        // treePrint(parse(input.data.healingdata))
        backend().sendJsonTxt("/test/selfhealing/update", "POST", input.data.healingdata) {
            alerts.addAlert(Alert("Self Healing test send: $it", BootstrapLevel.info))
        }
    }

    private fun errorHandler(code: Int, text: String) {
        alerts.addAlert(Alert("($code) $text", BootstrapLevel.danger))
    }

    private fun backend() = ThreatsBackend(this::errorHandler)
}