package csaware.comm


import dk.rheasoft.csaware.api.SystemPreferences

open class SystemPreferencesBackend(exceptionHandler: (errorCode: Int, errorText: String) -> Unit) : CSAwareBackend(exceptionHandler) {

    fun get(receiver: (SystemPreferences) -> Unit) {
        getTxt("/systempreferences/get") {
            val x: SystemPreferences = SystemPreferences.fromJson(it)
            receiver(x)
        }
    }

    fun store(systemPreferences: SystemPreferences, receiver: (SystemPreferences) -> Unit) {
        sendJsonTxt("/systempreferences/store", "POST", systemPreferences.toJsonString()) {
            val x: SystemPreferences = SystemPreferences.fromJson(it)
            receiver(x)
        }
    }
}

