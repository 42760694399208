package dk.rheasoft.csaware.api.socialmedia
import kotlinx.serialization.Serializable

@Serializable
data class KeywordCount(val keyword: String, val count: Long)

@Serializable
data class KeywordCountResult(
    val keywordCounts: List<KeywordCount>
) {
    operator fun get(keyword: String): Long =
        keywordCounts.find { it.keyword.equals(keyword, ignoreCase = true) } ?.count ?: 0L

}