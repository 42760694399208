package dk.rheasoft.csaware.api.socialmedia

import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class SocialMediaObservation(
    val sourceType: SocialMediaType,
    val id: String,
    val createdAt: Instant,
    val title: String,
    val text: String,
    val userId: String,
    var language: String? = null,
) {

    @Serializable
    data class Read(
        val userId: String,
        val time: Instant = Clock.System.now()
    )

    @Serializable
    data class Comment(
        val userId: String,
        val text: String,
        val time: Instant = Clock.System.now()
    )
}

@Serializable
data class Translation(
    val language: String,
    val title: String?,
    val text: String?,

)