package csaware.systemdepend.graph.shapes

import csaware.main.CsawareServices
import csaware.systemdepend.graph.MxGraphWithStyles
import dk.rheasoft.csaware.api.systemdependencies.NodeShape
import dk.rheasoft.csaware.utils.JsonUtilSerialization
import kafffe.core.KafffeHtml
import kotlinx.serialization.decodeFromString
import mxgraph.mxConstants
import mxgraph.mxStencil
import mxgraph.mxStencilRegistry
import mxgraph.mxStylesheet
import org.w3c.dom.Document
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.Node
import org.w3c.dom.parsing.DOMParser
import org.w3c.dom.svg.SVGElement

object NodeShapeRegistry {
    val shapes = mutableSetOf<NodeShape>()

    fun createStencil(shape: String): mxStencil {
        val shapeXml: Document = DOMParser().parseFromString(shape, "text/xml")
        return mxStencil(shapeXml.documentElement!!)
    }

    fun putStyles(stylesheet: mxStylesheet) {
        for (shape in shapes) {
            stylesheet.putCellStyle(shape.styleName, shape.getCellStyle().unsafeCast<Any>())
        }
    }


    fun registerShapes() {
        // mxCellRenderer.registerShape("umlActor", ::UmlActorShape)
        shapes.filter { it.stencilShape != null }.forEach {
            registerStencil(it.mxShapeName, it.stencilShape!!)
        }
    }

    private val thumbCache: MutableMap<NodeShape, SVGElement> = mutableMapOf()

    fun shapeThumb(shape: NodeShape) : SVGElement =
        thumbCache.getOrPut(shape) {
            generateThumb(shape)
        }.cloneNode(true) as SVGElement

    private fun generateThumb(shape: NodeShape): SVGElement {
        thumbGraph.model.clear()
        val shapeStyle = shape.styleName + ";vertice_normal_color"
        thumbGraph.insertVertex(thumbGraph.getDefaultParent(), shape.styleName, shape.mxShapeName, 0.0, 0.0, 100.0, 50.0, shapeStyle)
        val svgElement = (thumbGraph.view.getCanvas() as SVGElement).ownerSVGElement?.cloneNode(true)
        thumbGraph.model.clear()
        return svgElement as SVGElement
    }

    private val thumbGraph: MxGraphWithStyles by lazy {
        val graphElement: HTMLDivElement = KafffeHtml.start.div {
            withStyle {
                visibility = "hidden"
                position = "absolute"
                overflowX = "hidden"
                overflowY = "hidden"
                height = "1px"
                width = "1px"
            }
        }.element
         MxGraphWithStyles(graphElement)
    }


    private fun registerStencil(mxShapeName: String, stencilShape: String) {
        val stencil = createStencil(stencilShape)
        mxStencilRegistry.addStencil(mxShapeName, stencil)
    }

    val defaultShape = NodeShape(mxConstants.SHAPE_RECTANGLE, "rectangle_shape", valueBelow = true)
    init {

        with(shapes) {
            // Builtins
            add(defaultShape)
            add(NodeShape(mxConstants.SHAPE_ELLIPSE, "ellipse_shape", valueBelow = true))
            add(NodeShape(mxConstants.SHAPE_RHOMBUS, "rhombus_shape", valueBelow = true))
            add(NodeShape(mxConstants.SHAPE_HEXAGON, "hexagon_shape", valueBelow = true))
            add(NodeShape(mxConstants.SHAPE_CLOUD, "cloud_shape", valueBelow = true))
            add(NodeShape(mxConstants.SHAPE_CYLINDER, "cylinder_shape", valueBelow = true))
            add(NodeShape(mxConstants.SHAPE_TRIANGLE, "triangle_shape", valueBelow = true))

            loadStencilsShapes()
        }
    }

    private fun loadStencilsShapes() {
        CsawareServices.backend.getTxt("/sysdep/shapes") {
            val loadedShapes: List<NodeShape> = JsonUtilSerialization.json.decodeFromString(it)
            shapes.addAll(loadedShapes)
        }
    }

    fun fromStyleName(styleName: String) =
        shapes.find { it.styleName.equals(styleName, ignoreCase = true) } ?: defaultShape
}

fun NodeShape.getCellStyle(): dynamic {
    val style = js("{}")
    style[mxConstants.STYLE_SHAPE] = mxShapeName
    style[mxConstants.STYLE_SPACING] = 20
    style[mxConstants.STYLE_ROUNDED] = true
    if (valueBelow) {
        style[mxConstants.STYLE_SPACING_TOP] = -22
        style[mxConstants.STYLE_VERTICAL_LABEL_POSITION] = "bottom"
        style[mxConstants.STYLE_FONTCOLOR] = "#ffffff"
    }
    return style
}
