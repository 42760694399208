package csaware.translation

import kafffe.core.Model
import kafffe.core.func

/**
 * String model that can also hold a translated version of the text.
 */
class TranslationModel (private val sourceModel: Model<String>) : Model<String>() {
    private var _translation: String? = null

    val translation : String get() = _translation?: data

    fun translationModel() = this.func({translation})

    init {
        translate()
    }

    fun translate() {
        StandardTranslation.translate(data) {
            _translation = it
            changed()
        }

    }
    override var data: String
        get() = sourceModel.data
        set(value) {sourceModel.data = value}
}