package csaware.main

import csaware.comm.UserBackend
import dk.rheasoft.csaware.api.User
import dk.rheasoft.csaware.api.UserInfo
import dk.rheasoft.csaware.api.access.MainFeature
import dk.rheasoft.csaware.api.access.Permission
import kafffe.core.Model
import kafffe.core.RefreshingCacheModel
import kotlinx.browser.window
import kotlinx.datetime.Clock
import kotlinx.datetime.toJSDate

object UserInformation {
    private var currentCache: UserInfo = UserInfo(User("dummy", "dummy", "dummy", "dummy", "dummy", setOf()), setOf())
    private var cachedAt = Clock.System.now().toJSDate().getTime() - 200000

    val current: User
        get() {
            if ((Clock.System.now().toJSDate().getTime() - cachedAt) > 180000) {
                loadCurrent {}
            }
            return currentCache.user
        }

    fun loadCurrent(block: () -> Unit) {
        UserBackend(UserInformation::errorHandler).getCurrentUser {
            currentCache = it
            cachedAt = Clock.System.now().toJSDate().getTime()
            block()
        }
    }

    fun hasFeature(feature: MainFeature) = currentCache.mainFeatures.contains(feature)

    fun hasAnyAccess() = current.roles.any{ it.grants.isNotEmpty() }

    fun hasAccess(feature: MainFeature, permission: Permission) =
        hasFeature(feature) && current.hasAccess(feature, permission)


    private fun errorHandler(code: Int, text: String) {
        window.alert("User info failed: ($code) $text")
    }

    val threatManagers: Model<List<User>> = RefreshingCacheModel(::refreshThreatManagers, listOf())

    fun refreshThreatManagers() {
        refreshThreatManagers(threatManagers)
    }

    private fun refreshThreatManagers(model: Model<List<User>>) {
        if (hasAccess(MainFeature.Threats, Permission.Write)) {
            UserBackend(UserInformation::errorHandler).getUsersByAccess(0, 200, MainFeature.Threats, Permission.Write) {
                model.data = it.result
            }
        } else {
            model.data = listOf()
        }
    }

    fun expireModels() {
        (threatManagers as RefreshingCacheModel).expire()
        @Suppress("UNUSED_VARIABLE") val test = threatManagers.data
    }

}