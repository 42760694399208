package csaware.threats

import csaware.main.CsawareServices
import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import kafffe.bootstrap.modifier.BootstrapPopoverModifier
import kafffe.bootstrap.modifier.BootstrapTooltipModifier
import kotlinx.datetime.Clock

/**
 * Example fot listing threats returned by backend.
 *
 * Currently loads a "new" threats on button click.
 */
class ThreatsTableCurrentActive : ThreatsTableBase(i18nText(CsawareMessages::nav_threats)) {
    init {
        loadData()
    }

    override fun loadData() {
        // clear any tootltip,popovers
        BootstrapTooltipModifier.removeAll()
        BootstrapPopoverModifier.removeAll()
        CsawareServices.alerts.clearAlerts()

        val offset = pageSize * (pager.currentPage - 1)
        if (!filter.isFilter()) {
            CsawareServices.threatsBackend.getThreatsCurrentActive(Clock.System.now(), offset, pageSize, this::receiveData)
        } else {
            CsawareServices.threatsBackend.getThreatsCurrentActiveWithFilter(
                Clock.System.now(),
                filter,
                offset,
                pageSize,
                this::receiveData
            )
        }
    }

}