@file:Suppress("PropertyName","FunctionName")

package csaware.messages

import dk.rheasoft.csaware.api.Cardinality
import dk.rheasoft.csaware.api.FieldType
import dk.rheasoft.csaware.api.ShareState
import dk.rheasoft.csaware.api.ThreatState
import kafffe.core.FunctionalSubModel
import kafffe.core.MessagesStrategy
import kafffe.core.Model
import kafffe.core.ServiceRegistry
import kafffe.messages.Messages
import kafffe.messages.MessagesObject.currentLanguage
import kafffe.messages.messagesModel
import kotlin.reflect.KProperty1


@OptIn(ExperimentalJsExport::class)
@JsExport
interface CsawareMessages : Messages {

    val translation_automatic: String
    val settings : String

    val environment_name: String

    val nav_overview: String
    val nav_threats: String
    val nav_threatsClosed: String
    val nav_config: String
    val threat_group: String
    val threat_state: String
    val threat_state_initator: String
    val threat_state_history: String
    val threat_state_comment: String
    val threat_course_of_action: String
    val threat_subtype: String
    val threat_firstObserved: String
    val threat_endActive: String
    val threat_newState: String
    val threat_selfHealingConfirm: String
    val threat_where: String
    val threat_assignee: String

    val threat_stixtype: String
    val threat_stixtype_campaign: String
    val threat_stixtype_report: String
    val threat_stixtype_vulnerability: String
    val threat_stixtype_malware: String
    val threat_stixtype_attack_pattern: String
    val threat_stixtype_tool: String
    val threat_stixtype_unknown: String

    fun threatStixType(stixType: String) : String  =
            when (stixType) {
                "campaign" -> threat_stixtype_campaign
                "report" -> threat_stixtype_report
                "vulnerability" -> threat_stixtype_vulnerability
                "malware" -> threat_stixtype_malware
                "attack-pattern" -> threat_stixtype_attack_pattern
                "tool" -> threat_stixtype_tool
                else -> threat_stixtype_unknown
            }


    val threat_observed_data_objects: String
    val threat_observed_data_id: String
    val threat_observed_data_type: String
    val threat_observed_data_data: String

    val severity: String
    val count: String

    val threat_topTitle: String

    val threat_level_5_name: String
    val threat_level_5_text: String
    val threat_level_4_name: String
    val threat_level_4_text: String
    val threat_level_3_name: String
    val threat_level_3_text: String
    val threat_level_2_name: String
    val threat_level_2_text: String
    val threat_level_1_name: String
    val threat_level_1_text: String
    val threat_level_0_name: String
    val threat_level_0_text: String
    val threat_group_all: String
    val threat_nobody: String

    fun threatLevelName(level: Int): String {
        return when (level) {
            5 -> threat_level_5_name
            4 -> threat_level_4_name
            3 -> threat_level_3_name
            2 -> threat_level_2_name
            1 -> threat_level_1_name
            else -> threat_level_0_name
        }
    }

    fun threatLevelText(level: Int): String {
        return when (level) {
            5 -> threat_level_5_text
            4 -> threat_level_4_text
            3 -> threat_level_3_text
            2 -> threat_level_2_text
            1 -> threat_level_1_text
            else -> threat_level_0_text
        }
    }

    val ThreatState_Active: String
    val ThreatState_Resolved: String
    val ThreatState_Ignored: String
    val ThreatState_HealingAwaitDecision: String
    val ThreatState_HealingAccepted: String
    val ThreatState_HealingDeclined: String
    val ThreatState_Healed: String
    val ThreatState_HealingFailed: String
    val ThreatState_HealingInProgress: String
    val ThreatState_Unknown: String

    fun threatState(state: ThreatState) =
            when (state) {
                ThreatState.Active -> ThreatState_Active
                ThreatState.Resolved -> ThreatState_Resolved
                ThreatState.Ignored -> ThreatState_Ignored
                ThreatState.HealingAwaitDecision -> ThreatState_HealingAwaitDecision
                ThreatState.HealingAccepted -> ThreatState_HealingAccepted
                ThreatState.HealingDeclined -> ThreatState_HealingDeclined
                ThreatState.Healed -> ThreatState_Healed
                ThreatState.HealingFailed -> ThreatState_HealingFailed
                ThreatState.HealingInProgress -> ThreatState_HealingInProgress
                ThreatState.Unknown -> ThreatState_Unknown
            }

    val user_management: String
    val user_new: String
    val user_edit: String
    val user_subject: String
    val user_email: String
    val user_firstName: String
    val user_lastName: String
    val user_fullname: String
    val user_roles: String
    val user_action_import: String
    val user_action_export: String

    val user_preferences_edit:String
    val user_preferences_uiLanguage:String
    val user_preferences_dataPresentationLanguage: String

    val system_preferences_edit:String
    val system_preferences_accountUiLanguage:String
    val system_preferences_accountDataPresentationLanguage: String

    val informationshare_management: String
    val informationshare_short_description: String
    val informationshare_edit: String
    val informationshare_id: String
    val informationshare_description: String
    val informationshare_state: String
    val informationshare_create: String
    val informationshare_modified: String
    val informationshare_initiator: String
    val informationshare_history: String
    val informationshare_summary: String

    val ShareState_AwaitDecision: String
    val ShareState_Accept: String
    val ShareState_Decline: String
    val ShareState_Unknown: String

    fun shareState(state: ShareState) =
            when (state) {
                ShareState.PermissionNeeded -> ShareState_AwaitDecision
                ShareState.SharingAccepted -> ShareState_Accept
                ShareState.SharingDenied -> ShareState_Decline
                ShareState.Unknown -> ShareState_Unknown
            }

    val system_dependencies: String

    val system_depend_id: String
    val system_depend_name: String
    val system_depend_description: String
    val system_depend_connectedFrom: String
    val system_depend_connectedTo: String
    val system_depend_infoflow: String
    val system_depend_category: String
    val system_depend_fields: String
    val system_depend_fields_valueset: String
    val system_depend_fields_valueset_values: String
    val system_depend_fields_to_shape_mapping: String
    val system_depend_layout: String
    val system_depend_layoutDirection: String
    val system_depend_layout_horizontal: String
    val system_depend_layout_vertical: String
    val system_depend_layout_roots: String
    val system_depend_layout_user_root: String
    val system_depend_layout_default_root: String
    val system_depend_spacing: String

    val system_depend_action_search: String
    val system_depend_action_new: String
    val system_depend_action_edit: String
    val system_depend_action_delete: String
    val system_depend_action_delete_confirm_title: String
    val system_depend_action_config: String
    val system_depend_action_import: String
    val system_depend_action_export: String
    val system_depend_action_config_import: String
    val system_depend_action_config_export: String
    val system_depend_action_print: String
    val system_depend_label_all: String

    val system_depend_field_id: String
    val system_depend_field_label: String
    val system_depend_field_type: String
    val system_depend_field_cardinality: String
    val system_depend_field_add: String
    val system_depend_fields_config: String

    val system_depend_field_validation_error_txt: String

    fun system_depend_field_cardinality_enum(cardinality: Cardinality): String = when (cardinality) {
        Cardinality.One -> "1"
        Cardinality.ZeroOrOne -> "0..1"
        Cardinality.ZeroToMany -> "0..*"
        Cardinality.OneToMany -> "1..*"
    }

    fun system_depend_field_validation_error(fieldType: FieldType, value: String): String =
        system_depend_field_validation_error_txt
            .replace("%type%", fieldType.name)
            .replace("%value%", value)

    val sign_out: String
    val sign_out_disclaimer: String
    val sign_out_link: String

    val mail_dialog_title: String
    val mail_to: String
    val mail_from: String
    val mail_subject: String
    val mail_body: String
    val mail_send: String

    val socialmedia_reader : String
    val socialmedia_reader_filter_readstate_both : String
    val socialmedia_reader_filter_readstate_not_read : String
    val socialmedia_reader_filter_readstate_read : String
    val socialmedia_reader_read_on_source : String
    val socialmedia_createdAt : String
    val socialmedia_keywords : String
    val socialmedia_text : String
    val socialmedia_account : String
    val socialmedia_id:String

    val socialmedia_system_where : String

    val socialmedia_account_included :String
    val socialmedia_management_title: String
    val socialmedia_account_edit: String
    val socialmedia_account_new: String
    val socialmedia_account_mediaType: String
    val socialmedia_account_collectorState: String
    val socialmedia_account_id: String
    val socialmedia_account_name: String
    val socialmedia_account_displayName: String
    val socialmedia_account_description: String
    val socialmedia_account_lastCollected: String

    val socialmedia_account_twitter_new: String

    val socialmedia_account_include: String
    val socialmedia_account_exclude: String

    val socialmedia_account_action_export: String
    val socialmedia_account_action_import: String
    val socialmedia_account_action_include_page: String
    val socialmedia_account_action_exclude_page: String

    val socialmedia_create_threatobservation: String

    val markdown_bold            : String
    val markdown_italic          : String
    val markdown_strikethrough   : String
    val markdown_heading         : String
    val markdown_heading_smaller : String
    val markdown_heading_bigger  : String
    val markdown_heading_1x       : String
    val markdown_heading_2x       : String
    val markdown_heading_3x       : String
    val markdown_code            : String
    val markdown_quote           : String
    val markdown_unordered_list  : String
    val markdown_ordered_list    : String
    val markdown_clean_block     : String
    val markdown_link            : String
    val markdown_image           : String
    val markdown_mde_table       : String
    val markdown_horizontal_rule : String
    val markdown_preview         : String
    val markdown_side_by_side    : String
    val markdown_fullscreen      : String
    val markdown_guide           : String
    val markdown_undo            : String
    val markdown_redo            : String
}

fun i18nText(prop: KProperty1<CsawareMessages, String>): Model<String> {
    val getMessage: (Model<Messages>) -> String = {
        val messages = csawareMessages()
        prop.get(messages)
    }
    return FunctionalSubModel(messagesModel, getData = getMessage)
}

fun csawareMessages() : CsawareMessages = CsawareMessagesObject.get()

fun csawareMessageStrategy(prefix: String? = null) = MessagesStrategy(prefix) { csawareMessages() }


object CsawareMessagesObject {
    fun register() {
        ServiceRegistry.register("csaware_en", CsawareMessages_en())
        ServiceRegistry.register("csaware_da", CsawareMessages_da())
    }

    val allLanguages: List<CsawareMessages>
        get() = ServiceRegistry.findAll()

    private var cache: CsawareMessages? = null
    private var cachedLanguage: String? = null

    fun get(): CsawareMessages {
        if (cachedLanguage != currentLanguage) {
            cache = get(currentLanguage)
            cachedLanguage = currentLanguage
        }
        return cache!!
    }

    fun get(language: CharSequence): CsawareMessages {
        return allLanguages.find { language.startsWith(it.language) } ?: CsawareMessages_en()
    }
}

