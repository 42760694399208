package csaware.socialmedia

import csaware.main.UserInformation
import kafffe.core.KafffeApplication
import org.w3c.dom.HTMLElement

fun addTwitterWidgetsJs() {
     KafffeApplication.addJsScriptRef("twitter_widgets", "https://platform.twitter.com/widgets.js")
}

fun createTweet(tweetId: String, element: HTMLElement) {
    val widgets: dynamic = js("twttr.widgets")
    val options = js("{}")
    options ["align"] = "center"
    options ["theme"] = "light"
    options ["lang"] = UserInformation.current.preferences.dataPresentationLanguage.shortName
    options["dnt"] = "true"
    options["conversation"] = "full"
    widgets.createTweet(tweetId, element, options)
}

