package csaware.utilities

import kafffe.core.KafffeComponentWithModel
import kafffe.core.KafffeHtmlBase
import kafffe.core.Model

class EllipsisLabel(private val textMaxWidth: String, textModel: Model<String>, private val lines: Int = 1) : KafffeComponentWithModel<String>(textModel) {
    init {
        setModelChangedRerender()
    }

    override fun KafffeHtmlBase.kafffeHtml() = div {
        withStyle {
            maxWidth = textMaxWidth
            overflowX = "hidden"
            overflowY = "hidden"
            textOverflow = "ellipsis"
            if (lines == 1) {
                whiteSpace = "nowrap"
            }
        }
        addClass("ellipsis-$lines")
        text(model.data)
    }


}