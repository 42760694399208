package dk.rheasoft.csaware.api

import dk.rheasoft.csaware.utils.JsonUtilSerialization
import kotlinx.serialization.Serializable
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.decodeFromJsonElement

/**
 * Event data to be pushed to the FrontEnds in case of server-side data changes.
 */
@Serializable
data class UpdateEvent(
    val type: EntityType,
    val ids: List<String> = listOf(),
    val action: ActionType = ActionType.Changed
) {
    constructor(type: EntityType, id: String, action: ActionType = ActionType.Changed) : this(type, listOf(id), action)

    enum class ActionType {
        New,
        Changed,
        Deleted
    }

    enum class EntityType {
        ThreatObservation,
        SocialMediaObservation,
        SocialMediaAccount,
        SystemDependencies,
        SystemDependencyNode,
        SystemDependencyEdge,
        SystemDependencyConfig,
        UserRole,
        InformationShare,
        Other,
        SystemPreferences,
    }

    fun toJsonString(): String =
        JsonUtilSerialization.json.encodeToString(this)

    companion object {
        fun fromJson(json: JsonObject): UpdateEvent =
            JsonUtilSerialization.json.decodeFromJsonElement(json)

        fun fromJson(json: String): UpdateEvent =
            JsonUtilSerialization.json.decodeFromString(json)
    }

}