package csaware.systemdepend.graph

import csaware.main.UserConfiguration
import csaware.systemdepend.graph.shapes.NodeShapeRegistry
import dk.rheasoft.csaware.api.SystemDependencyResource
import mxgraph.*
import org.w3c.dom.HTMLElement


private const val defaultFill = "var(--bs-info)"

open class MxGraphWithStyles(graphElement: HTMLElement) : mxGraph(graphElement) {

    override fun isCellSelectable(cell: Any): Boolean {
        return cell is mxCell && cell.isVertex()
    }

    override fun isCellMovable(cell: Any): Boolean = false
    override fun isCellEditable(cell: Any): Boolean = false
    override fun isCellResizable(cell: Any): Boolean = false

    init {
        NodeShapeRegistry.registerShapes()
        NodeShapeRegistry.putStyles(stylesheet)

        let {
            val resourceStyle = js("{}")
            resourceStyle[mxConstants.STYLE_FILLCOLOR] = defaultFill
            resourceStyle[mxConstants.STYLE_STROKECOLOR] = "#000000"
            resourceStyle[mxConstants.STYLE_STROKEWIDTH] = 1
            resourceStyle["width"] = "180px"
            resourceStyle["height"] = "180px"
            stylesheet.putCellStyle("vertice_normal_color", resourceStyle)
        }
        let {
            val resourceStyle = js("{}")
            resourceStyle[mxConstants.STYLE_FILLCOLOR] = defaultFill
            resourceStyle[mxConstants.STYLE_STROKECOLOR] = "#8DFF54"
            resourceStyle[mxConstants.STYLE_STROKEWIDTH] = 5
            stylesheet.putCellStyle("vertice_highlight_color", resourceStyle)
        }
        let {
            val resourceStyle = js("{}")
            resourceStyle[mxConstants.STYLE_OPACITY] = 35
            resourceStyle[mxConstants.STYLE_TEXT_OPACITY] = 40
            stylesheet.putCellStyle("lowlight", resourceStyle)
        }
        let {
            val edgeStyle = getStylesheet().getDefaultEdgeStyle().asDynamic()
            edgeStyle[mxConstants.STYLE_ROUNDED] = true
            edgeStyle[mxConstants.STYLE_STROKECOLOR] = "#ffffff"
            edgeStyle[mxConstants.STYLE_STROKEWIDTH] = 1
            stylesheet.putCellStyle("edge", edgeStyle)
            getStylesheet().putDefaultEdgeStyle(edgeStyle)
        }
        let {
            val resourceStyle = js("{}")
            resourceStyle[mxConstants.STYLE_ROUNDED] = true
            resourceStyle[mxConstants.STYLE_STROKECOLOR] = "#8DFF54"
            resourceStyle[mxConstants.STYLE_STROKEWIDTH] = 2
            stylesheet.putCellStyle("edge_highlight", resourceStyle)
        }
        let {
            val edgeStyle = js("{}")
            edgeStyle[mxConstants.STYLE_STARTARROW] = mxConstants.NONE
            edgeStyle[mxConstants.STYLE_ENDARROW] = mxConstants.ARROW_CLASSIC
            stylesheet.putCellStyle("singleArrow", edgeStyle)
        }
        let {
            val edgeStyle = js("{}")
            edgeStyle[mxConstants.STYLE_ENDARROW] = mxConstants.ARROW_CLASSIC
            edgeStyle[mxConstants.STYLE_STARTARROW] = mxConstants.ARROW_CLASSIC
            stylesheet.putCellStyle("doubleArrow", edgeStyle)
        }
        let {
            val resourceStyle = js("{}")
            resourceStyle[mxConstants.STYLE_ROUNDED] = true
            resourceStyle[mxConstants.STYLE_STROKECOLOR] = "#8DFF54"
            resourceStyle[mxConstants.STYLE_STROKEWIDTH] = 2
            stylesheet.putCellStyle("edge_empty", resourceStyle)
        }
        val config: UserConfiguration = UserConfiguration.default
        for (severity in (1..5)) {
            val resourceStyle = js("{}")
            resourceStyle[mxConstants.STYLE_STROKECOLOR] = config.severityColor(severity)
            resourceStyle[mxConstants.STYLE_STROKEWIDTH] = 1 + severity
            stylesheet.putCellStyle("severity-$severity", resourceStyle)
        }
    }

}