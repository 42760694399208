package csaware.translation

import csaware.comm.CSAwareBackend
import csaware.main.UserInformation
import dk.rheasoft.csaware.api.TranslationText

object StandardTranslation {
    fun translate(text: String, receiver: (translation: String) -> Unit) {
        if (AutoTranslationModel.data && UserInformation.current.preferences.dataPresentationLanguage.shortName != "en" ) {
            CSAwareBackend(::exceptionHandler).translate(TranslationText("en", UserInformation.current.preferences.dataPresentationLanguage.shortName, text)) {
                receiver(it.text)
            }
        } else {
            receiver(text)
        }
    }

    private fun exceptionHandler(status: Int, msg: String) {
        println("Translation error: $status: $msg")

    }
}